import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "../../../api/api"

export const getTaskProgress = createAsyncThunk(
  "taskprogress/getTaskprogress",  async (params, thunk) => {
        try{
            const res = await axios.get(
                `${process.env.REACT_APP_API_DJANGO}taskprogresses/?client_id=${params['_client']}&standard_id=${params['client_standardId']}`
              );
              return res.data;
        } catch (error) {
         return thunk.rejectWithValue(error.response.data);
        }
  })

  export const updateSectionAssignee = createAsyncThunk(
    "taskprogress/updateSectionAssignee",  async (params, thunk) => {
          try{
              await axios.put(
                  `${process.env.REACT_APP_API_DJANGO}taskprogresses/update-section-assignee/${params['task_progress']}/`,
                  {
                    user: params['assignee_id']
                  }
                );

                const res = await axios.get(
                    `${process.env.REACT_APP_API_DJANGO}taskprogresses/?client_id=${params['_client']}&standard_id=${params['client_standardId']}`
                  );
                return res.data;
          } catch (error) {
           return thunk.rejectWithValue(error.response.data);
          }
    })
  
    // const updateAssignee = async (task_progress, data) => {
    //     const token = localStorage.getItem("token");
    //     try {
    //       const res = await axios.put(
    //         `${process.env.REACT_APP_API_URL_LOGIN}scm/taskprogresses/update-section-assignee/${task_progress}/`,
    //         data,
    //         {
    //           headers: {
    //             Authorization: `Bearer ${token}`,
    //           },
    //         }
    //       );
    //       return res;
    //     } catch (e) {}
    //   };

//   const updateAssignee = async (task_progress, data) => {
//     const token = localStorage.getItem("token");
//     try {
//       const res = await axios.put(
//         `${process.env.REACT_APP_API_URL_LOGIN}scm/taskprogresses/update-section-assignee/${task_progress}/`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       return res;
//     } catch (e) {}
//   };

  const taskprogressSlice = createSlice({
    name: "taskprogress",
    initialState: {
        taskprogress: [],
        loading: false,
        error: null,
    },
    reducers: {
        onRefresh: () => {},
        updateTaskAssignee: (state, action) => {
          const index = state.taskprogress.findIndex(
            (item) => item.id === action.payload.id
          );
          state.taskprogress[index].task_assignee =
            action.payload.task_assignee;
        },
        updateTaskStatus: (state, action) => {
            const index = state.taskprogress.findIndex(
              (item) => item.id === action.payload.id
            );
            state.taskprogress[index].task_status =
              action.payload.task_status;
          },
        // setCurrentRow: (state, action) => {
        //   state.currentRow = action.payload;
        // },
      },
    extraReducers : (builder) => {
        builder.addCase(getTaskProgress.pending, (state) => {
            state.loading = true;
        })
        .addCase(getTaskProgress.fulfilled, (state, action) => {
            state.loading = false;
            state.taskprogress = action.payload
        })
        .addCase(getTaskProgress.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(updateSectionAssignee.pending, (state) => {
            state.loading = true;
        })
        .addCase(updateSectionAssignee.fulfilled, (state, action) => {
            state.loading = false;
            state.taskprogress = action.payload
        })
        .addCase(updateSectionAssignee.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    }
  })
  export const { updateTaskAssignee, updateTaskStatus,  } = taskprogressSlice.actions;
  export default taskprogressSlice.reducer;