import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import useAxios from '../hooks/useAxios';
import api from '../api/api';
import axios from '../api/api';
import standardService from '../api/services/standardService';
import { Avatar, AvatarGroup, Tooltip, Typography, colors } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { deepOrange, deepPurple } from '@mui/material/colors';
import { useSelector, useDispatch } from "react-redux";
import { updateSectionAssignee } from '../feature/slices/taskprogress/taskProgressSlice';

export default function Assignee({ newTask,  taskprogress, assigneeObj, onSetAssignee, client_standardId }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const token = localStorage.getItem('token');
  const isAssignee = localStorage.getItem('isAssignee');
  const _user = localStorage.getItem('user');
  const _client = localStorage.getItem('client');

  const dispatch = useDispatch();

  api.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  const [newAssignee, setNewAssignee] = React.useState('');
  const [assignee, setAssignee] = React.useState((assigneeObj?.first_name === undefined ? '' : assigneeObj.first_name));
  // const [assignees, setAssignees] = React.useState([]);
  const { assignees } = useSelector((state) => state.assignees)

  const handleMenuItemClick = async (name, index) => {
    setSelectedIndex(index);
    setNewAssignee(name);
    setOpen(false);
    try {
      await updateAssignee(assignees[index]?.id);
      //  setAssignee(assignees[index]?.first_name);
       onSetAssignee(assignee)
    } catch (error) {
      // handle error
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };


  const updateAssignee = async (assigneeId) => {
    setOpen(false);
    try {
      // const result = await standardService.updateAssignee(taskprogress, {
      //   user: assigneeId,
      // });
      // dispatch(getTaskProgress({_client: _client, client_standardId: client_standardId}))
      dispatch(updateSectionAssignee({assignee_id: assigneeId, task_progress: taskprogress, _client: _client, client_standardId: client_standardId }))
      setAssignee(assignees.find((assignee) => assignee.id === assigneeId)?.first_name);
    } catch (error) {
      // handle error
    }
  };


  // Define the useEffect hook here
  // React.useEffect(() => {
  //   setAssignee(`${user}`);
  // }, [sectionId]);

  const stringToColor = (str) => {

    let hash = 2;

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xff;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  };


  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <ButtonGroup variant="outlined-secondary" ref={anchorRef} aria-label="split button" sx={{ border: 1 }}>
        {assignee ? <Button><Typography style={{ textTransform: 'capitalize' }}>{assignee}</Typography></Button> :
            <Button sx={{ cursor: 'default' }}>UNASSIGNED</Button>
          }
          {isAssignee === 'false' && (
            <Button
              variant='outlined-dark'
              size="small"
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}
              sx={{ borderLeft: 1 }}
            >
              <ArrowDropDownIcon />
            </Button>
          )
          }

        </ButtonGroup>

        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem
                  sx={{
                    overflow:"auto", 
                    maxHeight:'25vh',
                    width:'7vw',
                    "&::-webkit-scrollbar": {
                      width: "5px",
                    },
        
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "black",
                      borderRadius: "10px",
                    },
        
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#888",
                      borderRadius: "4px",
                    },
        
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#555",
                    },
                  }}
                  >
                    {assignees.map((option, index) => (
                      <MenuItem
                        key={option.id}
                        // disabled={index === 3}
                        selected={index === selectedIndex}
                        onClick={() => handleMenuItemClick(
                          option?.first_name,
                          index)}>
                        {option?.first_name.charAt(0).toUpperCase() + option?.first_name.slice(1)}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {/* ============================== ASSIGNEE AVATAR ============================================= */}
        {/* <Box sx={{ paddingLeft: '5px' }}>
          <Stack direction="row" spacing={-1}>
          {Array.from(new Set(assignees.map((assignee) => String(assignee.first_name))))
            .filter((a) => a !== '')
            .map((a, i) => {
              const color = stringToColor(a);
              return (
                <Tooltip title={a} key={i}>
                  <Avatar
                    key={i}
                    sx={{
                      bgcolor: color,
                      border: '2px solid white',
                      transition: 'transform 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.0) translateY(-5px)',
                      },
                    }}
                    alt="Remy Sharp"
                  >
                    {a[0]}
                  </Avatar>
                </Tooltip>
              );
            })}
          </Stack>
        </Box>  */}
       
      </Box>

    </React.Fragment>

  )

}