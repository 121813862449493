import React from 'react'
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { color, maxWidth, width } from '@mui/system';
import { IconButton, Stack, Typography, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function ViewFile({files, onclose, task_requirement}) {

    const docs = []

    if (!Array.isArray(files)) {
        docs.push({
          uri: files.previewUrl,
          fileType: files.name.substring(files.name.lastIndexOf('.') + 1),
          fileName: files.name,
        })
    }
    for (let i = 0; i < files.length; i++) {
        docs.push({
          uri: files[i].previewUrl,
          fileType: files[i].name.substring(files[i].name.lastIndexOf('.') + 1),
          fileName: files[i].name,
        })
    }

    return (
      
       <Box sx={{
          '& div#pdf-controls': { display: 'none' },
          '.cy-footer': {
            display: 'none',
          },
           color: 'black',
        }}>
        <Stack style={{borderStartStartRadius: 5, borderStartEndRadius: 5, paddingBottom: 8}} display='flex' direction='row' justifyContent='space-between' backgroundColor={'black'}> {task_requirement ? 
          <Box height='14vh' display='flex' flexDirection='column' paddingTop={5}>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'Highlight', mt: .5, ml: 2.5 }}>Requirement:</Typography>
            <Typography color='white' sx={{ fontSize: 12, fontWeight: 'bold', ml: 5, mb: .5, mr: -1 }}>{task_requirement}</Typography>
          </Box>: <Box style={{paddingTop: 12}}/>}
          <IconButton disableRipple
              sx={{
                position: 'relative',
                // top: -10,
                color: 'white',
                borderRadius: 5,
                // transform: 'translate(-5px, 5px)', 
                '&:hover': {
                  // Adjust the styles for the hover effect
                  // For example, you can reduce opacity or remove the hover effect completely
                  transform: 'scale(1.2)',
                  color: 'red'
                  // background: '',
                  // ...
                },
              }}
              onClick={onclose}
            >
          <CloseIcon/>
          </IconButton>
         
        </Stack>
        <DocViewer documents={docs}
        pluginRenderers={DocViewerRenderers}
        style={{ 
          height: '86vh',
          width: '99vw'    
        }}
        config={{ footer: { display: 'none' } }}
        />
        <Box sx={{
          height: '3.5rem',
          // overflowY: 'hidden',
          display: 'flex',
          // position: 'absolute',
          transform: "translate(-0%, -60%)",
          // top: '100%',
          width: 'auto',
          color: 'white',
          backgroundColor: 'white',
          borderBottomRightRadius: 5,
          borderBottomLeftRadius: 5
        }}>
        </Box>
      </Box>
      
    )
}
export default ViewFile