import React from 'react';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';

export default function CustomAlert(props) {
  const { open, severity, successMessage, onClose } = props;

  return (
    <Alert onClose={onClose} severity={severity} sx={{ width: '100%' }} open={open}>
      <Typography>{successMessage}</Typography>
    </Alert>
  );
}
