import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { Box } from "@mui/material";
import api from "../../api/api";

function ApexCustomAngleCircle() {
  const [percentages, setPercentages] = useState([0, 0, 0]);
  const _clientId = localStorage.getItem("client");
  const [loading, setLoading] = useState(false);

  api.interceptors.request.use(
    function (config) {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    setLoading(true);
    const fetchFulfilledCounts = async () => {
      try {
        const response = await api.get(
          `/scm/taskprogresses/fulfilled_percentage/?app=${process.env.REACT_APP_NAME}&client_id=${_clientId}`
        );
        const data = response.data;
        setPercentages([
          data.standards_percentage,
          data.sections_percentage,
          data.tasks_percentage,
        ]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchFulfilledCounts();
  }, [_clientId]);

  const options = {
    series: percentages,
    chart: {
      height: 390,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        offsetX: 0,
        offsetY: 0,
        hollow: {
          margin: 5,
          size: "45%",
          background: "transparent",
          image: undefined,
        },
        dataLabels: {
          name: {
            show: true,
          },
          value: {
            show: true,
            color: "white",
            offsetY: 8,
          },
        },
      },
    },
    colors: ["#FF0000", "#67AD45", "#11A0DB"],
    labels: ["Standards", "Sections", "Sub-Sections"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      offsetY: 10,
      markers: {
        size: 10,
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5,
      },
      formatter: function (seriesName, opts) {
        const chartWidth = opts.w.globals.chartWidth;
        let fontSize = 17;

        if (chartWidth < 400) {
          fontSize = 5;
        } else if (chartWidth < 600) {
          fontSize = 25;
        } else if (chartWidth < 800) {
          fontSize = 35;
        }

        return (
          '<span style="color: white; font-size: ' +
          fontSize +
          'px">' +
          seriesName +
          " : " +
          opts.w.globals.series[opts.seriesIndex] +
          "%" +
          "</span>"
        );
      },
      responsive: [
        {
          breakpoint: 900,
          options: {
            position: "bottom",
            offsetY: 0,
            offsetX: 0,
            horizontalAlign: "center",
            fontSize: "16px",
            itemMargin: {
              horizontal: 0,
              vertical: 5,
            },
          },
        },
      ],
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          chart: {
            height: 300,
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          chart: {
            height: 250,
          },
        },
      },
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 200,
          },
        },
      },
    ],
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <ApexCharts
        type="radialBar"
        options={options}
        width={900}
        height={430}
        series={percentages}
      />
    </Box>
  );
}

export default ApexCustomAngleCircle;
