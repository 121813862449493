import React from 'react';
import { Pagination } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(100),
    marginBottom: theme.spacing(1),
    '& button': {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(.5),
    },
  },
  paginationHorizontal: {
    flexDirection: 'row',
  },
  paginationVertical: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function CustomPagination(props) {
  const { orientation, ...rest } = props;
  const classes = useStyles();

  return (
    <Pagination
      className={`${classes.paginationContainer} ${
        orientation === 'horizontal'
          ? classes.paginationHorizontal
          : classes.paginationVertical
      }`}
      {...rest}
    />
  );
}

export default CustomPagination;
