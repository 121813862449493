import React from "react";
import ApexCharts from "react-apexcharts";
import { Box } from "@mui/material";

function ApexTimeline() {
  var options = {
    series: [
      {
        name: "Plan",
        data: [
          {
            x: "CRM",
            y: [
              new Date("2023-01-05").getTime(),
              new Date("2023-05-06").getTime(),
            ],
          },
          {
            x: "SII",
            y: [
              new Date("2023-05-06").getTime(),
              new Date("2023-08-11").getTime(),
            ],
          },
          {
            x: "NCO",
            y: [
              new Date("2023-08-11").getTime(),
              new Date("2023-11-17").getTime(),
            ],
          },
        ],
        color: "#2196F3",
      },
      {
        name: "Actual",
        data: [
          {
            x: "CRM",
            y: [
              new Date("2023-01-05").getTime(),
              new Date("2023-06-06").getTime(),
            ],
          },
          {
            x: "SII",
            y: [
              new Date("2023-06-06").getTime(),
              new Date("2023-09-11").getTime(),
            ],
          },
          {
            x: "NCO",
            y: [
              new Date("2023-09-11").getTime(),
              new Date("2023-12-17").getTime(),
            ],
          },
        ],
        color: "#c2c2c2",
      },
    ],
    chart: {
      height: 350,
      type: "rangeBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        // calculate difference in months
        var a = new Date(val[0]);
        var b = new Date(val[1]);
        var diff =
          (b.getFullYear() - a.getFullYear()) * 12 +
          (b.getMonth() - a.getMonth());

        // convert to days if difference is less than 1 month
        if (diff < 1) {
          diff = (b.getTime() - a.getTime()) / (1000 * 3600 * 24); // convert milliseconds to days
          return diff.toFixed(0) + (diff > 1 ? " days" : " day");
        }

        return diff + (diff > 1 ? " months" : " month");
      },
    },

    fill: {
      type: "solid",
      colors: ["#2196F3", "#c2c2c2"],
    },
    yaxis: {
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: "#fff",
        },
      },
    },
    legend: {
      position: "top",
      labels: {
        colors: "#fff",
      },
    },
  };

  return (
    <div style={{ color: "black" }}>
      <Box style={{ width: "100%", maxWidth: "900px", margin: "0 auto" }}>
        <ApexCharts
          type="rangeBar"
          options={options}
          width="100%"
          height={400}
          series={options.series}
        />
      </Box>
    </div>
  );
}

export default ApexTimeline;
