import * as React from "react";
import {
  DataGrid,
  GridNoRowsOverlay,
  GridLoadingOverlay,
} from "@mui/x-data-grid";
import { getActivityLogs } from "../api/api";
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import { maxWidth } from "@mui/system";
import { useDispatch } from 'react-redux';
import { getTaskProgress } from "../feature/slices/taskprogress/taskProgressSlice";

const columns = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "timestamp", headerName: "Timestamp", width: 155, sortable: false },
  { field: "user", headerName: "User", width: 80, sortable: false },
  { field: "action", headerName: "Action", width: 200, sortable: false },
  {
    field: "details",
    headerName: "Details",
    width: 410,
    sortable: false,
    renderCell: (params) => (
      <div
        style={{
          maxWidth: "100%",
          whiteSpace: "normal",
          wordWrap: "break-word",
        }}
      >
        {params.value}
      </div>
    ),
  },

  // CLIENT STANDARD ID COLUMN USED TO NAVIGATE TO THE SPECIFIC ADDED STANDARD
  // {
  //   field: "c_standard",
  //   headerName: "Standard ID",
  //   width: 100,
  //   sortable: false,
  // },
];

function CustomNoRowsOverlay() {
  return (
    <GridNoRowsOverlay>
      <div style={{ padding: "16px" }}>No activity logs found.</div>
    </GridNoRowsOverlay>
  );
}

function CustomLoadingOverlay() {
  return (
    <GridLoadingOverlay>
      <div>Loading...</div>
    </GridLoadingOverlay>
  );
}

export default function ActivityLogsDataGrid() {
  const [loading, setLoading] = React.useState(false);
  const [rows, setRows] = React.useState([]);

  const token = localStorage.getItem("token");
  const _client = localStorage.getItem("client");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  api.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  React.useEffect(() => {
    setLoading(true);
    getActivityLogs(_client)
      .then((apiRows) => {
        setRows(apiRows);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [_client]);

  return (
    <div style={{ marginTop: "9px", height: "90%", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={25}
        hideFooterSelectedRowCount={true}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
          LoadingOverlay: CustomLoadingOverlay,
        }}
        loading={loading}
        disableColumnMenu={true}
        onCellDoubleClick={(event) => {
          navigate(
            `/compliance_manage?clientStandard=${Number(
              String(event.row.c_standard)
            )}`
          );
          dispatch(getTaskProgress({_client: _client, client_standardId: Number(String(event.row.c_standard))}))
        }}
      />
    </div>
  );
}
