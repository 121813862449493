
import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    assignees: []
}


const assigneeSlice = createSlice({
    name: 'assignee',
    initialState,
    reducers: {
        add: (state, action) => {
            state.assignees.push(action.payload)
        },
        reset: (state) => {
            state.assignees = []
        }
    }
}
)


export default assigneeSlice.reducer;
export const { add, reset } = assigneeSlice.actions