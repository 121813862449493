import {
    Box,
    Modal,
    Typography,
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    CircularProgress,
    Snackbar,
    Alert,
    Divider,
  } from "@mui/material";
  import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
  import { tokens } from "../../theme";
  import Header from "../../components/Header";
  import { useTheme } from "@mui/material";
  import { useEffect, useState } from "react";
  import AddStandard from "../../components/AddStandard";
  import useAxios from "../../hooks/useAxios";
  import React from "react";
  import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
  import IconButton from "@mui/material/IconButton";
  import { TextField } from "@mui/material";
  import CustomAlert from "../../components/Alert/SuccessAlert";
  import { Stack } from "@mui/system";
  import CollapsibleComplianceView from "../../components/CollapsibleComplianceView";
  import { AddCircleOutline, CloseOutlined } from "@mui/icons-material";
  import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
  import { Navigate } from "react-router-dom";
  import ManageStandard from "./ManageStandards";
  import axios from "../../api/api";
  import api from "../../api/api";
  import useMediaQuery from "@mui/material/useMediaQuery";
  import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
  
  const EditStandard = ({ standard, onAlert, onclose, refreshKey, onCloseAlertWithTime }) => {
    const [standardName, setStandardName] = useState(standard.name); // Update this line
    const [standardDesc, setStandardDesc] = useState(standard.description);
  
  
    // setRefreshKey((oldKey) => oldKey + 1);
    
    const updateStandard = async (name, description) => {
      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_API_DJANGO}standards/${standard.id}/`,
          {
            name: name,
            description: description
          }
        );
        onAlert({
          message: "Standard Name updated successfully!",
          isShow: true,
          serverity: "info",
        });
        onCloseAlertWithTime();
        refreshKey(oldKey => refreshKey(oldKey +1))
        return res;
      } catch (e) {
        return e;
      }
    };
  
    return (
      <Box
        sx={{
          zIndex: "99999",
        }}
      >
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "30%",
                  height: "40%",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 2,
                  borderRadius: "5px",
                  justifyContent: "center",
                  "& .MuiTextField-root": { m: 1, width: "60ch" },
                }}
              >
                <Stack display="flex" direction="column">
                  <Typography variant="h4" paddingBottom={2}>
                    Update Standard
                  </Typography>
                  <TextField
                    required
                    type="text"
                    id="name"
                    name="name"
                    value={standardName}
                    autoFocus={onclose}
                    onChange={(event) => {
                      const value = event.target.value;
                      setStandardName(value);
                    }}
                    focused={true}
                    label="Standard Name"
                    variant="outlined"
                    size="small"
                  />
                  <TextField
                    type="text"
                    id="description"
                    name="description"
                    value={standardDesc}
                    onChange={(event) => {
                      const value = event.target.value;
                      setStandardDesc(value);
                    }}
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={8}
                    size="small"
                  />
  
                  <Stack
                    display="flex"
                    direction="row"
                    justifyContent="end"
                    paddingTop={1}
                    spacing={1}
                  >
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        onclose(!onclose)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={!standardName}
                      onClick={() => {
                        updateStandard(standardName, standardDesc);
                        onclose(!onclose)
                      }}
                    >
                      Save
                    </Button>
                  </Stack>
                </Stack>
              </Box>
      </Box>
    );
  };
  
  const StandardTable = () => {
    const [showAlert, setShowAlert] = useState({
      message: "",
      isShow: false,
      serverity: "",
    });
    const [showAddAlert, setShowAddAlert] = useState({
      message: "",
      isShow: false,
      serverity: "",
    });
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(false);
    const [complianceId, setComplianceId] = useState("");
    const token = localStorage.getItem("token");
    const [standarId, setStandardId] = useState(null);
    const [standardName, setStandardName] = useState("");
  
    api.interceptors.request.use((config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
  
    // const [compliance] = useAxios({
    //   axiosInstance: api,
    //   method: "GET",
    //   url: `scm/standards/?app=${process.env.REACT_APP_NAME}`,
    // });
  
    const [compliance, setCompliance] = useState([]); // replace isCompliance with compliance
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedStandard, setSelectedStandard] = useState(null);
    const [isAddingStandard, setIsAddingStandard] = useState(false); // Add this line here
    const [isAddingSection, setIsAddingSection] = useState(false); // Add this line here
    const [refreshKey, setRefreshKey] = useState(0);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await axios.get(
            `scm/standards/?app_id=${process.env.REACT_APP_NAME}`,
            {
              headers: {
                "Content-Language": "en-US",
              },
            }
          );
          setCompliance(response.data); // use setCompliance instead of setIsCompliance
          setError(false);
        } catch (error) {
          setError(true);
          alert("Reload. Error loading data.");
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }, [refreshKey]);
  
    const ShowSections = (event, params) => {
      event.stopPropagation(); // prevent row click event from being triggered
      setComplianceId(params.id);
      setOpen(true);
    };
  
    const onCloseAlertWithTime = () => {
      const interval = setInterval(() => {
        setShowAlert((prevState) => ({ ...prevState, isShow: false }));
        clearInterval(interval);
      }, 2000);
    };
    const onCloseAddAlertWithTime = () => {
      const interval = setInterval(() => {
        setShowAddAlert((prevState) => ({ ...prevState, isShow: false }));
        clearInterval(interval);
      }, 2000);
    };
  
    const [openAdd, setOpenAdd] = React.useState(false);
    const [name, setName] = React.useState("");
    const [description, setDescription] = React.useState("");
  
  
    const handleOpenAdd = () => {
      setOpenAdd(true);
    };
  
    const handleCloseAdd = () => {
      setOpenAdd(false);
    };
  
    const handleConfirmSaveStandard = () => {
      handleSaveStandard();
    };
  
    const handleSaveStandard = async () => {
      //props.newSubsection(false)
      try {
        setIsAddingStandard(true); //start submitting
        const response = await axios.post(
          `scm/standards/`,
          {
            name: name,
            description: description,
            version: 1.0,
            app_name: process.env.REACT_APP_NAME,
          },
          {
            headers: {
              "Content-Language": "en-US",
            },
          }
        );
        if (response.status === 201) {
          setShowAlert({
            message: "New Standard successfully added!",
            isShow: true,
            serverity: "info",
          });
          onCloseAlertWithTime();
          // props.newSubsection(true)
        }
      } catch (error) {
      } finally {
        setIsAddingStandard(false);
        setOpenAdd(false);
      }
    };
    
    const [isEditingStandard, setIsEditingStandard] = useState(false);
    const [openSection, setOpenSection] = React.useState(false);
    const [sectionName, setSectionName] = React.useState("");
    const [sectionDescription, setSectionDescription] = React.useState("");
    const [newSection, setNewSection] = useState(false);
  
    const handleOpen = () => {
      setOpenSection(true);
    };
  
    const handleClose = () => {
      setOpenSection(false);
    };
  
    const handleConfirmSave = () => {
      handleSaveSection();
     
    };
  
    const handleSaveSection = async () => {
      try {
        setIsAddingSection(true); //start submitting
        const response = await axios.post(
          `scm/sections/`,
          {
            name: sectionName,
            description: sectionDescription,
            version: 1.0,
            standard: standarId,
          },
          {
            headers: {
              "Content-Language": "en-US",
            },
          }
        );
        //setSectionList([...sectionList, response.data]);
        setShowAddAlert({
          message: "Section successfully created!",
          isShow: true,
          serverity: "success",
        });
        onCloseAddAlertWithTime();
        setSectionDescription("");
        setSectionName("");
      } catch (error) {
        setShowAddAlert({
          message: "Error creating section!",
          isShow: true,
          serverity: "error",
        });
        onCloseAddAlertWithTime();
      } finally {
        setIsAddingSection(false); //start submitting
        setOpenSection(false);
        setNewSection(true);
      }
    };
  
    const isFullScreen = useMediaQuery("(min-width: 1380px)");
    const boxWidth = isFullScreen ? "30%" : "50%"; // THIS IS USED TO RESIZE THE MODAL
    const boxHeight = isFullScreen ? "30%" : "50%";
  
    const CustomQuickFilterToolbar = () => (
      //============================================= the search feature ===========================================
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        justifyItems={"center"}
        bgcolor={colors.black[900]}
      >
        <GridToolbarQuickFilter
          style={{ fontSize: 100 }}
          sx={{ paddingTop: 2 }}
        />
        <Button
          startIcon={<AddCircleOutline />}
          variant="contained"
          color="success"
          size="small"
          sx={{
            border: 5,
            borderColor: "black",
            borderRadius: 2,
          }}
          onClick={() => {
            handleOpenAdd();
          }}
        >
          Add Standard
        </Button>
      </Box>
    );
  
    // DELETE FUNCTION FOR ROW OF STANDARD
    const [openDelete, setOpenDelete] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState(false); // State for Delete Snackbar
    const _roles = localStorage.getItem('role');
    const _isCytechAdmin = _roles.includes(`${process.env.REACT_APP_ADMIN_ROLE}`);
  
    
  
    const handleDeleteSnackbarOpen = () => {
      setOpenDeleteSnackbar(true);
    };
  
    const handleDeleteSnackbarClose = () => {
      setOpenDeleteSnackbar(false);
    };
  
    const handleOpenDelete = (standarId) => {
      setOpenDelete(true);
      setToBeDeleted(standarId);
    };
  
    const handleCloseDelete = () => {
      if (!isProcessing) {
        setOpenDelete(false);
      }
    };
  
    const deleteStandard = async () => {
      try {
        setIsProcessing(true);
  
        // Delete the row (which also deletes associated rowProgress instances)
        const res = await axios.delete(
          `${process.env.REACT_APP_API_DJANGO}standards/${toBeDeleted}/`
        );
        // Show Snackbar notification
        handleDeleteSnackbarOpen();
        setOpenDelete(false);
  
        // Add a delay before reloading the page
        await new Promise((resolve) => setTimeout(resolve, 3000));
  
        setRefreshKey((oldKey) => oldKey + 1);
        return res;
      } catch (e) {
        throw e;
      } finally {
        setIsProcessing(false);
      }
    };
    // COUNTDOWN FOR RELOADING PAGE
    const [countdown, setCountdown] = useState(null);
  
    useEffect(() => {
      if (openDeleteSnackbar) {
        setCountdown(3);
      }
    }, [openDeleteSnackbar]);
  
    useEffect(() => {
      if (countdown && countdown > 0) {
        const timer = setTimeout(() => {
          setCountdown(countdown - 1);
        }, 1000);
        return () => clearTimeout(timer);
      }
    }, [countdown]);
  
    function DeleteProgress(props) {
      return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          <CircularProgress {...props} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round((props.value / 100) * 3)}s`}{" "}
              {/* Convert the percentage back to seconds */}
            </Typography>
          </Box>
        </Box>
      );
    }
  
    const columns = [
      {
        field: "name",
        headerName: (
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>Standards</span>
        ),
        flex: 0.12,
        headerAlign: "center",
        align: "left",
        sortable: false,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                whiteSpace: "normal",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                fontWeight={"bold"}
                noWrap={false}
                sx={{ color: "skyblue" }}
              >
                {params.value}
              </Typography>
            </Box>
          );
        },
      },
  
      {
        field: "description",
        headerName: (
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>
            Description
          </span>
        ),
        flex: 0.4,
        headerAlign: "center",
        align: "left",
        sortable: false,
        renderCell: (params) => (
          <Typography
            style={{
              height: "3em",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {params.value}
          </Typography>
        ),
      },
      {
        field: "sections",
        headerName: (
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>Sections</span>
        ),
        flex: 0.1,
        headerAlign: "center",
        sortable: false,
        renderCell: (params) => {
          const sections =
            params.value && params.value.length > 0
              ? params.value.map((standard) => standard).join(", ")
              : "";
          return (
            <Typography
              variant="h6"
              style={{
                height: "3em",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
              }}
            >
              {sections}
            </Typography>
          );
        },
      },
  
      {
        field: "add",
        headerName: (
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>Action</span>
        ),
        flex: 0.09,
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderCell: (params) => (
          <Box display="flex">
            <Button
              sx={{
                marginRight: "14px",
              }}
              type="submit"
              color="secondary"
              variant="contained"
              onClick={(event) => {
                ShowSections(event, params);
                setStandardId(params.row.id);
                setStandardName(params.row.name);
              }}
            >
              View Sections
            </Button>
            <Divider orientation="vertical" flexItem />
            <IconButton 
            onClick={() => {
              setIsEditingStandard(true)
              setSelectedStandard(params.row)
            }}
            >
                <EditNoteOutlinedIcon
                  color="white"
                  sx={{
                    fontSize: 24,
                    "&:hover": {
                      color: "greenyellow",
                    },
                  }}
                />
              </IconButton>
              <Modal open={isEditingStandard} onClose={!isEditingStandard}>
              <EditStandard 
              key={params.row.id} 
              standard={selectedStandard} 
              onclose={(_onclose) => 
              setIsEditingStandard(_onclose)} 
              onAlert={(alert) => setShowAlert(alert)}
              refreshKey={newKey => setRefreshKey(newKey)}
              onCloseAlertWithTime={onCloseAlertWithTime}
              />
              </Modal>
            <Tooltip title="Important! Once executed, this action cannot be undone. Deleting this Standard permanently erases associated or related data and completion status. Please consider the consequences before proceeding.">
              <IconButton onClick={() => handleOpenDelete(params.row.id)}>
                <DeleteOutlineOutlinedIcon
                  color="white"
                  sx={{
                    fontSize: 20,
                    "&:hover": {
                      color: "red",
                    },
                  }}
                />
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" flexItem />
          </Box>
        ),
      },
    ];
  
    return (
      <>
        {_isCytechAdmin ? 
      <Box m="15">
        <Dialog
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this Standard?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDelete}
              variant="outlined"
              color="error"
              disabled={isProcessing}
            >
              Cancel
            </Button>
            {isProcessing ? (
              <CircularProgress size={23} style={{ margin: "0 10px" }} />
            ) : (
              <Button
                onClick={deleteStandard}
                variant="contained"
                color="success"
                autoFocus
                disabled={isProcessing}
              >
                Yes
              </Button>
            )}
          </DialogActions>
        </Dialog>
      
        <Box>
          
          <Dialog open={openAdd} onClose={handleCloseAdd}>
            <DialogContent
              sx={{
                top: "50%",
                left: "50%",
                boxShadow: 24,
                p: 2,
                borderRadius: "5px",
                justifyContent: "center",
                "& .MuiTextField-root": { m: 1, width: "60ch" },
              }}
            >
              <Stack display="flex" direction="column">
                <Typography variant="h4" paddingBottom={2}>
                  Add New Standard
                </Typography>
                <TextField
                  required
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  autoFocus={openAdd}
                  onChange={(event) => {
                    const value = event.target.value;
                    setName(value);
                  }}
                  label="Standard Name"
                  variant="outlined"
                  size="small"
                  paddingBottom={2}
                />
                <TextField
                  type="text"
                  id="standardDescription"
                  name="standardDescription"
                  value={description}
                  onChange={(event) => {
                    const value = event.target.value;
                    setDescription(value);
                  }}
                  label="Description"
                  variant="outlined"
                  multiline
                  rows={4}
                  size="small"
                />
                <Stack
                  display="flex"
                  direction="row"
                  justifyContent="end"
                  paddingTop={1}
                  spacing={1}
                >
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      setName("");
                      setDescription("");
                      handleCloseAdd();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={handleConfirmSaveStandard}
                    disabled={isAddingStandard || !name.trim()}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </DialogContent>
          </Dialog>
        </Box>
        <Box
          height="84vh"
          marginTop="3vh"
          display={"flex"}
          flexDirection={"column"}
          sx={{
            borderRadius: "5px",
            "& .MuiDataGrid-root": {
              border: "none",
              backgroundColor: colors.black[100],
            },
            "& .MuiDataGrid-cell": {
              borderBottomWidth: "1px",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.primary[900],
              borderBottom: "",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.black[800],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "",
              backgroundColor: colors.primary[900],
            },
          }}
        >
          <DataGrid
            rows={compliance}
            columns={columns}
            loading={loading}
            disableRowSelectionOnClick={true}
            disableColumnFilter={true} //column filter and export
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableColumnMenu={true}
            disableSelectionOnClick={false}
            editMode="cell"
            disableExport={true}
            pagination={false}
            showColumnRightBorder={true} // show the vertical line between columns
            showCellRightBorder={true}
            isCellEditable={false} // show the vertical line between cells
            components={{
              Toolbar: CustomQuickFilterToolbar,
            }}
          />
          <Snackbar
            open={openDeleteSnackbar}
            onClose={handleDeleteSnackbarClose}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }} // move Snackbar to top right
            style={{ marginTop: "100px" }} // moves Snackbar 100px down
          >
            <Alert severity="error" variant="filled">
              <Box
                sx={{
                  display: "flex", // Use Flexbox for alignment
                  alignItems: "center", // Align items on the center line
                  justifyContent: "center", // Center elements horizontally
                  width: "100%", // Make sure the box fills up the entire width of the Snackbar
                  height: "100%",
                  padding: "0 10px", // Some padding to ensure some space between text and CircularProgress
                }}
              >
                <Typography
                  variant="body2"
                  color="inherit"
                  sx={{ marginRight: "5px" }}
                >
                  Successfully Deleted Standard! Updating Data in{" "}
                </Typography>
                <DeleteProgress value={(countdown / 3) * 100} />{" "}
                {/* Convert the seconds to a percentage of 3 seconds */}
              </Box>
            </Alert>
          </Snackbar>
          <Modal open={open} onClose={() => setOpen(false)}>
            <Box
              sx={{
                position: "absolute",
                top: "53%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                height: "94%",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: "5px",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  background: colors.primary[900],
                  boxShadow: 10,
                  p: 1,
                }}
                borderRadius={1}
              >
                <Stack
                  display="flex"
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography variant="h2">{standardName}</Typography>
                  <Stack display="flex" direction="row">
                    <Button
                      color="success"
                      startIcon={<PostAddOutlinedIcon />}
                      variant="contained"
                      sx={{
                        bottom: "1px",
                        marginRight: 2,
                        marginTop: 1,
                        height: "auto",
                        width: "auto",
                        ransition: "transform 0.2s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.1) translateY(-1px)",
                        },
                      }}
                      onClick={() => {
                        handleOpen();
                      }}
                    >
                      Section
                    </Button>
                    <IconButton
                      variant="contained"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseOutlined />
                    </IconButton>
                  </Stack>
                </Stack>
                <Dialog
                  open={openSection}
                  onClose={handleClose}
                  // keepMounted
                  sx={{
                    display: "flex",
                    width: "100%", // adjust the width as needed
                    height: "100%", // adjust the height as needed
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto",
                  }}
                >
                  <DialogContent>
                    <Stack display="flex" direction="column">
                      <Typography variant="h4" paddingBottom={2}>
                        Add New Section
                      </Typography>
                      <TextField
                        required
                        type="text"
                        id="sectionDescription"
                        name="sectionDescription"
                        value={sectionName}
                        autoFocus={open}
                        onChange={(event) => {
                          const value = event.target.value;
                          setSectionName(value);
                        }}
                        label="Section Name"
                        variant="outlined"
                        size="small"
                        sx={{ width: "50vh", marginBottom: "2vh" }}
                      />
                      <TextField
                        type="text"
                        id="description"
                        name="description"
                        value={sectionDescription}
                        onChange={(event) => {
                          const value = event.target.value;
                          setSectionDescription(value);
                        }}
                        label="Description"
                        variant="outlined"
                        multiline
                        rows={4}
                        size="small"
                        sx={{ width: "50vh", marginBottom: "2vh" }}
                      />
                      <Stack
                        display="flex"
                        direction="row"
                        justifyContent="end"
                        paddingTop={1}
                        spacing={1}
                      >
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={() => {
                            setSectionDescription("");
                            setSectionName("");
                            handleClose();
                          }}
                        >
                          Cancel
                        </Button>
  
                        <Button
                          type="submit"
                          color="secondary"
                          variant="contained"
                          disabled={isAddingSection || !sectionName.trim()}
                          onClick={handleConfirmSave}
                        >
                          Save
                        </Button>
                      </Stack>
                    </Stack>
                  </DialogContent>
                </Dialog>
                <Box marginTop={"1vh"}>
                  <Typography fontWeight={"bold"} fontSize={"25px"}>
                    {/* {standardName} */}
                  </Typography>
                  <Typography
                    fontWeight={"semibold"}
                    color={"GrayText"}
                    fontSize={"28px"}
                  >
                    {""}
                  </Typography>
                </Box>
  
                {/* ==========================================Collapsible Table============================================= */}
                <ManageStandard
                  standardId={standarId}
                  standardName={standardName}
                  newSection={newSection}
                />
                <Box>
                  <Box
                    display={"flex"}
                    variant="contained"
                    onClick={() => {}}
                    sx={{
                      position: "absolute",
                      bottom: "4%",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "left" }}>
                <Box
                  sx={{
                    position: "absolute",
                    top: 860,
                    width: "30%",
                    paddingRight: "3vh",
                  }}
                >
                  {showAddAlert.isShow && (
                    <CustomAlert
                      severity="success"
                      onClose={() => {
                        setShowAddAlert({ isShow: false });
                      }}
                      open={showAddAlert.isShow}
                      successMessage={showAddAlert.message}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
        {/* )} */}
        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <Box
            sx={{
              position: "absolute",
              top: 145,
              width: "30%",
              paddingRight: "3vh",
            }}
          >
            {showAlert.isShow && (
              <CustomAlert
                severity="success"
                onClose={() => {
                  setShowAlert({ isShow: false });
                }}
                open={showAlert.isShow}
                successMessage={showAlert.message}
              />
            )}
          </Box>
        </Box>
      </Box> : 
      <div style={{ padding: 12, paddingLeft: 8}}>
        <h1>Oops! Something went wrong</h1>
        <p style={{ paddingLeft: 12}}>Sorry, you don't have admin access.</p>
      </div>
      }
      </>
    );
  };
  export default StandardTable;
  