import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import useAxios from '../hooks/useAxios';
import axios from '../api/api'
import standardService from '../api/services/standardService';
import { useDispatch } from 'react-redux';
import { trigger } from '../feature/slices/standards/standardSlice';

// const options = ['In Progress', 'Completed'];

export default function TaskStatus({ taskStatus, taskProgress, onUpdateData, onUpdateTaskStatus}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedStatus, setSelectedStatus] = React.useState(taskStatus ? taskStatus : null);
  const isAssignee = localStorage.getItem('isAssignee')
  const dispatch = useDispatch()

  const _status = [
    'noneexistent', 'initial', 'limited', 'defined', 'managed', 'optimized', 'not applicable'
  ]

  const statusLabels = {
    noneexistent: 'Noneexistent 0%',
    initial: 'Initial 20%',
    limited: 'Limited 40%',
    defined: 'Defined 60%',
    managed: 'Managed 80%',
    optimized: 'Optimized 100%',
    'not applicable': 'Not Applicable'
  };

  const handleMenuItemClick = (status, index) => {
    setSelectedIndex(index);
    setSelectedStatus(status);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };



  const updateTaskStatus = async (taskProgressId, task_status) => {
    try {
      const result = await standardService.updateTaskStatus(taskProgressId, { task_status: task_status })
      dispatch(trigger())
      setOpen(false); // Add this line to close the dropdown
      onUpdateTaskStatus({ id : taskProgressId, task_status: task_status })
      onUpdateData({id : taskProgressId, task_status: task_status})
    } catch (error) {
    }
  };

  return (
    <React.Fragment>
      <ButtonGroup variant="outlined" ref={anchorRef} aria-label="split button" sx={{
        '.MuiButton-root': {
          '&.MuiButton-outlined': {
            color: 'white',
            borderColor: 'GrayText',
          }
        }
      }}>
        {selectedStatus ? <Button>{selectedStatus}</Button> :
          <Button sx={{ cursor: 'default' }}>{taskStatus}</Button>}
        {isAssignee === 'false' && (
          <Button
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {_status.map((option, index) => (
                    <MenuItem
                      key={option}
                      // disabled={index === 3}
                      selected={index === selectedIndex}
                      onClick={() => {
                        updateTaskStatus(taskProgress?.id, option);
                        setSelectedStatus(option);
                      }}
                    >
                      {statusLabels[option]}
                    </MenuItem>))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}