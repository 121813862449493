import React, { useState } from 'react';
// import { DataGrid } from '@mui/x-data-grid';
import { Box, Checkbox, CircularProgress, FormControl, FormControlLabel, LinearProgress, Paper } from '@mui/material';
// import { CheckBox } from '@mui/icons-material';

export default function DataTable({ onSelectedItem, standard, loading }) {
  const [checked, setChecked] = useState(false)

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: '5vh',
        marginBottom: '1vh'
      }}
    >
        <FormControlLabel 
          required 
          control={
            <Checkbox 
              checked={checked} 
              onChange={() =>{
                onSelectedItem('')
                setChecked(!checked)
              }}
            />
          }
          label={standard.name} 
          // onClick={() => {
          // }}
        />
    </Box>
  );
}