import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        // primary: {
        //   100: "#cfecf8",
        //   200: "#a0d9f1",
        //   300: "#70c6e9",
        //   400: "#41b3e2",
        //   500: "#11a0db",
        //   600: "#0e80af",
        //   700: "#0a6083",
        //   800: "#074058",
        //   900: "#03202c"
        // },
        primary: {
          100: "#cfecf8",
          200: "#a0d9f1",
          300: "#70c6e9",
          400: "#41b3e2",
          500: "#11a0db",
          600: "#0e80af",
          700: "#0a6083",
          800: "#074058",
          900: "#03202c"
        },
        
        // primary: {
        //   100: "#d0d1d5",
        //   200: "#a1a4ab",
        //   300: "#727681",
        //   400: "#1F2A40",
        //   500: "#141b2d",
        //   600: "#101624",
        //   700: "#0c101b",
        //   800: "#080b12",
        //   900: "#040509",
        // },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
        black: {
          100: "#cccfd0",
          200: "#9a9fa2",
          300: "#677073",
          400: "#354045",
          500: "#021016",
          600: "#020d12",
          700: "#010a0d",
          800: "#010609",
          900: "#000304"
        },
        blue: {
          100: "#cfecf8",
          200: "#a0d9f1",
          300: "#70c6e9",
          400: "#41b3e2",
          500: "#11a0db",
          600: "#0e80af",
          700: "#0a6083",
          800: "#074058",
          900: "#03202c"
        },
      }
    : {
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },

        primary: {
          100: "#dbeade",
          200: "#b7d5bd",
          300: "#93bf9c",
          400: "#6faa7b",
          500: "#4b955a",
          600: "#3c7748",
          700: "#2d5936",
          800: "#1e3c24",
          900: "#0f1e12",
        },
        greenAccent: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
        purple: {
          100: "#e8ded0",
          200: "#d2bda1",
          300: "#bb9d73",
          400: "#a57c44",
          500: "#8e5b15",
          600: "#724911",
          700: "#55370d",
          800: "#392408",
          900: "#1c1204"
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.primary[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.black[900],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[600],
            },
            secondary: {
              main: colors.primary[600],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#ffffff",
            },
          }),
    },
    typography: {
      fontFamily: ["Open Sans", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Open Sans", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("dark");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};