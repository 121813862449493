import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { Box } from "@mui/material";
import axios from "../../api/api";
import api from "../../api/api";

function generateSeriesData(chartData) {
  const uniqueSections = Array.from(
    new Set(
      chartData.flatMap((standard) =>
        standard.Sections_data.map((section) =>
          section.sections_name.replace("~", "")
        )
      )
    )
  );
  const series = uniqueSections.map((section) => {
    return {
      name: section,
      data: chartData.map((standard) => {
        const matchingSection = standard.Sections_data.find(
          (s) => s.sections_name === section
        );
        return matchingSection ? matchingSection.sections_progress : 0;
      }),
    };
  });

  return series;

}

function TestGraph() {
  const _clientId = localStorage.getItem("client");
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(false);

  api.interceptors.request.use(
    function (config) {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );


  useEffect(() => {
    setLoading(true);
    const fetchChartData = async () => {
      try {
        const response = await axios.get(
          `scm/taskprogresses/chart-data/?app=${process.env.REACT_APP_NAME}&client_id=${_clientId}`
        );
        setChartData(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchChartData();
  }, [_clientId]);


  const series = generateSeriesData(chartData);

  const categories = chartData.map((standard) => standard.ClientStandard);

  const colorsArray = [
    "#FBCC16", // original color
    "#11A0DB", // original color
    "#67AD45", // original color
    "#F99D1B", // original color
    "#FF0000", // original color
    "#00A86B", // a bright green
    "#F15A22", // a vibrant orange
    "#0072C6", // a bright blue
    "#D3D3D3", // a light gray
    "#FF8C00", // a bright orange
    "#00BFFF", // a light blue
    "#FF1493", // a bright pink
    "#00CED1", // a bright turquoise
    "#8B0000", // a deep red
    "#008000", // a bright green
  ];

  const options = {
  //   series: [{
  //   name: 'Marine Sprite',
  //   data: [44, 55, 41, 37, 22, 43, 21]
  // }, {
  //   name: 'Striking Calf',
  //   data: [53, 32, 33, 52, 13, 43, 32]
  // }, {
  //   name: 'Tank Picture',
  //   data: [12, 17, 11, 9, 15, 11, 20]
  // }, {
  //   name: 'Bucket Slope',
  //   data: [9, 7, 5, 8, 6, 9, 4]
  // }, {
  //   name: 'Reborn Kid',
  //   data: [25, 12, 19, 32, 25, 24, 10]
  // }],
    chart: {
    type: 'bar',
    height: 350,
    stacked: true,
    stackType: '100%'
  },
  plotOptions: {
    bar: {
      horizontal: true,
    },
  },
  stroke: {
    width: 1,
    colors: ['#fff']
  },
  xaxis: {
    categories,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val + "K"
      }
    }
  },
  fill: {
    opacity: 1,
      colors: series.map((_, index) => colorsArray[index % colorsArray.length]),  
  },
  legend: {
    position: 'top',
    horizontalAlign: 'left',
    offsetX: 40,
    markers: {
      fillColors: colorsArray,
    },
    labels: {
      colors: "#FFFFFF",
    },
    showForSingleSeries: true,
  }
  };
  

  return (
    <div>
      <Box style={{ width: "90%", maxWidth: "800px", margin: "0 auto" }}>
        <ApexCharts
          type="bar"
          options={options}
          width="100%"
          height={380}
          series={series}
        />
      </Box>
    </div>
  )
}

export default TestGraph
      
        
        
      