import {
    Box,
    Modal,
    Typography,
    Button,
    Tooltip,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogContentText,
    DialogActions,
    CircularProgress,
    Snackbar,
    Alert,
  } from "@mui/material";
  import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
  import { tokens } from "../../theme";
  import { useTheme } from "@mui/material";
  import { useEffect, useState } from "react";
  import React from "react";
  import IconButton from "@mui/material/IconButton";
  import { TextField } from "@mui/material";
  import CustomAlert from "../../components/Alert/SuccessAlert";
  import { Stack } from "@mui/system";
  import { AddCircleOutline, CloseOutlined } from "@mui/icons-material";
  import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
  import ManageStandard from "./ManageStandards";
  import axios from "../../api/api";
  import api from "../../api/api";
  import useMediaQuery from "@mui/material/useMediaQuery";
  import InputLabel from '@mui/material/InputLabel';
  import MenuItem from '@mui/material/MenuItem';
  import FormControl from '@mui/material/FormControl';
  import Select from '@mui/material/Select';


  const EditSCF = ({ compliance, onAlert, onclose, refreshKey, onCloseAlertWithTime }) => {
    const [complianceName, setComplianceName] = useState(compliance.name); // Update this line
    const [complianceDesc, setComplianceDesc] = useState(compliance.description);
    const [complianceMethod, setComplianceMethod] = useState(compliance.methods);
    const [selectedApp, setSelectedApp] = React.useState('');
  
  
    // setRefreshKey((oldKey) => oldKey + 1);
    // Utangan pakog Dropdown para sa app defaultValue
    const updateCompliance = async (name, description, methods, app) => {
      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_API_DJANGO}compliances/${compliance.id}/`,
          {
            name: name,
            description: description,
            methods: methods,
            app_name: 'Sequoia'
          }
        );
        onAlert({
          message: "Standard Name updated successfully!",
          isShow: true,
          serverity: "info",
        });
        onCloseAlertWithTime();
        refreshKey(oldKey => refreshKey(oldKey +1))
        return res;
      } catch (e) {
        return e;
      }
    };
  
    return (
      <Box
        sx={{
          zIndex: "99999",
        }}
      >
              <Box
                sx={{
                  display: "flex",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "30%",
                  height: "65%",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 2,
                  borderRadius: "5px",
                  justifyContent: "center",
                  "& .MuiTextField-root": { m: 1, width: "60ch" },
                }}
              >
                <Stack display="flex" direction="column">
                  <Typography variant="h4" paddingBottom={2}>
                    Update Compliance
                  </Typography>
                  <TextField
                    required
                    type="text"
                    id="name"
                    name="name"
                    value={complianceName}
                    autoFocus={onclose}
                    onChange={(event) => {
                      const value = event.target.value;
                      setComplianceName(value);
                    }}
                    focused={true}
                    label="Standard Name"
                    variant="outlined"
                    size="small"
                  />
                  <TextField
                    type="text"
                    id="description"
                    name="description"
                    value={complianceDesc}
                    onChange={(event) => {
                      const value = event.target.value;
                      setComplianceDesc(value);
                    }}
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={8}
                    size="small"
                  />
                  <TextField
                    type="text"
                    id="methods"
                    name="methods"
                    value={complianceMethod}
                    onChange={(event) => {
                      const value = event.target.value;
                      setComplianceMethod(value);
                    }}
                    label="Methods"
                    variant="outlined"
                    multiline
                    rows={8}
                    size="small"
                  />
                  {/* this is for app dropdown */}
                {/* <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Age</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={age}
                    label="Age"
                    onChange={handleChange}
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Box> */}

                  <Stack
                    display="flex"
                    direction="row"
                    justifyContent="end"
                    paddingTop={1}
                    spacing={1}
                  >
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        onclose(!onclose)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={!complianceName}
                      onClick={() => {
                        updateCompliance(complianceName, complianceDesc, complianceMethod);
                        onclose(!onclose)
                      }}
                    >
                      Save
                    </Button>
                  </Stack>
                </Stack>
              </Box>
      </Box>
    );
  };
  
  const ComplianceTable = () => {
    const [showAlert, setShowAlert] = useState({
      message: "",
      isShow: false,
      serverity: "",
    });
    const [showAddAlert, setShowAddAlert] = useState({
      message: "",
      isShow: false,
      serverity: "",
    });
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [open, setOpen] = useState(false);
    const token = localStorage.getItem("token");
    const [complianceId, setComplianceId] = useState(null);
    const [complianceName, setComplianceName] = useState("");
    const [isEditingCompliance, setIsEditingCompliance] = useState(false)
    const [selectedCompliance, setSelectedCompliance] = useState(null);
  
    api.interceptors.request.use((config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
  
    const [compliance, setCompliance] = useState([]); // replace isCompliance with compliance
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isAddingStandard, setIsAddingStandard] = useState(false); // Add this line here
    const [refreshKey, setRefreshKey] = useState(0);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          setLoading(true);
          const response = await axios.get(
            `scm/compliances/?app_id=${process.env.REACT_APP_NAME}`,
            {
              headers: {
                "Content-Language": "en-US",
              },
            }
          );
          setCompliance(response.data); // use setCompliance instead of setIsCompliance
          setError(false);
        } catch (error) {
          setError(true);
          alert("Reload. Error loading data.");
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }, [refreshKey]);
  
    const onCloseAlertWithTime = () => {
      const interval = setInterval(() => {
        setShowAlert((prevState) => ({ ...prevState, isShow: false }));
        clearInterval(interval);
      }, 2000);
    };
    const onCloseAddAlertWithTime = () => {
      const interval = setInterval(() => {
        setShowAddAlert((prevState) => ({ ...prevState, isShow: false }));
        clearInterval(interval);
      }, 2000);
    };
  
    const [openAdd, setOpenAdd] = React.useState(false);
    const [name, setName] = React.useState("");
    const [description, setDescription] = React.useState("");
  
  
    const handleOpenAdd = () => {
      setOpenAdd(true);
    };
  
    const handleCloseAdd = () => {
      setOpenAdd(false);
    };
  
  
    const [openSection, setOpenSection] = React.useState(false);
    const [newSection, setNewSection] = useState(false);
  
    const handleOpen = () => {
      setOpenSection(true);
    };
  
    const handleClose = () => {
      setOpenSection(false);
    };
  
  
    const [dialogOpen, setDialogOpen] = useState(false);
    const isFullScreen = useMediaQuery("(min-width: 1380px)");
    const boxWidth = isFullScreen ? "30%" : "50%"; // THIS IS USED TO RESIZE THE MODAL
    const boxHeight = isFullScreen ? "30%" : "50%";
  
    const CustomQuickFilterToolbar = () => (
      //============================================= the search feature ===========================================
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        justifyItems={"center"}
        bgcolor={colors.black[900]}
      >
        <GridToolbarQuickFilter
          style={{ fontSize: 100 }}
          sx={{ paddingTop: 2 }}
        />
        <Button
          startIcon={<AddCircleOutline />}
          variant="contained"
          color="success"
          size="small"
          sx={{
            border: 5,
            borderColor: "black",
            borderRadius: 2,
          }}
          onClick={() => {
            handleOpenAdd();
          }}
        >
          Add Compliance
        </Button>
      </Box>
    );
  
    // DELETE FUNCTION FOR ROW OF STANDARD
    const [openDelete, setOpenDelete] = useState(false);
    const [toBeDeleted, setToBeDeleted] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState(false); // State for Delete Snackbar
    const _roles = localStorage.getItem('role');
    const _isCytechAdmin = _roles.includes(`${process.env.REACT_APP_ADMIN_ROLE}`);
  
    
  
    const handleDeleteSnackbarOpen = () => {
      setOpenDeleteSnackbar(true);
    };
  
    const handleDeleteSnackbarClose = () => {
      setOpenDeleteSnackbar(false);
    };
  
    const handleOpenDelete = (standarId) => {
      setOpenDelete(true);
      setToBeDeleted(standarId);
    };
  
    const handleCloseDelete = () => {
      if (!isProcessing) {
        setOpenDelete(false);
      }
    };
  
    const handleConfirmSaveCompliance = () => {
      handleSaveCompliance();
    };
  
    const handleSaveCompliance = async () => {
      //props.newSubsection(false)
      try {
        setIsAddingStandard(true); //start submitting
        const response = await axios.post(
          `scm/compliances/`,
          {
            name: name,
            description: description,
            version: 1.0,
            app_name: process.env.REACT_APP_NAME,
          },
          {
            headers: {
              "Content-Language": "en-US",
            },
          }
        );
        if (response.status === 201) {
          setShowAlert({
            message: "New Principle successfully added!",
            isShow: true,
            serverity: "info",
          });
          onCloseAlertWithTime();
          // props.newSubsection(true)
        }
      } catch (error) {
      } finally {
        setIsAddingStandard(false);
        setOpenAdd(false);
      }
    };
  
    const deleteStandard = async () => {
      try {
        setIsProcessing(true);
  
        // Delete the row (which also deletes associated rowProgress instances)
        const res = await axios.delete(
          `${process.env.REACT_APP_API_DJANGO}standards/${toBeDeleted}/`
        );
        // Show Snackbar notification
        handleDeleteSnackbarOpen();
        setOpenDelete(false);
  
        // Add a delay before reloading the page
        await new Promise((resolve) => setTimeout(resolve, 3000));
  
        setRefreshKey((oldKey) => oldKey + 1);
        return res;
      } catch (e) {
        throw e;
      } finally {
        setIsProcessing(false);
      }
    };
    // COUNTDOWN FOR RELOADING PAGE
    const [countdown, setCountdown] = useState(null);
  
    useEffect(() => {
      if (openDeleteSnackbar) {
        setCountdown(3);
      }
    }, [openDeleteSnackbar]);
  
    useEffect(() => {
      if (countdown && countdown > 0) {
        const timer = setTimeout(() => {
          setCountdown(countdown - 1);
        }, 1000);
        return () => clearTimeout(timer);
      }
    }, [countdown]);
  
    function DeleteProgress(props) {
      return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          <CircularProgress {...props} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round((props.value / 100) * 3)}s`}{" "}
              {/* Convert the percentage back to seconds */}
            </Typography>
          </Box>
        </Box>
      );
    }
  
    const columns = [
      {
        field: "name",
        headerName: (
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>Principle Name</span>
        ),
        flex: 0.12,
        headerAlign: "center",
        align: "left",
        sortable: false,
        renderCell: (params) => {
          return (
            <Box
              sx={{
                whiteSpace: "normal",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                fontWeight={"bold"}
                noWrap={false}
                sx={{ color: "skyblue" }}
              >
                {params.value.replace(/["[\]']+/g, "")}
              </Typography>
            </Box>
          );
        },
      },
  
      {
        field: "description",
        headerName: (
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>
            Description
          </span>
        ),
        flex: 0.4,
        headerAlign: "center",
        align: "left",
        sortable: false,
        renderCell: (params) => (
          <Typography
            style={{
              height: "3em",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {params.value.replace(/["[\]']+/g, "")}
          </Typography>
        ),
      },
     
      {
        field: "add",
        headerName: (
          <span style={{ fontSize: "16px", fontWeight: "bold" }}>Action</span>
        ),
        flex: 0.09,
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderCell: (params) => (
          <Box display="flex">
            <Button
              sx={{
                marginRight: "14px",
              }}
              type="submit"
              color="secondary"
              variant="contained"
              onClick={(event) => {
                setIsEditingCompliance(true)
                setSelectedCompliance(params.row)
              }}
            >
              Edit
            </Button>

            <Modal open={isEditingCompliance} onClose={!isEditingCompliance}>
              <EditSCF 
              key={params.row.id} 
              compliance={selectedCompliance} 
              onclose={(_onclose) => 
              setIsEditingCompliance(_onclose)} 
              onAlert={(alert) => setShowAlert(alert)}
              refreshKey={newKey => setRefreshKey(newKey)}
              onCloseAlertWithTime={onCloseAlertWithTime}
              />
              </Modal>
            
          </Box>
        ),
      },
    ];
  
    return (
      <>
        {_isCytechAdmin ? 
      <Box m="15">
        <Dialog
          open={openDelete}
          onClose={handleCloseDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this Standard?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDelete}
              variant="outlined"
              color="error"
              disabled={isProcessing}
            >
              Cancel
            </Button>
            {isProcessing ? (
              <CircularProgress size={23} style={{ margin: "0 10px" }} />
            ) : (
              <Button
                onClick={deleteStandard}
                variant="contained"
                color="success"
                autoFocus
                disabled={isProcessing}
              >
                Yes
              </Button>
            )}
          </DialogActions>
        </Dialog>
      
        <Box>
          
          <Dialog open={openAdd} onClose={handleCloseAdd}>
            <DialogContent
              sx={{
                top: "50%",
                left: "50%",
                boxShadow: 24,
                p: 2,
                borderRadius: "5px",
                justifyContent: "center",
                "& .MuiTextField-root": { m: 1, width: "60ch" },
              }}
            >
              <Stack display="flex" direction="column">
                <Typography variant="h4" paddingBottom={2}>
                  Add New Compliance
                </Typography>
                <TextField
                  required
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  autoFocus={openAdd}
                  onChange={(event) => {
                    const value = event.target.value;
                    setName(value);
                  }}
                  label="Principle Name"
                  variant="outlined"
                  size="small"
                  paddingBottom={2}
                />
                <TextField
                  type="text"
                  id="complianceDescription"
                  name="complianceDescription"
                  value={description}
                  onChange={(event) => {
                    const value = event.target.value;
                    setDescription(value);
                  }}
                  label="Description"
                  variant="outlined"
                  multiline
                  rows={4}
                  size="small"
                />
                <Stack
                  display="flex"
                  direction="row"
                  justifyContent="end"
                  paddingTop={1}
                  spacing={1}
                >
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() => {
                      setName("");
                      setDescription("");
                      handleCloseAdd();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    onClick={handleConfirmSaveCompliance}
                    disabled={isAddingStandard || !name.trim()}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </DialogContent>
          </Dialog>
        </Box>
        <Box
          height="84vh"
          marginTop="3vh"
          display={"flex"}
          flexDirection={"column"}
          sx={{
            borderRadius: "5px",
            "& .MuiDataGrid-root": {
              border: "none",
              backgroundColor: colors.black[100],
            },
            "& .MuiDataGrid-cell": {
              borderBottomWidth: "1px",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.primary[900],
              borderBottom: "",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.black[800],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "",
              backgroundColor: colors.primary[900],
            },
          }}
        >
          <DataGrid
            rows={compliance}
            columns={columns}
            loading={loading}
            disableRowSelectionOnClick={true}
            disableColumnFilter={true} //column filter and export
            disableColumnSelector={true}
            disableDensitySelector={true}
            disableColumnMenu={true}
            disableSelectionOnClick={false}
            editMode="cell"
            disableExport={true}
            pagination={false}
            showColumnRightBorder={true} // show the vertical line between columns
            showCellRightBorder={true}
            isCellEditable={false} // show the vertical line between cells
            components={{
              Toolbar: CustomQuickFilterToolbar,
            }}
          />
          <Snackbar
            open={openDeleteSnackbar}
            onClose={handleDeleteSnackbarClose}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "top", horizontal: "right" }} // move Snackbar to top right
            style={{ marginTop: "100px" }} // moves Snackbar 100px down
          >
            <Alert severity="error" variant="filled">
              <Box
                sx={{
                  display: "flex", // Use Flexbox for alignment
                  alignItems: "center", // Align items on the center line
                  justifyContent: "center", // Center elements horizontally
                  width: "100%", // Make sure the box fills up the entire width of the Snackbar
                  height: "100%",
                  padding: "0 10px", // Some padding to ensure some space between text and CircularProgress
                }}
              >
                <Typography
                  variant="body2"
                  color="inherit"
                  sx={{ marginRight: "5px" }}
                >
                  Successfully Deleted Standard! Updating Data in{" "}
                </Typography>
                <DeleteProgress value={(countdown / 3) * 100} />{" "}
                {/* Convert the seconds to a percentage of 3 seconds */}
              </Box>
            </Alert>
          </Snackbar>
          <Modal open={open} onClose={() => setOpen(false)}>
            <Box
              sx={{
                position: "absolute",
                top: "53%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "100%",
                height: "94%",
                bgcolor: "background.paper",
                boxShadow: 24,
                borderRadius: "5px",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  background: colors.primary[900],
                  boxShadow: 10,
                  p: 1,
                }}
                borderRadius={1}
              >
                <Stack
                  display="flex"
                  direction="row"
                  justifyContent="space-between"
                >
                  <Typography variant="h2">{complianceName}</Typography>
                  <Stack display="flex" direction="row">
                    <Button
                      color="success"
                      startIcon={<PostAddOutlinedIcon />}
                      variant="contained"
                      sx={{
                        bottom: "1px",
                        marginRight: 2,
                        marginTop: 1,
                        height: "auto",
                        width: "auto",
                        ransition: "transform 0.2s ease-in-out",
                        "&:hover": {
                          transform: "scale(1.1) translateY(-1px)",
                        },
                      }}
                      onClick={() => {
                        handleOpen();
                      }}
                    >
                      Section
                    </Button>
                    <IconButton
                      variant="contained"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <CloseOutlined />
                    </IconButton>
                  </Stack>
                </Stack>
                <Box marginTop={"1vh"}>
                  <Typography fontWeight={"bold"} fontSize={"25px"}>
                    {/* {standardName} */}
                  </Typography>
                  <Typography
                    fontWeight={"semibold"}
                    color={"GrayText"}
                    fontSize={"28px"}
                  >
                    {""}
                  </Typography>
                </Box>
                <Box>
                  <Box
                    display={"flex"}
                    variant="contained"
                    onClick={() => {}}
                    sx={{
                      position: "absolute",
                      bottom: "4%",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  >
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "left" }}>
                <Box
                  sx={{
                    position: "absolute",
                    top: 860,
                    width: "30%",
                    paddingRight: "3vh",
                  }}
                >
                  {showAddAlert.isShow && (
                    <CustomAlert
                      severity="success"
                      onClose={() => {
                        setShowAddAlert({ isShow: false });
                      }}
                      open={showAddAlert.isShow}
                      successMessage={showAddAlert.message}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
        {/* )} */}
        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <Box
            sx={{
              position: "absolute",
              top: 145,
              width: "30%",
              paddingRight: "3vh",
            }}
          >
            {showAlert.isShow && (
              <CustomAlert
                severity="success"
                onClose={() => {
                  setShowAlert({ isShow: false });
                }}
                open={showAlert.isShow}
                successMessage={showAlert.message}
              />
            )}
          </Box>
        </Box>
      </Box> : 
      <div style={{ padding: 12, paddingLeft: 8}}>
        <h1>Oops! Something went wrong</h1>
        <p style={{ paddingLeft: 12}}>Sorry, you don't have admin access.</p>
      </div>
      }
      </>
    );
  };
  export default ComplianceTable;
  