import * as React from "react";
import { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Paper,
  Grid,
  Box,
  Typography,
  Alert,
  CircularProgress,
} from "@mui/material";
import logo from "../../image.png";
import logo1 from "../../bg.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../api/services/login";
import axios from "axios";
import { loginUser } from "../../feature/slices/userSlice";

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAllowed, setAllowed] = useState(true);
  const [inputError, setInputError] = useState(false);
  const [serverError, setServerError] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token !== null && token !== undefined) {
      dispatch({ type: "LOGIN_SUCCESS" });
      if (localStorage.getItem("isAssignee") === "true"){
        navigate("/task_assignee");
      } else {
        navigate("/dashboard");
      }
    }
  }, [dispatch, navigate]);

  const fetchStakeholder = async (token, stakeholderId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_LOGIN}scm/stakeholders/?stakeholder_id=${stakeholderId}&app_id=${process.env.REACT_APP_NAME}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      setServerError(true);
      return error;
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await login(dispatch, {
        email: email,
        password: password,
      });

      const stakeholderInfo = await fetchStakeholder(
        res.data.access_token,
        res.data.id
      );
      localStorage.setItem(
        "stakeholder_info",
        JSON.stringify(stakeholderInfo.data)
      );
      const _stakeholder = JSON.parse(localStorage.getItem("stakeholder_info"));

      if ((_stakeholder !== null || _stakeholder !== undefined) && _stakeholder.length > 0) {
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("isAuthenticated", true);
        localStorage.setItem("email", res.data.email);
        localStorage.setItem("client", res.data.client);
        localStorage.setItem("user", res.data.id);
        localStorage.setItem("isAssignee", (res.data.role_name.includes("assignee") || res.data.role_name.includes("CyTech User")));
        localStorage.setItem("isCytechUser", res.data.role_name.includes(process.env.REACT_APP_USER_ROLE));
        localStorage.setItem("role", JSON.stringify(res.data.role_name));
        dispatch(loginUser({ isAssignee: res.data.role_name === "assignee" }));

        const _stakeholderApps = _stakeholder[0].app;
        const _isAllowed = _stakeholderApps.some(
          (app) => app === `${process.env.REACT_APP_NAME}`
        );

        if (!_isAllowed) {
          setLoading(false);
        }

        setAllowed(_isAllowed);

        if (_isAllowed) {
          dispatch({ type: "LOGIN_SUCCESS" });
          if (localStorage.getItem("isAssignee") === "true") {
            navigate("/task_assignee");
          } else {
            navigate("/dashboard");
          }
          window.location.reload();
        }
      } else {
        localStorage.removeItem("stakeholder_info");
        setError(true);
        setAllowed(false);
        setLoading(false); // Stop the loading here too
        navigate("/login");
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        if (error.response.status === 500) {
          setServerError(true);
        } else {
          setInputError(true);
        }
      } else if (error.request) {
        setServerError(true);
      } else {
        setInputError(true);
      }
    }
  };

  const handleLogin = (event) => {
    if (event.target.id === "email") setEmail(event.target.value);
    else if (event.target.id === "password") setPassword(event.target.value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        alignItems: "center",
      }}
    >
      <Grid item xs={6} container justifyContent="center" alignItems="center">
        <img
          src={logo}
          alt="LOGO"
          style={{
            width: "70%",
            height: "70%",
            marginLeft: "30vh",
          }}
        />
      </Grid>
      <Grid
        item
        xs={4}
        container
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <img
          src={logo1}
          alt="CyTech_Logo"
          style={{
            display: "block",
            maxWidth: "100%",
            maxHeight: "100%",
            marginBottom: "1rem",
          }}
        />

        {loading ? (
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              pt: 10,
            }}
          >
            <Typography pb={2}>Logging in ...</Typography>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Paper elevation={3}>
            <Box p={4}>
              <Typography variant="h4" align="center" gutterBottom>
                Login
              </Typography>
              {/* {error && !isAllowed && (
                <Alert severity="error" sx={{ mb: 2 }}>
                  Wrong email or password
                </Alert>
              )} */}
              {serverError ? (
                <Alert severity="error" sx={{ mb: 2 }}>
                  Server is down. Please try again later.
                </Alert>
              ) : !isAllowed ? (
                <Alert severity="error" sx={{ mb: 2 }}>
                  You are not allowed to access this app
                </Alert>
              ) : inputError && isAllowed ? (
                <Alert severity="error" sx={{ mb: 2 }}>
                  Wrong email or password
                </Alert>
              ) : null}
              <TextField
                id="email"
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={handleLogin}
              />
              <TextField
                id="password"
                label="Password"
                variant="outlined"
                type="password"
                fullWidth
                margin="normal"
                value={password}
                onChange={handleLogin}
                onKeyPress={handleKeyPress}
              />
              <Button
                variant="contained"
                fullWidth
                onClick={handleSubmit}
                sx={{ mt: 2 }}
              >
                Login
              </Button>
            </Box>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
}

export default LoginPage;
