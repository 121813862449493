import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "../../../api/api";


export const getAssignees = createAsyncThunk( 
  "assignees/getAssignees", 
  async (params, thunk) => {
    const _client = localStorage.getItem("client");
    const _roles = JSON.parse(localStorage.getItem('role'));
    const _isCytechAdmin = _roles.includes(`${process.env.REACT_APP_ADMIN_ROLE}`);
      try {
      if(_isCytechAdmin){
        const response = await axios.get(
          `scm/stakeholderRole/?role_name=${process.env.REACT_APP_USER_ROLE}`,
          {
            headers: {
              "Content-Language": "en-US",
            },
          }
        );
        return response.data.map((stakeholder) => stakeholder.stakeholder);
      }else{
        const response = await axios.get(
          `scm/stakeholderRole/?client=${_client}&app=${process.env.REACT_APP_NAME}`,
          {
            headers: {
              "Content-Language": "en-US",
            },
          }
        );
        return response.data.map((stakeholder) => stakeholder.stakeholder);
      }
    } catch (error) {
      return thunk.rejectWithValue(error.response.data);
    }
})

const assigneeSlice = createSlice({
    name: "assignees",
    initialState: {
        assignees: [],
        loading: false,
        error: null,
    },
    extraReducers : (builder) => {

        builder.addCase(getAssignees.pending, (state) => {
          state.loading = true;
        })
          .addCase(getAssignees.fulfilled, (state, action) => {
          state.loading = false;
          state.assignees = action.payload;
          })
          .addCase(getAssignees.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload;
      });
    }
})


export default assigneeSlice.reducer;