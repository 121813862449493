import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userdata: {
      id: "",
      first_name: "",
      last_name: "",
      username: "",
      access_token: "",
    },
    isAuthenticated: false,
    isAssignee: false
  },
  reducers: {
    loginUser: (state, action) => {
      state.userdata = action.payload;
      state.isAssignee = action.payload['isAssignee']
      state.isAuthenticated = true;
    },
    loginAsAssignee: (state, action) => {
      state.userdata = action.payload;
      state.isAssignee = true;
    },
    autoLogoutUser: (state, action) => {
      state.isAuthenticated = false;
      state.isAssignee = false;
    },
    reset_userdata: (state, action) => {
      state.userdata = {
        id: "",
        first_name: "",
        last_name: "",
        username: "",
        access_token: "",
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { loginUser, autoLogoutUser, reset_userdata } = userSlice.actions;

export default userSlice.reducer;
