import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { Modal, Tooltip, Badge } from "@mui/material";
import { IconButton } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import standardService from "../api/services/standardService";
import useAxios from "../hooks/useAxios";
import axios from "../api/api";
import {
  ChatBubbleOutlineOutlined,
  CloseOutlined,
  Message,
} from "@mui/icons-material";

function Comment({ task_progress }) {
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const user = localStorage.getItem("user");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleDeleteComment = async (commentId, commentUser, index) => {
    const currentUser = JSON.parse(localStorage.getItem("user"));
    // Check if the current user is authorized to delete the comment
    if (currentUser == commentUser) {
      try {
        await standardService.deleteTaskComment(commentId);
        setComments([
          ...comments.slice(0, index),
          ...comments.slice(index + 1),
        ]);
      } catch (e) {}
    } else {
      alert("You are not authorized to delete this comment.");
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCommentSubmit = async () => {
    // console.log(task_progress);

    if (comment.trim().length == 0) {
      return;
    }
    try {
      setIsSubmitting(true); // start submitting
      const result = await standardService.addTaskComment({
        task_progress: task_progress,
        comment: comment,
        user: user,
      });
      setComments((prev) => [comment, ...prev]);
      setComment("");
    } catch (error) {
      // handle error
    } finally {
      setIsSubmitting(false);
    }
  };

  /* Using useAxios hook to fetch taskcomments and task_progress progress based on dependencies. */
  const [commentList] = useAxios({
    axiosInstance: axios,
    method: "GET",
    url: `scm/taskcomments/?task_progress=${task_progress}`,
    deps: [comments],
  });
  //============
  const [isInputVisible, setIsInputVisible] = useState(false); // Initialize state variable
  // Comment indicator
  const commentCount = commentList.length;
  //============
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {/* If there are comments, render a Badge component with a count and a button */}
        {commentCount > 0 && (
          <Badge badgeContent={commentCount} color="primary">
            <Button
              variant="outlined"
              onClick={handleOpen}
              sx={{
                width: "10vh",
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.1) translateY(-1px)",
                },
              }}
            >
              <Message />
            </Button>
          </Badge>
        )}

        {/* If there are no comments, render a standalone button */}
        {commentCount === 0 && (
          <Button
            variant="outlined"
            onClick={handleOpen}
            sx={{
              width: "10vh",
              transition: "transform 0.2s ease-in-out",
              "&:hover": {
                transform: "scale(1.1) translateY(-1px)",
              },
            }}
          >
            <Message />
          </Button>
        )}
      </Box>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: "100%",
            height: "auto", // Set default height to auto
            maxHeight: isInputVisible ? "90vh" : "100vh", // Conditionally set maxHeight based on state variable
            bgcolor: "background.paper",
            boxShadow: 10,
            borderRadius: 1,
            p: 2,
            display: open ? "block" : "none",
            overflow: "auto",
            "@media (min-width:600px)": {
              width: "60%",
            },
            "@media (min-width:960px)": {
              width: "40%",
            },
            "@media (min-width:1280px)": {
              width: "30%",
            },
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb="10px"
          >
            <Typography variant="h3">Add comment here</Typography>
            <Box
              position="relative"
              sx={{ top: -14, left: 15, bottom: 0, right: 0 }}
            >
              <IconButton onClick={handleClose}>
                <CloseOutlined />
              </IconButton>
            </Box>
          </Box>
          {/* {imageUrl && ( */}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                height: "200px",
                overflowY: commentList.length > 2 ? "scroll" : "hidden",
                borderRadius: "5px",
                mt: 1,
                boxShadow: "inset 0 0 10px rgba(0,0,0,0.3), 0 0 0 .1px #FFFFFF",
              }}
            >
              {commentList
                .slice()
                .reverse()
                .map((comment, index) => (
                  <div key={index}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "10px",
                        paddingLeft: "1vh",
                      }}
                    >
                      <Typography>
                        <span style={{ color: "white" }}>
                          {comment.comment}
                        </span>
                        {" - "}
                        <span style={{ color: "lightblue" }}>
                          {comment.name}
                        </span>
                        {" | "}
                        <span style={{ color: "gray" }}>
                          {new Date(comment.created_at).toLocaleString()}
                        </span>
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={() =>
                          handleDeleteComment(comment.id, comment.user, index)
                        }
                      >
                        <ClearOutlinedIcon fontSize="small" color="secondary" />
                      </IconButton>
                    </Box>
                    {index !== commentList.length - 1 && <Divider />}
                  </div>
                ))}
            </Box>
            {/* Section to add new comment */}
            <Box sx={{ marginTop: "1vh", bottom: 0, left: 0, right: 0 }}>
              <TextField
                id="evidence-comment"
                label="Add a comment"
                outline="none"
                value={comment}
                onChange={handleCommentChange}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleCommentSubmit();
                  }
                }}
                sx={{ width: "100%", height: "70px", marginBottom: "3vh" }}
                disabled={isSubmitting}
              />
              <Button
                variant="contained"
                onClick={handleCommentSubmit}
                sx={{
                  bottom: "10%",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
                disabled={isSubmitting}
              >
                Comment
              </Button>
            </Box>
          </Box>
          {/* )} */}
        </Box>
      </Modal>
    </>
  );
}

export default Comment;
