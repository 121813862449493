import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { Alert, CircularProgress, Modal, Paper, Tooltip } from "@mui/material";
import AWS from 'aws-sdk';
import { IconButton } from "@mui/material";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { makeStyles } from '@mui/styles';
import { Link } from "react-router-dom";
import standardService from "../../api/services/standardService";
import useAxios from "../../hooks/useAxios";
import axios, { fetchExistingUploadedFileList } from '../../api/api'
import { uploadExistingFile } from "../../api/api";
import { CloseOutlined, UploadOutlined } from "@mui/icons-material";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { FileIcon, defaultStyles } from 'react-file-icon';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ViewFile from "./ViewFile";

const subdirectory = process.env.REACT_APP_S3


AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_REGION,
});


function Existing({ client_standardId, newTask }) {
  const s3 = new AWS.S3();

  const [open, setOpen] = useState(false);

  const [imageUrl, setImageUrl] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const [isUploaded, setIsUploaded] = useState(false);
  const [openUploadSnackbar, setOpenUploadSnackbar] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const _app = localStorage.getItem('app');
  const _client = localStorage.getItem('client');

  // Function to handle Snackbar open
  const handleUploadSnackbarOpen = () => {
    setOpenUploadSnackbar(true);
  };


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // Reset preview and input fields when modal is closed
    setPreviewUrl(null);
    setFiles(null);
    setSelectedFiles([])
  };

  const [files, setFiles] = useState(null); // For multiple file upload

  const uploadSelectedFiles = async () => {
    try {
      setIsLoading(true);
      for (const file of files) {
        const fileName = file.name.replace(/\s/g, '-'); // Replace whitespace with '%'
  
        const params = {
          Bucket: process.env.REACT_APP_BUCKET,
          Key: `${subdirectory}/${fileName}`,
          Body: file,
          ContentType: file.type,
          ACL: 'public-read',
        };
  
        const response = await s3.upload(params).promise();
        const reader = new FileReader();
        reader.onload = (event) => setPreviewUrl(event.target.result);
        reader.readAsDataURL(file);
        
        // Call the uploadTaskEvidence function
        await uploadExistingFile(response.Location, _client);
      }
  
      handleUploadSnackbarOpen(); // Open Snackbar after successful upload
      setTimeout(() => {
        handleClose();
      }, 2000); // Close modal after 2 seconds
    } catch (error) {
      alert('Error Uploading File');
    } finally {
      setIsLoading(false);
    }
  };

  const useStyles = makeStyles(theme => ({
    buttonContainer: {
      height: '12vh',
      width: '12vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `2px dashed ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      '&:hover': {
        backgroundColor: '#383838',
      },
    },
    uploadIcon: {
      color: theme.palette.primary.main,
      fontSize: '4rem',
    },
  }));

  const FileUploadButton = ({ onFileSelect }) => {
    const classes = useStyles();
  
    const handleFileSelect = event => {
      const files = event.target.files;
      onFileSelect(files);
      const updatedFiles = [...selectedFiles];
    for (let i = 0; i < files.length; i++) {
      updatedFiles.push(files[i]);
    }
    setSelectedFiles(updatedFiles);
    };
  
    return (
      <IconButton 
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }} 
      className={classes.buttonContainer} component="label" disableRipple>
        <input
            multiple
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileSelect}/>
      
          <CloudUploadIcon className={classes.uploadIcon}/>
        <Typography variant="h6" color="textSecondary">
            Select Files
          </Typography>
     
      </IconButton>
    );
  };

  const handleRemoveFile = index => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
    setFiles(updatedFiles)
  };


  //============
  const [isInputVisible, setIsInputVisible] = useState(false); // Initialize state variable
  //============

  const [selectedFiles, setSelectedFiles] = useState([]);

  return (
    <>
      <Button variant='contained' disabled={newTask} onClick={handleOpen} 
        sx={{transition: 'transform 0.2s ease-in-out',
          '&:hover': {
            transform: 'scale(1.1) translateY(-2px)',
          }}}
          >Upload Existing Document &nbsp;
          <UploadOutlined/>
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: '100%',
            height: 'auto', // Set default height to auto
            maxHeight: isInputVisible ? '90vh' : '100vh', // Conditionally set maxHeight based on state variable
            bgcolor: 'background.paper',
            boxShadow: 10,
            borderRadius: 1,
            p: 2,
            display: open ? 'block' : 'none',
            overflow: 'auto',
            '@media (min-width:600px)': {
              width: '60%',
            },
            '@media (min-width:960px)': {
              width: '40%',
            },
            '@media (min-width:1280px)': {
              width: '30%',
            },
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" mb='10px'>
            <Typography variant='h3'>
              Upload your evidence here
            </Typography>
            <Box position='relative' sx={{ top: -14, left: 15, bottom: 0, right: 0 }}>
              <IconButton onClick={handleClose}>
                <CloseOutlined/>
              </IconButton>
            </Box>
          </Box>
          <Divider variant='fullWidth' />
          {/* ---------------- Button ---------------------- */}
          <Box sx={{ mt: '1vh' }}>
            <Box display='flex' flexDirection='column' alignItems='center'>
              {(previewUrl && !isUploaded) && (
                <Box style={{ textAlign: 'center' }}>
                  <Alert pb={1} sx={{width: '40vh', boxShadow: '5'}}>Uploaded Successfully!</Alert>
                  {/* <img src={previewUrl} alt="preview" height={"100%"} width={"100%"} /> */}
                </Box>
              )}

              <Box style={{ marginTop: '1vh', marginBottom: '1vh' }}>
                <FileUploadButton onFileSelect={setFiles} />
              </Box>
            
                {selectedFiles.length > 0 && (
                    <ul style={{ width: '100%'}}>
                        <Divider/>
                   {selectedFiles.map((file, index) => (
              <>
                {/* <FileIcon size='10'
              extension={file.name.split('.').pop()}
              {...defaultStyles[file.name.split('.').pop()]}/> */}
                <Stack display='flex' direction='row' justifyContent='space-between' alignItems='center'>
                <Typography>{file.name}</Typography>
                <IconButton onClick={() => handleRemoveFile(index)}><CancelOutlinedIcon color="error"/></IconButton>
                </Stack>
                <Divider/>
              </>
            ))}
                  </ul>
                  )}
                
              {(files && !isUploaded && selectedFiles.length !== 0) && (
                <Button variant='contained' onClick={uploadSelectedFiles} sx={{ mt: '1vh', mb: '2vh', width: '12vh' }}  disabled={isLoading}>
                  {isLoading ? (<CircularProgress size={24} />) : (
                    <>
                      <UploadOutlined />
                    </>
                  )}
                </Button>
              )}
            </Box>

            {imageUrl && (
              <Box style={{ marginTop: '10px', textAlign: 'center' }}>
                <Typography pb={1}>Uploaded!</Typography>
                <img src={imageUrl} height={"100%"} width={"100%"} alt="" />
              </Box>
            )}

            {imageUrl && (
              <Box style={{ marginTop: '0px', textAlign: 'center' }}>
                {/* <Button href={imageUrl} target="_blank" variant='contained' onClick={downloadS3} sx={{ mt: '1vh',mb: '2vh', width: '30vh' }}>Download</Button> */}
                {/* <a href={imageUrl} target="_blank" download onClick={downloadS3}>Dowload</a> */}
                <Button href={imageUrl} target="_blank" download={'screens.png'}>View file</Button>
              </Box>
            )}
            <FileList/>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const FileList = () => {
  const [fileList, setFileList] = useState([]);
  const _client = localStorage.getItem('client');
  const [isLoading, setIsLoading] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleOpenViewFile = () => {
    setOpenFile(true);
    document.addEventListener('contextmenu', event => event.preventDefault())
  };
  
  const handleCloseViewFile = () => {
    setOpenFile(false);
    document.removeEventListener('contextmenu', event => event.preventDefault())
  };

  useEffect(() => {
    const fetchFileList = async () => {
      try {
        const list = await fetchExistingUploadedFileList(_client);
  
        const uniqueNames = Array.from(new Set(list.map(file => file.name))); // Filter unique names
  
        // filter the list data by unique names
        const uniqueList = uniqueNames.map(name => {
          return list.find(file => file.name === name)
        })

        setFileList(uniqueList);
      } catch (error) {
        // Handle error
      }
    };
    fetchFileList();
}, []);

  return (
    <div>
      {fileList.length > 0 && (
        <>
        <Divider variant='fullWidth' />
        <Typography paddingTop={1} color='Highlight' fontWeight='bold'>Recently uploaded</Typography>
        <List dense={true} sx={{
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'background.paper' }}>
          {fileList.map((value, index) => (
            <ListItem key={`${value.id}-${index}`} sx={{ height: '4vh', padding: 0}}
            secondaryAction=
            {
              <Stack direction='row' spacing={1}>
                  <IconButton edge="end" aria-label="preview" onClick={() => {
                    handleOpenViewFile();
                    setSelectedFile(value);
                  }}>
              <VisibilityOutlinedIcon color='info'/>
              </IconButton>
              <Modal open={openFile} onClose={() => setOpenFile(false)}>
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: 'auto',
                  padding: '1rem',
                  paddingRight: '2rem',
                  paddingLeft: '2rem',
                }}>
                  <ViewFile files={selectedFile} onclose={handleCloseViewFile}/>
                </Box>
              </Modal>
              </Stack>
            }>
               <ListItemIcon sx={{ height: '24px'}}>
                  <FileIcon
                    extension={value.name.split('.').pop()}
                    {...defaultStyles[value.name.split('.').pop()]}/>
                </ListItemIcon>
                <ListItemText id={value.id} primary={value.name} sx={{paddingRight: 10}}/>
            </ListItem>
          ))}
        </List>
        </>
      )}
    </div>
  )


}



export default Existing;
