import axios from "axios";

const addClientCompliance = async (data) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL_LOGIN}scm/clientcompliances/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (e) {}
};

const addClientStandard = async (data) => {
  const token = localStorage.getItem("token");
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL_LOGIN}scm/clientstandard/create/taskprogress/`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

// const addTaskprogresses = async (data) => {
//   const token = localStorage.getItem("token");
//   try {
//     const res = await axios.post(
//       `${process.env.REACT_APP_API_URL_LOGIN}scm/taskprogresses/`,
//       data,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return res;
//   } catch (e) {}
// };

const addTask = async (data) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL_LOGIN}scm/tasks/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (e) {}
};

const updateAssignee = async (task_progress, data) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL_LOGIN}scm/taskprogresses/update-section-assignee/${task_progress}/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (e) {}
};

const updateTaskAssignee = async (taskProgressId, data) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_API_URL_LOGIN}scm/taskprogresses/update-task-assignee/${taskProgressId}/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (e) {}
};

const updateTaskStatus = async (taskProgressId, data) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_API_URL_LOGIN}scm/taskprogresses/patch-task-status/${taskProgressId}/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (e) {}
};

const updateSectionStatus = async (sectionId, data) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_API_DJANGO}taskprogresses/patch-section-status/${sectionId}/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (e) {}
};

const getStatusValuesAndTaskProgress = async (clientStandardId) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_DJANGO}taskprogresses/get-status-values-and-task-progress/?client_standard=${clientStandardId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (e) {
    // handle error here
  }
};

async function getClientComplianceList(complianceId) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DJANGO}clientcompliances/?compliance=${complianceId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

async function getClientStandards(clientComplianceId) {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_DJANGO}client_standards/?client_compliance=${clientComplianceId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

const addTaskComment = async (data) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL_LOGIN}scm/taskcomments/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (e) {}
};

const deleteTaskComment = async (taskcommentId) => {
  const token = localStorage.getItem("token");
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_DJANGO}taskcomments/${taskcommentId}/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res;
  } catch (e) {}
};

const standardService = {
  addClientCompliance,
  addClientStandard,
  // addTaskprogresses,
  addTask,
  updateAssignee,
  updateTaskAssignee,
  updateTaskStatus,
  getClientComplianceList,
  getClientStandards,
  getStatusValuesAndTaskProgress,
  updateSectionStatus,
  addTaskComment,
  deleteTaskComment,
};

export default standardService;
