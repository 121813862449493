import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import useAxios from '../hooks/useAxios';
import api from '../api/api';
import axios from '../api/api';
import standardService from '../api/services/standardService';
import { useDispatch } from 'react-redux';
import { trigger } from '../feature/slices/standards/standardSlice';
import { Avatar, Tooltip, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';

export default function TaskAssignee({ taskAssignee, taskProgress, onUpdateTaskAssignee, assignees, onUpdateData }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const token = localStorage.getItem('token');
  const isAssignee = localStorage.getItem('isAssignee');
  const _user = localStorage.getItem('user');
  const dispatch = useDispatch()
  const _clientId = localStorage.getItem('client')
  const [assignee, setAssignee] = React.useState(taskAssignee);


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const updateTaskAssignee = async (assigneeId, task_assignee) => {
    console.log('task_assignee', task_assignee)
    setOpen(false);
    try {
      const result = await standardService.updateTaskAssignee(taskProgress.id, {task_assignee: assigneeId });
      onUpdateTaskAssignee({ id : taskProgress.id, task_assignee: task_assignee })
      onUpdateData({id : taskProgress.id, task_assignee: task_assignee})
    } catch (error) {
      // handle error
    }
  };




  return (
    <React.Fragment>
      <ButtonGroup variant="outlined-secondary" ref={anchorRef} aria-label="split button" sx={{ border: 1 }}>
        {assignee ? <Button><Typography style={{ textTransform: 'capitalize' }}>{assignee?.first_name}</Typography></Button> :
          <Button sx={{ cursor: 'default' }}>{'UNASSIGNED'}</Button>
        }
        {isAssignee === 'false' && (
          <Button
            variant='outlined-dark'
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
            sx={{ borderLeft: 1 }}
          >
            <ArrowDropDownIcon />
          </Button>
        )
        }

      </ButtonGroup>

      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
          
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem
                sx={{
                  overflow:"auto", 
                  maxHeight:'25vh',
                  width:'7vw',
                  "&::-webkit-scrollbar": {
                    width: "5px",
                  },
      
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "black",
                    borderRadius: "10px",
                  },
      
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                    borderRadius: "4px",
                  },
      
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#555",
                  },
                }}
                >
                  {assignees.map((option, index) => (
                      <MenuItem
                        key={option.id}
                        // disabled={index === 3}
                        selected={index === selectedIndex}
                        onClick={() => {
                          updateTaskAssignee(option?.id, option);
                          setAssignee(option)
                        }}>
                        {option?.first_name.charAt(0).toUpperCase() + option?.first_name.slice(1)}
                      </MenuItem>
                    ))
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}