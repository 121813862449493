import React, { useEffect, useState, createContext, useContext } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AddLinkIcon from '@mui/icons-material/AddLink';
import Checkbox from '@mui/material/Checkbox';  
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getScfControlsByTaskId, tagScfControls } from "../../feature/slices/scf-controls/scf-controlsSlice";

import {
  Button,
  Stack,
  useTheme,
  Grid,
  Icon,
  Skeleton,
  Modal,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Snackbar,
  TextareaAutosize,
} from "@mui/material";
import { tokens } from "../../theme";
import { TextField } from "@mui/material";
import { AddCircleOutline, AddLink } from "@mui/icons-material";
import useAxios from "../../hooks/useAxios";
import axios from '../../api/api'
import { Alert, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery'; // THIS IS USED TO RESIZE THE MODAL
import CustomAlert from '../../components/Alert/SuccessAlert';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RefreshContext = createContext();

function Row(props) {
  const row = props.row;
  const [open, setOpen] = React.useState(false);
  const [alertState, setAlertState] = React.useState({
    message: "",
    isShow: false,
    severity: "",
  });
  const isAssignee = localStorage.getItem("isAssignee");
  const _user = localStorage.getItem("user");
  //===========
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //===========
  const dispatch = useDispatch();
  const _clientId = localStorage.getItem("client");

  // const [assignee, setAssignee] = useState(progressTasks.task_assignee);

  const handleOpen = () => {
    setOpen(true);
  };

  // const { taskProgresses : progressTasks, isLoading, currentRow } = useSelector(state => state.taskProgress)
 

  const [loading, setLoading] = React.useState(false);
  const [isAddingSubSection, setIsAddingSubSection] = useState(false); // Add this line here
 



  const EditSection = ({ section, onAlert, onclose, refreshKey, onCloseAlertWithTime }) => {
    const [sectionName, setSectionName] = useState(section.name); // Update this line
    const [sectionDesc, setSectionDesc] = useState(section.description);
  
  
    const updateSection = async () => {
      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_API_DJANGO}sections/${section.id}/`,
          {
            name: sectionName,
            description: sectionDesc
          }
        );
        return res;
      } catch (e) {
        return e;
      } 
    };
  
    return (
      <Box
        sx={{
          zIndex: "99999",
        }}
      >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  // width: 800,
                  // height: 600,
                  minHeight: '35vh',
                  maxHeight: '90vh',
                  minWidth: '40vh',
                  maxWidth: '100vh',
                  bgcolor:'background.paper',
                  boxShadow: 24,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  p: 4,
                  "& .MuiTextField-root": { m: 1, width: "60ch" },
                }}
              >
                <Stack display="flex" direction="column">
                  <Typography variant="h4" paddingBottom={2}>
                    Update Section
                  </Typography>
                  <TextField
                    required
                    type="text"
                    id="name"
                    name="name"
                    value={sectionName}
                    autoFocus={onclose}
                    onChange={(event) => {
                      const value = event.target.value;
                      setSectionName(value);
                    }}
                    focused={true}
                    label="Section Name"
                    variant="outlined"
                    size="small"
                  />
                  <TextField
                    type="text"
                    id="description"
                    name="description"
                    value={sectionDesc}
                    onChange={(event) => {
                      const value = event.target.value;
                      setSectionDesc(value);
                    }}
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={8}
                    size="small"
                  />
  
                  <Stack
                    display="flex"
                    direction="row"
                    justifyContent="end"
                    paddingTop={1}
                    spacing={1}
                  >
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        onclose(!onclose)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={!sectionName}
                      onClick={() => {
                        updateSection();
                        onclose(!onclose)
                      }}
                    >
                      Save
                    </Button>
                  </Stack>
                </Stack>
              </Box>
      </Box>
    );
  };



  const [openAdd, setOpenAdd] = React.useState(false);
  const [subSectionName, setSubsectionName] = React.useState("");
  const [subSsectionDescription, setSubsectionDescription] = React.useState("");
  const [subSsectionRequirement, setSubsectionRequirement] = React.useState("");

  const handleOpenAdd = () => {
    setOpenAdd(true);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleConfirmSaveSubsection = (id) => {
    handleSaveSubsection(id);
    setDialogOpen(false);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleSaveSubsection = async (sectionId) => {
    props.newSubsection(false);
    try {
      setIsAddingSubSection(true);
      const response = await axios.post(
        `scm/tasks/create-task/`,
        {
          name: subSectionName,
          description: subSsectionDescription,
          requirement: subSsectionRequirement,
          version: 1.0,
          section_id: sectionId,
        },
        {
          headers: {
            "Content-Language": "en-US",
          },
        }
      );
      if (response.status === 201) {
        setAlertState({
          message: "Subsection successfully added!",
          isShow: true,
          severity: "success",
        });
        props.newSubsection(true);
      }
    } catch (error) {
    } finally {
      setIsAddingSubSection(false);
      setOpenAdd(false);
    }
  };



  const isFullScreen = useMediaQuery("(min-width: 1380px)");
  const boxWidth = isFullScreen ? "30%" : "50%"; // THIS IS USED TO RESIZE THE MODAL
  const boxHeight = isFullScreen ? "30%" : "50%";

  // DELETE FUNCTION FOR ROW OF Section
  const [openDelete, setOpenDelete] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState(false); // State for Delete Snackbar
  const setRefreshKey = useContext(RefreshContext);
  const [isEditingSection, setIsEditingSection] = useState(false);

  const handleDeleteSnackbarOpen = () => {
    setOpenDeleteSnackbar(true);
  };

  const handleDeleteSnackbarClose = () => {
    setOpenDeleteSnackbar(false);
  };

  const handleOpenDelete = (sectionId) => {
    setOpenDelete(true);
    setToBeDeleted(sectionId);
  };

  const handleCloseDelete = () => {
    if (!isProcessing) {
      setOpenDelete(false);
    }
  };

  const deleteSection = async () => {
    try {
      setIsProcessing(true);

      // Delete the row (which also deletes associated rowProgress instances)
      const res = await axios.delete(
        `${process.env.REACT_APP_API_DJANGO}sections/${toBeDeleted}/`
      );
      // Show Snackbar notification
      handleDeleteSnackbarOpen();
      setOpenDelete(false);

      // Add a delay before reloading the page
      await new Promise((resolve) => setTimeout(resolve, 3000));

      setRefreshKey((oldKey) => oldKey + 1);
      return res;
    } catch (e) {
      throw e;
    } finally {
      setIsProcessing(false);
    }
  };
  // COUNTDOWN FOR RELOADING PAGE
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    if (openDeleteSnackbar) {
      setCountdown(3);
    }
  }, [openDeleteSnackbar]);

  useEffect(() => {
    if (countdown && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  function DeleteProgress(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round((props.value / 100) * 3)}s`}{" "}
            {/* Convert the percentage back to seconds */}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Dialog
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Section?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDelete}
            variant="outlined"
            color="error"
            disabled={isProcessing}
          >
            Cancel
          </Button>
          {isProcessing ? (
            <CircularProgress size={23} style={{ margin: "0 10px" }} />
          ) : (
            <Button
              onClick={deleteSection}
              variant="contained"
              color="success"
              autoFocus
              disabled={isProcessing}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset", background: colors.black[800] },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row">
            <Box textAlign="left">
              {row.name.replace("~", "")}
            </Box>
        </TableCell>

        <TableCell>
            <Box textAlign="left">
              {row.description}
            </Box>
        </TableCell>
        {/* delete function for sections */}
        <TableCell align="center">
       <IconButton onClick={() => {
        setIsEditingSection(true)
        }}>
              <EditNoteOutlinedIcon
                color="white"
                sx={{
                  fontSize: 24,
                  "&:hover": {
                    color: "yellowgreen",
                  },
                }}
              />
            </IconButton>
        <Modal open={isEditingSection} onClose={!isEditingSection}>
          <EditSection section={row} 
          onclose={(_onclose) => setIsEditingSection(_onclose)}
          />
        </Modal>
          <Tooltip title="Important! Once executed, this action cannot be undone. Deleting this Section permanently erases associated or related data and completion status. Please consider the consequences before proceeding.">
            <IconButton onClick={() => handleOpenDelete(row.id)}>
              <DeleteOutlineOutlinedIcon
                color="white"
                sx={{
                  fontSize: 20,
                  "&:hover": {
                    color: "red",
                  },
                }}
              />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <Snackbar
        open={openDeleteSnackbar}
        onClose={handleDeleteSnackbarClose}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }} // move Snackbar to top right
        style={{ marginTop: "100px" }} // moves Snackbar 100px down
      >
        <Alert severity="error" variant="filled">
          <Box
            sx={{
              display: "flex", // Use Flexbox for alignment
              alignItems: "center", // Align items on the center line
              justifyContent: "center", // Center elements horizontally
              width: "100%", // Make sure the box fills up the entire width of the Snackbar
              height: "100%",
              padding: "0 10px", // Some padding to ensure some space between text and CircularProgress
            }}
          >
            <Typography
              variant="body2"
              color="inherit"
              sx={{ marginRight: "5px" }}
            >
              Successfully Deleted Section! Updating Data in{" "}
            </Typography>
            <DeleteProgress value={(countdown / 3) * 100} />{" "}
            {/* Convert the seconds to a percentage of 3 seconds */}
          </Box>
        </Alert>
      </Snackbar>

      {/* ---------------------------------Child Rows------------------------------------ */}
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
          sx={{ background: colors.grey[900] }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/* =====================================THE ADD TASK BUTTON IS HERE========================================= */}
            <Box sx={{ margin: 1 }}>
              <Stack
                direction="row"
                display="flex"
                justifyContent="space-between"
                spacing={5}
              >
                {/* <Typography variant="h6" fontWeight='bold' gutterBottom component="div" color='highlight'>
                  Tasks
                </Typography> */}
                {/* ================ Add Task Button =================== */}
                {/* {isAssignee === 'false' && (
                  <Button variant='contained' onClick={handleAddTask}>
                    Task&nbsp;<AddCircleOutline />
                  </Button>
                )} */}
              </Stack>
              <Table size="medium" aria-label="Sections">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Stack
                        display="flex"
                        direction="row"
                        sx={{ fontWeight: "bold" }}
                      >
                        Subsection
                        <Tooltip
                          title={
                            "The following subsections are part of the section above, where you can view task requirements and their status. It is also the place where you can upload evidence/requirements and engage in conversations with others through comments."
                          }
                        >
                          <IconButton disableRipple={true} size="small">
                            <HelpOutlineIcon
                              style={{
                                fontSize: "0.8rem",
                                alignSelf: "center",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "bold" }}>
                      Section Title
                    </TableCell>{" "}
                    {/* This is decription sauna*/}
                    <TableCell align="left" sx={{ fontWeight: "bold" }}>
                      Requirements
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        color="warning"
                        startIcon={<PostAddOutlinedIcon />}
                        size="small"
                        variant="contained"
                        sx={{
                          bottom: "1px",
                          height: "auto",
                          width: "auto",
                          ransition: "transform 0.2s ease-in-out",
                          "&:hover": {
                            transform: "scale(1.1) translateY(-1px)",
                          },
                        }}
                        onClick={handleOpenAdd}
                      >
                        Subsection
                      </Button>
                      <Dialog
                        open={openAdd}
                        onClose={handleCloseAdd}
                        // keepMounted
                        sx={{
                          display: "flex",
                          width: "100%", // adjust the width as needed
                          height: "100%", // adjust the height as needed
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "auto",
                        }}
                      >
                        <DialogContent>
                          <Stack display="flex" direction="column">
                            <Typography variant="h4" paddingBottom={2}>
                              Add New Subsection
                            </Typography>
                            <TextField
                              required
                              type="text"
                              id="sectionDescription"
                              name="sectionDescription"
                              value={subSectionName}
                              autoFocus={openAdd}
                              onChange={(event) => {
                                const value = event.target.value;
                                setSubsectionName(value);
                              }}
                              label="Subsection Name"
                              variant="outlined"
                              size="small"
                              paddingBottom={2}
                              sx={{ width: "50vh", marginBottom: "2vh" }}
                            />
                            <TextField
                              type="text"
                              id="description"
                              name="description"
                              value={subSsectionDescription}
                              onChange={(event) => {
                                const value = event.target.value;
                                setSubsectionDescription(value);
                              }}
                              label="Description"
                              variant="outlined"
                              multiline
                              rows={4}
                              size="small"
                              sx={{ width: "50vh", marginBottom: "2vh" }}
                            />
                            <TextField
                              type="text"
                              id="requirements"
                              name="requirements"
                              value={subSsectionRequirement}
                              onChange={(event) => {
                                const value = event.target.value;
                                setSubsectionRequirement(value);
                              }}
                              label="Requirements"
                              variant="outlined"
                              multiline
                              rows={4}
                              size="small"
                              sx={{ width: "50vh", marginBottom: "2vh" }}
                            />
                            <Stack
                              display="flex"
                              direction="row"
                              justifyContent="end"
                              paddingTop={1}
                              spacing={1}
                            >
                              <Button
                                color="secondary"
                                variant="outlined"
                                onClick={() => {
                                  setSubsectionName("");
                                  setSubsectionDescription("");
                                  setSubsectionRequirement("");
                                  handleCloseAdd();
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                type="submit"
                                color="secondary"
                                variant="contained"
                                disabled={
                                  isAddingSubSection || !subSectionName.trim()
                                }
                                // onClick={() => {
                                //   setDialogOpen(true); --> to open dialog
                                // }}
                                onClick={() =>
                                      handleConfirmSaveSubsection(row.id)
                                    }
                              >
                                Save
                              </Button>
                            </Stack>
                          </Stack>
                        </DialogContent>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {loading ? <>Loading...</> : <TaskRow task_id={row?.id} />}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const TaskRow = ({ task_id }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [subsecList, setSubsecList] = React.useState([]);
  const isAssignee = localStorage.getItem("isAssignee");
  const [refreshKey, setRefreshKey] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`scm/tasks/?section=${task_id}`, {
          headers: {
            "Content-Language": "en-US",
          },
        });
        setSubsecList(response.data);
      } catch (error) {
        alert("Reload. Error loading data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [refreshKey]);

  

  // DELETE FUNCTION FOR ROW OF TASK
  const [open, setOpen] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState(false); // State for Delete Snackbar
  const [isEditingSubSection, setIsEditingSubSection] = useState(false);
  const [selectedSubsection, setSelectedSubsection] = useState('');
  const [isTaggingCompliance, setIsTaggingCompliance] = useState(false);
  // const { scfControls } = useSelector((state) => state.scfControls)

  const handleDeleteSnackbarOpen = () => {
    setOpenDeleteSnackbar(true);
  };

  const handleDeleteSnackbarClose = () => {
    setOpenDeleteSnackbar(false);
  };

  const handleOpen = (taskId) => {
    setOpen(true);
    setToBeDeleted(taskId);
  };

  const handleClose = () => {
    if (!isProcessing) {
      setOpen(false);
    }
  };


  const ScfCheckboxTags = ({handleClose, task_id}) => {
    const [selectedScf, setSelectedScf] = useState([]);
    const { scfTasks } = useSelector((state) => state.scfControls)
    const { scfControls } = useSelector((state) => state.scfControls)
    // const [defaultScf, setDefaultScf] = useState([]);
    
    const handleScfSelectionChange = (event, value) => {
      setSelectedScf(value);
    };

    const handleSave = async () => {
      const data = selectedScf.map((v) => ({
        compliance: v.id,
        task: task_id
      }));
        try{
          dispatch(tagScfControls({data: data, taskId: task_id }))
          console.log('task_id', task_id)
        }catch(err){

        }
    }
  
    useEffect(() => {
      // Find the matching controls based on scfTasks
      const matchedScf = scfControls.filter((control) =>
        scfTasks.some((task) => task.compliance.id === control.id)
      );
      setSelectedScf(matchedScf);
    }, [scfTasks, scfControls]);

    return (
      <>
        <Stack display='flex' alignItems='center'>
          <Autocomplete
            multiple
            id="scf-tags"
            options={scfControls}
            disableCloseOnSelect
            // defaultValue={defaultScf}
            getOptionLabel={(option) => option.name.replace(/["[\]',]+/g, "")}
            onChange={handleScfSelectionChange}
            value={selectedScf}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name.replace(/["[\]',]+/g, "")}
              </li>
            )}
            style={{ width: '50vw', minHeight: 600 }}
            renderInput={(params) => (
               <Stack display='flex' direction='column' alignItems='center' spacing={8}>
                <TextField {...params} label="SCF-Controls" placeholder="ScfControls" />
                <Stack display='flex' direction='row' alignItems='right' spacing={4}>
                  <Button variant="contained" onClick={() => {
                    // console.log('selectedScf', selectedScf)
                    handleSave()
                    // console.log('selectedScf', selectedScf)
                  }}>
                    Save
                  </Button>
                  <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                </Stack>
              </Stack>
            )}
          />
        </Stack>
      </>
    );
  };


  const TagCompliance = ({onclose, task_id}) => {

    const handleClose = () => {
      onclose(!onclose)
    } 

    return (
     
                <Box
                sx={{
                  position: "absolute",
                  top: "40%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "60%",
                  height: "60%",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  borderRadius: "5px"
                }}
              >


              
              <Stack display='flex' direction='row' justifyContent='space-between' alignItems='center' padding={2}>
                <Typography variant="h3">
                    Tag SCF-Controls
                  </Typography>
                  <IconButton
                  disableRipple
                  onClick={handleClose}>
                    <CloseOutlinedIcon/>
                  </IconButton>
                </Stack>
                {/* <Stack display='flex' justifyContent='center' direction='column' spacing={40}> */}
                <ScfCheckboxTags handleClose={handleClose} task_id={task_id}/>
                {/* </Stack> */}
                </Box>
    )
  }

  const deleteTask = async () => {
    try {
      setIsProcessing(true);

      // Delete the row (which also deletes associated rowProgress instances)
      const res = await axios.delete(
        `${process.env.REACT_APP_API_DJANGO}tasks/${toBeDeleted}/`
      );
      // Show Snackbar notification
      handleDeleteSnackbarOpen();
      setOpen(false);

      // Add a delay before reloading the page
      await new Promise((resolve) => setTimeout(resolve, 3000));

      setRefreshKey((oldKey) => oldKey + 1);
      return res;
    } catch (e) {
      throw e;
    } finally {
      setIsProcessing(false);
    }
  };
  // COUNTDOWN FOR RELOADING PAGE
  const [countdown, setCountdown] = useState(null);

  useEffect(() => {
    if (openDeleteSnackbar) {
      setCountdown(3);
    }
  }, [openDeleteSnackbar]);

  useEffect(() => {
    if (countdown && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  function DeleteProgress(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round((props.value / 100) * 3)}s`}{" "}
            {/* Convert the percentage back to seconds */}
          </Typography>
        </Box>
      </Box>
    );
  }

  const EditSubsection = ({ subSection, onAlert, onclose, refreshKey, onCloseAlertWithTime }) => {
    const [subSectionName, setSubSectionName] = useState(subSection.name); // Update this line
    const [subSectionDesc, setsubSectionDesc] = useState(subSection.description);
    const [subSectionReq, setsubSectionReq] = useState(subSection.requirement);
  
  
    const updateSubSection = async () => {
      try {
        const res = await axios.patch(
          `${process.env.REACT_APP_API_DJANGO}tasks/${subSection.id}/`,
          {
            name: subSectionName,
            description: subSectionDesc,
            requirement: subSectionReq
          }
        );
        refreshKey(oldKey => refreshKey(oldKey +1))
        return res;
      } catch (e) {
        return e;
      } 
    };
  
    return (
      <Box
        sx={{
          zIndex: "99999",
        }}
      >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  // width: 800,
                  // height: 600,
                  minHeight: '35vh',
                  maxHeight: '100vh',
                  minWidth: '40vh',
                  maxWidth: '100vh',
                  bgcolor:'background.paper',
                  boxShadow: 24,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  p: 4,
                  "& .MuiTextField-root": { m: 1, width: "60ch" },
                }}
              >
                <Stack display="flex" direction="column">
                  <Typography variant="h4" paddingBottom={2}>
                    Update Subsection
                  </Typography>
                  <TextField
                    required
                    type="text"
                    id="name"
                    name="name"
                    value={subSectionName}
                    autoFocus={onclose}
                    onChange={(event) => {
                      const value = event.target.value;
                      setSubSectionName(value);
                    }}
                    focused={true}
                    label="Subsection Name"
                    variant="outlined"
                    size="small"
                  />
                  <TextField
                    type="text"
                    id="description"
                    name="description"
                    value={subSectionDesc}
                    onChange={(event) => {
                      const value = event.target.value;
                      setsubSectionDesc(value);
                    }}
                    label="Description"
                    variant="outlined"
                    multiline
                    rows={4}
                    size="small"
                  />
                  <TextField
                    type="text"
                    id="requirement"
                    name="requirement"
                    value={subSectionReq}
                    onChange={(event) => {
                      const value = event.target.value;
                      setsubSectionReq(value);
                    }}
                    label="Requirement"
                    variant="outlined"
                    multiline
                    rows={8}
                    size="small"
                  />
  
                  <Stack
                    display="flex"
                    direction="row"
                    justifyContent="end"
                    paddingTop={1}
                    spacing={1}
                  >
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={() => {
                        onclose(!onclose)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      color="secondary"
                      variant="contained"
                      disabled={!subSectionName}
                      onClick={() => {
                        updateSubSection();
                        onclose(!onclose)
                      }}
                    >
                      Save
                    </Button>
                  </Stack>
                </Stack>
              </Box>
      </Box>
    );
  };

 
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this subsection?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            color="error"
            disabled={isProcessing}
          >
            Cancel
          </Button>
          {isProcessing ? (
            <CircularProgress size={23} style={{ margin: "0 10px" }} />
          ) : (
            <Button
              onClick={deleteTask}
              variant="contained"
              color="success"
              autoFocus
              disabled={isProcessing}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {subsecList.map((task) => {
        const requirement_newline = task.requirement?.includes("\n");
        const description_newline = task.description?.includes("\n");
        return (
          <TableRow key={task.id}>
            <TableCell component="th" scope="row">
                <Box textAlign="left">
                  {task.name.replace("~", "")}
                </Box>
            </TableCell>
            <TableCell align="left">
                <Box textAlign="left">
                  {description_newline ? (
                    task.description?.split("\n").map((task, key) => {
                      return (
                        <span key={key}>
                          {task}
                          <br />
                        </span>
                      );
                    })
                  ) : (
                    <div>{task.description}</div>
                  )}
                </Box>
            </TableCell>
            <TableCell align="left">
                <Box textAlign="left">
                  {requirement_newline ? (
                    task.requirement?.split("\n").map((task, key) => {
                      return (
                        <span key={key}>
                          {task}
                          <br />
                        </span>
                      );
                    })
                  ) : (
                    <div>{task.requirement}</div>
                  )}
                </Box>
            </TableCell>
            <TableCell align="center">
              <IconButton
              onClick={() => {
                setIsTaggingCompliance(true)
                // setSelectedSubsection(task)
                dispatch(getScfControlsByTaskId({taskId: task.id}))
              }}
              >
                <AddLinkIcon/>
              </IconButton>
              <Modal open={isTaggingCompliance} onClose={!isTaggingCompliance}>
                    <TagCompliance onclose={(_onclose) => {setIsTaggingCompliance(_onclose)}} task_id={task.id} />
            </Modal>
            <IconButton 
          onClick={() => {
            setIsEditingSubSection(true)
            setSelectedSubsection(task)
          }}
          >
              <EditNoteOutlinedIcon
                color="white"
                sx={{
                  fontSize: 24,
                  "&:hover": {
                    color: "greenyellow",
                  }
                }}
              />
            </IconButton>
            <Modal open={isEditingSubSection} onClose={!isEditingSubSection}>
              <EditSubsection
               key={task.id} 
               subSection={selectedSubsection} 
               onclose={(_onclose) => {setIsEditingSubSection(_onclose)}} 
               refreshKey={newKey => setRefreshKey(newKey)}
              //  onCloseAlertWithTime={onCloseAlertWithTime}
              />
            </Modal>
              <Tooltip title="Important! Once executed, this action cannot be undone. Deleting this Sub-Section permanently erases associated or related data and completion status. Please consider the consequences before proceeding.">
                <IconButton onClick={() => handleOpen(task.id)}>
                  <DeleteOutlineOutlinedIcon
                    color="white"
                    sx={{
                      fontSize: 20,
                      "&:hover": {
                        color: "red",
                      },
                    }}
                  />
                </IconButton>
              </Tooltip>
            </TableCell>
            <Snackbar
              open={openDeleteSnackbar}
              onClose={handleDeleteSnackbarClose}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: "top", horizontal: "right" }} // move Snackbar to top right
              style={{ marginTop: "100px" }} // moves Snackbar 100px down
            >
              <Alert severity="error" variant="filled">
                <Box
                  sx={{
                    display: "flex", // Use Flexbox for alignment
                    alignItems: "center", // Align items on the center line
                    justifyContent: "center", // Center elements horizontally
                    width: "100%", // Make sure the box fills up the entire width of the Snackbar
                    height: "100%",
                    padding: "0 10px", // Some padding to ensure some space between text and CircularProgress
                  }}
                >
                  <Typography
                    variant="body2"
                    color="inherit"
                    sx={{ marginRight: "5px" }}
                  >
                    Successfully Deleted Sub-Section! Updating Data in{" "}
                  </Typography>
                  <DeleteProgress value={(countdown / 3) * 100} />{" "}
                  {/* Convert the seconds to a percentage of 3 seconds */}
                </Box>
              </Alert>
            </Snackbar>
          </TableRow>
        );
      })}
    </>
  );
};

export default function ManageStandard({ standardId, newSection }) {
  const [isLoading, setIsLoading] = useState(false);
  const [sectionList, setSectionList] = useState([]);
  const [error, setError] = useState(null);
  const [newSubsection, setNewSubsection] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showAlert, setShowAlert] = React.useState({
    message: "",
    isShow: false,
    serverity: "",
  });

  const _client = localStorage.getItem("client");
  const _app = localStorage.getItem("app");

  const onCloseAlertWithTime = () => {
    const interval = setInterval(() => {
      setShowAlert((prevState) => ({ ...prevState, isShow: false })); // Update the state variable here
      clearInterval(interval);
    }, 2000);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `scm/sections/?standard=${standardId}`,
          {
            headers: {
              "Content-Language": "en-US",
            },
          }
        );
        setSectionList(response.data);
        setError(false);
      } catch (error) {
        setError(true);
        alert("Reload. Error loading data.");
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [newSubsection, newSection, refreshKey]);

  //
  const isFullScreen = useMediaQuery("(min-width: 1380px)");
  const boxWidth = isFullScreen ? "30%" : "50%"; // THIS IS USED TO RESIZE THE MODAL
  const boxHeight = isFullScreen ? "30%" : "50%";
  return (
    <RefreshContext.Provider value={setRefreshKey}>
      {isLoading ? (
        <Grid
          container
          flexDirection="row"
          justifyContent="center"
          position="absolute"
        >
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              pt: 25,
            }}
          >
            <Typography pb={2}>Loading ...</Typography>
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        <TableContainer
          component={Paper}
          style={{
            maxHeight: "95%",
            overflow: "auto",
            backgroundColor: colors.black[600],
          }}
        >
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  Sections
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  Description
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", fontSize: "16px" }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sectionList.map((row) => (
                <Row
                  key={row.id}
                  row={row}
                  section_name={row.name}
                  newSubsection={(newSubsection) => {
                    setNewSubsection(newSubsection);
                    setShowAlert({
                      message: "Subsection created successfully!",
                      isShow: newSubsection,
                      serverity: "",
                    });
                    onCloseAlertWithTime();
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box sx={{ display: "flex", justifyContent: "right" }}>
        <Box
          sx={{
            position: "absolute",
            top: 860,
            right: 1240,
            width: "30%",
            paddingRight: "3vh",
          }}
        >
          {showAlert.isShow && (
            <CustomAlert
              severity="success"
              onClose={() => {
                setShowAlert({ isShow: false });
              }}
              open={showAlert.isShow}
              successMessage={showAlert.message}
            />
          )}
        </Box>
      </Box>
    </RefreshContext.Provider>
  );
}
