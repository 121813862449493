import React, { useState, useEffect } from 'react';
import { Button, Box, Modal, Typography, Snackbar, Alert, CircularProgress, Stack, IconButton, Divider, ListItemSecondaryAction } from '@mui/material';
import AWS from 'aws-sdk';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { uploadTaskEvidence, fetchExistingUploadedFile, fetchExistingUploadedFileList, updateTaskEvidence, uploadExistingFile, removeTaskEvidence } from '../../api/api';
import PostAddIcon from '@mui/icons-material/PostAdd';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AttachmentOutlined from '@mui/icons-material/AttachmentOutlined';
import Checkbox from '@mui/material/Checkbox';
import ViewFile from './ViewFile';
import { makeStyles } from '@mui/styles';
import { CloseOutlined } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const subdirectory = process.env.REACT_APP_S3;
const useStyles = makeStyles(theme => ({
  buttonContainer: {
    height: '12vh',
    width: '12vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: 'GrayText',
    },
  },
  uploadIcon: {
    color: theme.palette.primary.main,
    fontSize: '4rem',
  },
}));

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_REGION,
});

function Evidence({ task, assignee, task_requirement }) {
  const s3 = new AWS.S3();
  const [openFiles, setOpenFiles] = useState(false);
  const [file, setFile] = useState(null);
  const [_file, _setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [taskEvidenceId, setTaskEvidenceId] = useState(); // Add state for task evidence ID
  const [openUploadSnackbar, setOpenUploadSnackbar] = useState(false); // State for Upload Snackbar
  const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState(false); // State for Delete Snackbar
  const [isLoading, setIsLoading] = useState(false);
  const _user = localStorage.getItem('user');
  const _isAssignee = localStorage.getItem('isAssignee');
  // Function to handle Snackbar open
  const handleUploadSnackbarOpen = () => {
    setOpenUploadSnackbar(true);
  };

  const handleDeleteSnackbarOpen = () => {
    setOpenDeleteSnackbar(true);
  };

  // Function to handle Snackbar close
  const handleUploadSnackbarClose = () => {
    setOpenUploadSnackbar(false);
  };

  const handleDeleteSnackbarClose = () => {
    setOpenDeleteSnackbar(false);
  };

  const handleOpen = async () => {
    setOpen(true);
  
    try {
      const response = await fetchExistingUploadedFile(task);
      if (response.file) {
        setTaskEvidenceId(response.file.id); // Set the task evidence ID
        setFile(response.file);
        _setFile(response.file.imageUrl);
        setPreviewUrl(response.file.previewUrl);
        setImageUrl(response.file.imageUrl);
        setIsUploaded(true);
      }
    } catch (error) {
    }
  };

  const handleOpenFile = () => {
    setOpenFile(true);
  }
  
  const handleClose = () => {
    setOpen(false);
    setPreviewUrl(null);
    setFile(null);
    setTaskEvidenceId(null); // Reset task evidence ID
  };

  const handleCloseFiles = () => {
    setOpenFiles(false);
    // setPreviewUrl(null);
    // setFile(null);
    // setTaskEvidenceId(null); 
  };


  // const handleFileSelect = (e) => {
  //   if (e.target.files.length > 0) {
  //     setFile(e.target.files[0]);
  //     const reader = new FileReader();
  //     reader.onload = () => setPreviewUrl(reader.result);
  //     reader.readAsDataURL(e.target.files[0]);
  //   }
  // };



  // const deleteUploadedFile = async () => {
  //   const params = {
  //     Bucket: process.env.REACT_APP_BUCKET,
  //     Key: `${subdirectory}/${file.name}`,
  //   };
  //   try {
  //     await s3.deleteObject(params).promise();
  //     await deleteTaskEvidence(taskEvidenceId); // Use the correct taskEvidenceId here
  //     setFile(null);
  //     setImageUrl(null);
  //     setIsUploaded(false);
  //     handleDeleteSnackbarOpen(); // Open Snackbar after successful delete
  //     setTimeout(() => { handleDeleteSnackbarClose(); handleClose(); }, 2000); // Close modal after 2 seconds
  //   } catch (error) {
  //   }
  // };

  useEffect(() => {
    if (taskEvidenceId === null && file !== null) {
      setTaskEvidenceId(file.id); // Set the task evidence ID from the file object
    }
  }, [file, taskEvidenceId]);

  // ========================================= F I L E L I S T U P L O A D ========================================================

  return (
    <>
      <Stack direction="row" spacing={2} justifyContent='center'>
      <Button variant="contained" color='success' onClick={() => setOpenFiles(true)} sx={{ display: 'relative', transition: 'transform 0.2s ease-in-out',
                      '&:hover': {
                        transform: 'scale(1.1) translateY(-1px)'} 
                        }}
              disabled={ (_isAssignee === 'true' && Number(_user) !== assignee?.id)}
                        >
      <Stack display='flex' direction='row'>
      Attachments <AttachmentOutlined style={{marginLeft: "3px", alignSelf: 'center'}}/>
      </Stack>
    </Button> 
      </Stack>
      <Dialog open={openFiles} onClose={handleCloseFiles}
      sx={{
       
        width: "100", // adjust the width as needed
        height: "100%", // adjust the height as needed
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
      }}
      >
        <DialogContent sx={{minWidth:'30vw', padding: 2}}>
          <Box margin={-2} display="flex" justifyContent="space-between">
            <div>{' '}</div>
             <IconButton onClick={handleCloseFiles}>
               <CloseOutlined/>
              </IconButton>
          
          </Box>
          <Box display="flex" justifyContent="center" width='100%' padding={2}>
            <ShowFiles task={task} task_requirement={task_requirement}/>
            </Box>
        </DialogContent>
          
      </Dialog>
    </>
  );
}



const FileList = ({taskId, onAttachFile, onCloseFileList, files}) => {
  const [fileList, setFileList] = useState([]);
  const [checked, setChecked] = React.useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const _app = localStorage.getItem('app');
  const _client = localStorage.getItem('client');
  const [isLoading, setIsLoading] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [openUploadSnackbar, setOpenUploadSnackbar] = useState(false); // State for Upload Snackbar
  const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState(false); // State for Delete Snackbar

  // Function to handle Snackbar open
  const handleUploadSnackbarOpen = () => {
    setOpenUploadSnackbar(true);
  };

  // Function to handle Snackbar close
  const handleUploadSnackbarClose = () => {
    setOpenUploadSnackbar(false);
    onCloseFileList();
  };

  const handleOpenViewFile = () => {
    setOpenFile(true);
    document.addEventListener('contextmenu', event => event.preventDefault())
  };

  const handleCloseViewFile = () => {
    setOpenFile(false);
    document.removeEventListener('contextmenu', event => event.preventDefault())
  };


const handleToggle = (index) => () => {
  const currentIndex = checked.indexOf(index);
  const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(index);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
   
};

  // create a boolean function that will check if the specific file is already in fileList
  const isFileInList = (file) => {
    if(!files) return false;
    return files.some(item => item.name === file.name);
  }
 
  
  useEffect(() => {
    const fetchFileList = async () => {
      try {
        const list = await fetchExistingUploadedFileList(_client);
  
        const uniqueNames = Array.from(new Set(list.map(file => file.name))); // Filter unique names
  
        // filter the list data by unique names
        const uniqueList = uniqueNames.map(name => {
          return list.find(file => file.name === name)
        })

        setFileList(uniqueList);

      } catch (error) {
        // Handle error
      }
    };
    fetchFileList();
}, []);

  const uploadSelectedFiles = async () => {
    try {
      setIsLoading(true);
      for (const file of checked) {
        await uploadTaskEvidence(taskId, fileList[file].previewUrl, _client);
      }
      onAttachFile(); // Refresh the file list
      handleUploadSnackbarOpen(); // Open Snackbar after successful upload
      setTimeout(() => { handleUploadSnackbarClose(); }, 2000); // Close modal after 2 seconds
      // Optional: Clear the checked state after successful upload
      setChecked([]);
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  

  return (
   <div>
    {fileList.length === 0 ? 
    <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>No files uploaded yet</Typography> : 
     <List dense={true} sx={{
      display: 'flex',
      flexDirection: 'column',
      bgcolor: 'background.paper', width: '100%'}}>
      {fileList.map((value, index) => (
        <ListItem key={value.id} sx={{ height: '4vh'}}
        secondaryAction=
        {
          <Stack direction='row' spacing={1}>
              <IconButton edge="end" aria-label="preview" onClick={() => {
                handleOpenViewFile();
                setSelectedFile(value);
              }} style={{ marginLeft: 2}}>
          <VisibilityOutlinedIcon color='info'/>
          </IconButton>
        <Checkbox
          disabled={isFileInList(value)}
          edge="end"
          onChange={handleToggle(index)} // Pass the index instead of value
          checked={checked.indexOf(index) !== -1} // Use index instead of value
          inputProps={{ 'aria-labelledby': value.id }}
        />
          <Modal open={openFile} onClose={() => setOpenFile(false)}>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              width: 'auto',
              padding: '1rem',
              paddingRight: '2rem',
              paddingLeft: '2rem',
            }}>
              <ViewFile files={selectedFile} onclose={handleCloseViewFile}/>
            </Box>
          </Modal>
          </Stack>
        }>
           <ListItemIcon sx={{ height: '24px'}}>
              <FileIcon
                extension={value.name.split('.').pop()}
                {...defaultStyles[value.name.split('.').pop()]}/>
            </ListItemIcon>
            <ListItemText id={value.id} primary={value.name} sx={{paddingRight: 10}}/>
        </ListItem>
      ))}
    </List>
    }
    <Stack padding={2}>
      {checked.length > 0 && (
        <Button variant='contained' onClick={uploadSelectedFiles} disabled={isLoading}>
        {isLoading ? <CircularProgress size={24}/> : 'Select file'}
      </Button>
      )}
    </Stack>
    
    {/* ==============================SNACKBAR=================================== */}
    {/* <Box position='absolute' justifyContent='center'>
        
        <Snackbar open={openDeleteSnackbar} onClose={handleDeleteSnackbarClose}>
          <Alert severity="warning" variant="filled" sx={{ position: 'relative', heigth: '10vh', width: '30vh', top: -370, left: 15, bottom: 0, right: 15 }}>
            File Deleted Successfully!
          </Alert>
        </Snackbar>
    </Box> */}
     <Snackbar open={openUploadSnackbar} onClose={handleUploadSnackbarClose}>
          <Alert severity="success" variant="filled" sx={{ position: 'relative', heigth: '10vh', width: '100%', top: 0, left: 0, bottom: 0, right: 0 }}>
            File Uploaded Successfully!
          </Alert>
        </Snackbar>
   </div>
  );
};




 // ========================================= ################################## ========================================================


 

const ShowFiles =  ({task, task_requirement}) => {

  const [trigger, setTrigger] = useState(false);
  const [files, setFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openFiles, setOpenFiles] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [openRemoveSnackbar, setOpenRemoveSnackbar] = useState(false); // State for Delete Snackbar

  const classes = useStyles();

  useEffect(() => {
    const fetchAttachments = async () => {
      try {
        const response = await fetchExistingUploadedFile(task);
        setFiles(response);
        setIsLoading(true);
      
      } catch (error) {
        setFiles([]);
      } finally {
        setIsLoading(false);
      }
  }
  fetchAttachments();
  }, [trigger]);

  const handleOpenViewFile = () => {
    setOpenFiles(true);
    document.addEventListener('contextmenu', event => event.preventDefault())
  };

  const handleCloseViewFile = () => {
    setOpenFiles(false);
    document.removeEventListener('contextmenu', event => event.preventDefault())
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleRemoveFileSnackbarOpen = () => {
    setOpenRemoveSnackbar(true);
  };

  // Function to handle Snackbar close=
  const handleRemoveFileSnackbarClose = () => {
    setOpenRemoveSnackbar(false);
    setOpenDialog(false);
  };


  const handleRemove = async () => {
    try {
      await removeTaskEvidence(selectedFileId);
      handleRemoveFileSnackbarOpen();
      setTimeout(() => { handleRemoveFileSnackbarClose(); }, 1000);
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
      // filter the file from the list except for the deleted file
      setFiles(files.filter((file) => file.id !== selectedFileId));
    }
  }


  return (
    <>
    {files.length > 0 ?
    <Stack display='flex' flexDirection='column' justifyContent='center' minWidth='31vw' padding={2}>
      <Stack display='flex' flexDirection='row' justifyContent='space-between' paddingBottom={2}>
        
      <Typography variant="h3" alignSelf='flex-end' >
          Attached file/s
          </Typography> 
      <Button size='small' variant='contained' startIcon={<PostAddIcon/>}
      sx={{ alignSelf: 'flex-end' }}
            onClick={() => {
              handleOpen();

            }} >
            Add Attachment
      </Button>
      </Stack>
      <Divider variant='fullWidth' sx={{ border: '1.5px solid white'}}/>
      <Dialog key={`file - ${task}`} open={open} onClose={handleClose}>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            
          <Box display="flex" justifyContent="space-between">
           <Typography variant="h3" sx={{ marginRight: '2rem' }}>
            Select from uploaded files
            </Typography> 
            <Box position='relative' sx={{ top: -14, left: 15, bottom: 0, right: 0 }}>
              <IconButton onClick={handleClose}>
                <CloseOutlined/>
              </IconButton>
            </Box>
          </Box>
          <Box>
          <FileList taskId={task} 
          onAttachFile={() => setTrigger(!trigger)}
          onCloseFileList={() => handleClose()}
          files={files}
          />
          </Box>
        
          </DialogContent>
      </Dialog>
       
      < List dense>
      {files.map((value) => (
        <>
          <ListItem key={value.id} sx={{ height: '4vh'}} disablePadding>
          <ListItemIcon sx={{ height: '24px'}}>
          <FileIcon
          extension={value.name.split('.').pop()}
          {...defaultStyles[value.name.split('.').pop()]}/>
          </ListItemIcon>
          <ListItemText
            primary={`${value.name}`}
            sx={{paddingRight: 16}}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="remove"
            onClick={() => {
              handleOpenDialog()
              setSelectedFileName(value.name)
              setSelectedFileId(value.id)
            }}>
              <CancelIcon color='error'/>
            </IconButton>
          </ListItemSecondaryAction>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Remove attachment?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove 
                  <Typography variant='body1' fontWeight='bold' color='info' display='inline'>
                    {` "${selectedFileName}" `}
                  </Typography>
                 ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button size='small' variant='outlined' onClick={handleCloseDialog}>
                Cancel
                </Button>
              <Button size='small' variant='contained' onClick={handleRemove}>
                Remove
              </Button>
            </DialogActions>
            <Snackbar open={openRemoveSnackbar} onClose={handleRemoveFileSnackbarClose}>
          <Alert severity="success" variant="filled" sx={{ position: 'relative', heigth: '10vh', width: '55vh', top: 0, left: 0, bottom: 0, right: 0 }}>
            Attachment Removed Successfully!
          </Alert>
        </Snackbar>
          </Dialog>
        </ListItem>
        <Divider style={{ width: '100%'}}/>
        </>
      ))}
    </List>
        
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem', paddingTop: 2}}>
      {files.length !== 0 && (
        <Button variant='outlined' onClick={handleOpenViewFile} style={{ width: '40%' }}>
        View Files
        </Button>
      )}
      </Box>
      <Modal open={openFiles} onClose={() => setOpenFiles(false)}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          width: 'auto',
          padding: '1rem',
          paddingRight: '2rem',
          paddingLeft: '2rem',
        }}>
          {/* <Stack display='flex' direction='row' justifyContent='space-between'>
            <div></div>
            <IconButton>
              <CloseOutlined/>
            </IconButton>
          </Stack> */}
          <ViewFile files={files} onclose={handleCloseViewFile} task_requirement={task_requirement}/>
        </Box>
       </Modal>
       </Stack>
    :  
          <Stack display='flex' flexDirection='column' justifyContent='center' alignItems='center' paddingBottom={4}>
          <Typography variant="h2" paddingBottom={2}>
          File Attachment
          </Typography>
          <Box className={classes.buttonContainer} display='flex' flexDirection='column'
            onClick={handleOpen}>
            <PostAddIcon className={classes.uploadIcon}/>
          <Typography variant="h6" color="textSecondary">
              Add File
            </Typography>
        </Box>
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <Box display="flex" justifyContent="space-between">
           <Typography variant="h3" sx={{ marginRight: '2rem' }}>
            Select from uploaded files
            </Typography> 
            <Box position='relative' sx={{ top: -14, left: 15, bottom: 0, right: 0 }}>
              <IconButton onClick={handleClose}>
                <CloseOutlined/>
              </IconButton>
            </Box>
          </Box>
          <Box>
          <FileList taskId={task} onAttachFile={() => setTrigger(!trigger)}
          onCloseFileList={() => handleClose()}
          />
          </Box>
          </DialogContent>
          
        
      </Dialog>
          </Stack>
          }
     
    </>
  )
};

const ChangeFile = ({taskId}) => {
  const [fileList, setFileList] = useState([]);
  const [checked, setChecked] = React.useState([]);
  const _app = localStorage.getItem('app');
  const _client = localStorage.getItem('client');
  const [isLoading, setIsLoading] = useState(false);
  const [openUploadSnackbar, setOpenUploadSnackbar] = useState(false); // State for Upload Snackbar
  const [openDeleteSnackbar, setOpenDeleteSnackbar] = useState(false); // State for Delete Snackbar

  // Function to handle Snackbar open
  const handleUploadSnackbarOpen = () => {
    setOpenUploadSnackbar(true);
  };

  // Function to handle Snackbar close
  const handleUploadSnackbarClose = () => {
    setOpenUploadSnackbar(false);
  };

  const handleDeleteSnackbarClose = () => {
    setOpenDeleteSnackbar(false);
  };

  const handleToggle = (index) => () => {
    const newChecked = [index]; // Start with the current checkbox as the only checked item
    setChecked(newChecked);
  };

  useEffect(() => {
    const fetchFileList = async () => {
      try {
        const list = await fetchExistingUploadedFileList(_client);
  
        const uniqueNames = Array.from(new Set(list.map(file => file.name))); // Filter unique names
  
        // filter the list data by unique names
        const uniqueList = uniqueNames.map(name => {
          return list.find(file => file.name === name)
        })

        setFileList(uniqueList);

      } catch (error) {
        // Handle error
      }
    };
    fetchFileList();
}, []);

  const updateSelectedFiles = async () => {
    try {
      setIsLoading(true);
      // for (const file of checked) {
      //   await uploadTaskEvidence(taskId, fileList[file].previewUrl, _app, _client);
      // }
      await updateTaskEvidence(taskId, fileList[checked[0]].previewUrl);
      handleUploadSnackbarOpen(); // Open Snackbar after successful upload
      setTimeout(() => { handleUploadSnackbarClose(); }, 2000); // Close modal after 2 seconds
      // Optional: Clear the checked state after successful upload
      setChecked([]);
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
    }
  };

  return (
   <div>
     <List dense={true} sx={{
      display: 'flex',
      flexDirection: 'column',
      bgcolor: 'background.paper' }}>
      {fileList.map((value, index) => (
        <ListItem key={value.id} sx={{ height: '4vh', padding: 0}}
        secondaryAction=
        {<Checkbox
          edge="end"
          onChange={handleToggle(index)} // Pass the index instead of value
          checked={checked.indexOf(index) !== -1} // Use index instead of value
          inputProps={{ 'aria-labelledby': value.id }}
        />
        }>
           <ListItemIcon sx={{ height: '24px'}}>
              <FileIcon
                extension={value.name.split('.').pop()}
                {...defaultStyles[value.name.split('.').pop()]}/>
            </ListItemIcon>
            <ListItemText id={value.id} primary={value.name} sx={{paddingRight: 10}}/>
        </ListItem>
      ))}
    </List>
    <Stack padding={2}>
      {checked.length > 0 && (
        <Button variant='contained' 
        onClick={updateSelectedFiles} 
        disabled={isLoading}>
        {isLoading ? <CircularProgress size={24}/> : 'Select file'}
      </Button>
      )}
    
    </Stack>
    {/* ==============================SNACKBAR=================================== */}
    <Box position='absolute' justifyContent='center'>
        <Snackbar open={openUploadSnackbar} onClose={handleUploadSnackbarClose}>
          <Alert severity="success" variant="filled" sx={{ position: 'relative', heigth: '10vh', width: '55vh', top: 0, left: 0, bottom: 0, right: 0 }}>
            File Uploaded Successfully!
          </Alert>
        </Snackbar>
        <Snackbar open={openDeleteSnackbar} onClose={handleDeleteSnackbarClose}>
          <Alert severity="warning" variant="filled" sx={{ position: 'relative', heigth: '10vh', width: '30vh', top: -370, left: 15, bottom: 0, right: 15 }}>
            File Deleted Successfully!
          </Alert>
        </Snackbar>
    </Box>
   </div>
  );
};


export default Evidence;