import { useState, useEffect } from "react";
import {
  Box,
  ClickAwayListener,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  Popper,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { ExitToApp, PersonOutlined } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from "axios";

const TopBar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedTab, setSelectedTab] = useState("Dashboard");
  const [anchorEl, setAnchorEl] = useState(null);
  const handleLogoClick = () => {
    window.open("https://cytechint.com/", "_blank");
  };
  const stakeholder_info = localStorage.getItem("stakeholder_info");

  const [clients, setClients] = useState([]);
  const [client, setClient] = useState('');
  const token = localStorage.getItem("token");
  const [inputValue, setInputValue] = useState('');
  const location = useLocation();

  // const handleChange = (event) => {
  //   const _client = event.target.value;
  //   setClient(_client);
  //   localStorage.setItem('client', _client.client_no)

  //   // if (location.pathname === "/compliance_manage") {
  //   //   // window.location.reload();
  //   //   return
  //   // }
  //   navigate("/dashboard");
  // };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTabClickAway = () => {
    setSelectedTab(null);
  };
  const handleLogout = () => {
    // remove token from localStorage
    localStorage.removeItem("token");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("email");
    localStorage.removeItem("client");
    localStorage.removeItem("app");
    localStorage.removeItem("user");
    localStorage.removeItem("isAssignee");
    localStorage.removeItem("role");
    localStorage.removeItem("stakeholder_info");
    // dispatch logout action
    dispatch({ type: "LOGOUT" });
    // navigate to login page
    navigate("/login");
  };

  const fetchClients = async () => {
    try {

      const response = await axios.get(`${process.env.REACT_APP_API_URL_LOGIN}scm/clients/`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response
    } catch (error) {
      handleLogout()
    }
  }



  useEffect(() => {
    fetchClients()
      .then((res) => {
        setClients(res.data);
        const objectIndex = res.data?.findIndex(obj => obj.client_no === Number(localStorage.getItem('client')));
        if(objectIndex) setClient(res.data[objectIndex])
      })
  }, [])


  const _roles = JSON.parse(localStorage.getItem('role'));
  const _isCytechAdmin = _roles.includes(`${process.env.REACT_APP_ADMIN_ROLE}`);
  const _isAdminClient = _roles.includes(`${process.env.REACT_APP_CLIENT_ROLE}`);
  const _isCyTechUser = _roles.includes(`${process.env.REACT_APP_USER_ROLE}`);



  //========================== User Menu ===============================//
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);

  const handleUserMenuClick = (event) => {
    if (!userMenuAnchorEl)
      setUserMenuAnchorEl(event.currentTarget);
    else
      (setUserMenuAnchorEl(null))
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
    setAnchorEl(null);
  };
  //====================================================================

  return (
    <Box
      sx={{
        position: "relative",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        display: "flex",
        alignItems: "center",
        background: colors.black[900],
        boxShadow: "0px 0px 2px rgba(100, 150, 150)",
        height: '6vh'
      }}
    >
      <Box >
        <Tooltip
          title="Dashboard"
          placement="top"
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          arrow
        >
          <Box>
            {/* -------------- SEQUOIA NAME LOGO -------------- */}
            <Link style={{ marginLeft: 1 }} to="/dashboard">
              <img
                src={require("../../seq.png")}
                alt="user-icon"
                style={{
                  maxHeight: "30px",
                  objectFit: "cover",
                  marginLeft: ".5vw",
                  marginBottom: '1vh'
                }}
              />
            </Link>
            {/* -------------- SEQUOIA LOGO -------------- */}
            <Link style={{ marginLeft: 1, marginTop: 3 }} to="/dashboard">
              <img
                src={require("../../image.png")}
                alt="tab-icon"
                style={{
                  maxHeight: "60px",
                  objectFit: "fill",
                  marginLeft: "25px",
                  marginRight: "25px",
                  marginTop: '5px'
                }}
              />
            </Link>
          </Box>
        </Tooltip>
      </Box>


      <ClickAwayListener onClickAway={handleTabClickAway}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          textColor="#DCD7C9"
          indicatorColor="secondary"
          sx={{
            flexGrow: 1,
            // marginLeft: "0px",
          }}
        >
          {_isCytechAdmin ?
            (
              <>
                <Tab
                  label="Manage Compliance"
                  value="Manage Compliance"
                  icon={<FactCheckIcon sx={{ fontSize: 25 }} />}
                  component={Link}
                  to="/compliance_manage"
                  sx={{
                    fontSize: "14px",
                    minWidth: "120px",
                    textTransform: "capitalize",
                    "&:hover": {
                      // backgroundColor: colors.grey[800],
                      color: 'skyblue'
                    },
                  }}
                  centered
                />
                <Tab
                  label="Compliance Information"
                  value="Compliance Information"
                  icon={<PlaylistAddCircleIcon sx={{ fontSize: 25 }} />}
                  component={Link}
                  to="/compliance_info"
                  sx={{
                    fontSize: "14px",
                    minWidth: "120px",
                    textTransform: "capitalize",
                    "&:hover": {
                      // backgroundColor: colors.grey[800],
                      color: 'skyblue'
                    },
                  }}
                  centered
                />
              </>
            ) : _isAdminClient ? (
              <>
                <Tab
                  label="Manage Compliance"
                  value="Manage Compliance"
                  icon={<FactCheckIcon sx={{ fontSize: 25 }} />}
                  component={Link}
                  to="/compliance_manage"
                  sx={{
                    fontSize: "14px",
                    minWidth: "120px",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: colors.grey[800],
                    },
                  }}
                  centered
                />
                <Tab
                  label="Compliance Information"
                  value="Compliance Information"
                  icon={<PlaylistAddCircleIcon sx={{ fontSize: 25 }} />}
                  component={Link}
                  to="/compliance_info"
                  sx={{
                    fontSize: "14px",
                    minWidth: "120px",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: colors.grey[800],
                    },
                  }}
                  centered
                /></>
            ) : (
              <>
                <Tab
                  label="Manage Task"
                  value="Compliance Information"
                  icon={<PlaylistAddCircleIcon sx={{ fontSize: 25 }} />}
                  component={Link}
                  to="/task_assignee"
                  sx={{
                    fontSize: "14px",
                    minWidth: "120px",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: colors.grey[800],
                    },
                  }}
                  centered
                />
              </>
            )}
          <div
            style={{
              position: "absolute",
              top: "38%",
              left: "42%",
              transform: "translate(-50%, -50%)",
              zIndex: -1
            }}
          >

            <Box>
              <img
                src={require("../../bg.png")}
                alt="user-icon"
                style={{
                  maxHeight: "45px",
                  marginTop: "25px",
                  cursor: "pointer",
                }}
                onClick={handleLogoClick}
              />
            </Box>
          </div>

          {/* ============================ Client Dropdown ============================ */}
          {(_isCytechAdmin || _isCyTechUser) && (
            <Box sx={{ width: 220, mt: "18px", mb: "12px", ml: "12px",
            ".css-l3ln04-MuiAutocomplete-root .MuiOutlinedInput-root": {
              padding: "4px"
            }
          }}>
            <Autocomplete
              value={client}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                const selectedClient = clients.find((client) => client.org_name === newInputValue);
                if (selectedClient) {
                  setClient(selectedClient);
                  localStorage.setItem('client', selectedClient.client_no)
                  navigate("/dashboard");
                }
              }}
              id="controllable-states-demo"
              options={clients}
              getOptionLabel={(option) => option.org_name === undefined ? '' : option.org_name}
              
              renderInput={(params) => <TextField {...params} label="Client"/>}
            />
          </Box>
          )}

          {/* ============================ //Client Dropdown ============================ */}

          <Tooltip title="User Menu"
            placement="top"
            arrow
            enterDelay={500}
            leaveDelay={200}
          >
            <IconButton onClick={handleUserMenuClick} sx={{ ml: 'auto', mt: '1.3vh', width: '50px', height: '50px', borderRadius: '25px' }}>
              <AccountCircleIcon fontSize="large" />
            </IconButton>
          </Tooltip>

          <ClickAwayListener onClickAway={handleClose}>
            <Menu
              anchorEl={userMenuAnchorEl}
              open={Boolean(userMenuAnchorEl)}
              onClose={handleUserMenuClose}
              getContentAnchorEl={null}
              PaperProps={{
                style: {
                  width: "18ch",
                  background: 'paper',
                  marginTop: 3,
                  marginLeft: 12,
                  border: 1
                },
                sx: {
                  borderRadius: "5px 5px 5px 5px",

                }
              }}
            >
              {_isCytechAdmin &&
                (
                  <>
                    <MenuItem
                      onClick={handleUserMenuClose}
                      label="Admin Manage Data"
                      value="Admin Manage Data"
                      icon={<AppRegistrationIcon sx={{ fontSize: 25 }} />}
                      component={Link}
                      to="/admin_manage_data"
                      sx={{
                        color: colors.primary[100],

                      }}><AppRegistrationIcon />&nbsp; Manage Data</MenuItem>
                    {/* <MenuItem
                    onClick={handleUserMenuClose}
                    label="Admin Manage Data"
                    value="Admin Manage Data"
                    icon={<AppRegistrationIcon sx={{ fontSize: 25 }} />}
                    component={Link}
                    to="/admin_manage_data"
                    sx={{
                      color: colors.primary[100],
                      
                    }}><AppRegistrationIcon />&nbsp; Manage Data</MenuItem> */}
                  </>


                )}
              <MenuItem sx={{ color: colors.primary[100], justifyContent: 'left' }} onClick={handleLogout}><ExitToApp />&nbsp; Logout</MenuItem>
            </Menu>

          </ClickAwayListener>
          {/* </Popper> */}
        </Tabs>
      </ClickAwayListener>
    </Box>
  );
};

export default TopBar;
