import assigneeSlice from "./slices/assignee/assigneeSlice";
import standardSlice from "./slices/standards/standardSlice";
import taskProgressSlice from "./slices/taskprogress/taskProgressSlice";
import scfControlsSlice from "./slices/scf-controls/scf-controlsSlice";
import userSlice from "./slices/userSlice";

export const rootReducer = {
  standard: standardSlice,
  assignees: assigneeSlice,
  user: userSlice,
  taskprogress: taskProgressSlice,
  scfControls: scfControlsSlice
};
