import { loginUser } from "../../feature/slices/userSlice";
import api from "../api";

const login = async (dispatch, data) => {
  let res = await api.post(
    "login/",
    {
      email: data.email,
      password: data.password,
    },
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );

  // dispatch(loginUser(res.data));
  return res;
};

export { login };
