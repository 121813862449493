import { Box, Divider, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import ApexTimeline from "../../components/Graphs/ApexTimeline";
import ApexBar from "../../components/Graphs/ApexBar";
import ApexCustomAngleCircle from "../../components/Graphs/ApexPie";
import ActivityLogsDataGrid from "../../components/ActivityLogsDataGrid"
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import TestGraph from "../../components/Graphs/TestGraph";
import { getScfControls } from "../../feature/slices/scf-controls/scf-controlsSlice";


const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const stakeholder_info = localStorage.getItem('stakeholder_info')
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleError = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("email");
    localStorage.removeItem("client");
    localStorage.removeItem("app");
    localStorage.removeItem("user");
    localStorage.removeItem("isAssignee");
    localStorage.removeItem("role");
    localStorage.removeItem("stakeholder_info");
    navigate("/login");
  }


    useEffect(() => {
        if(stakeholder_info.length === 0 || stakeholder_info === null){
          handleError();
        }
        dispatch(getScfControls())
    }, [])

  return (
    <Box
      sx={{
        display: "grid",
        flexDirection: "column",
        alignItems: "center",
        ml: ".5vh",
        mr: ".5vh",
        mt: '1vh'
      }}>
      {/* ------------------------------------------------------------- Overall Compliance ----------------------------------------------------------------- */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(10, 1fr)"
        gridAutoRows="140px"
        gap="6px"
      >
        <Box
          gridColumn="span 5"
          gridRow="span 3"
          p='10px'
          backgroundColor={colors.primary[900]}
          borderRadius={1}
          border={0}
        >
          <Typography variant="h5" fontWeight="600" >
            Completion Rate
          </Typography>
          <Divider />
          <ApexCustomAngleCircle/>
          <Box display='flex' justifyContent='center' >
          </Box>
        </Box>
        {/* ------------------------------------------------------------------------------ Accomplihsments -------------------------------------------------------------------------- */}
        <Box
          gridColumn="span 5"
          gridRow="span 3"
          backgroundColor={colors.primary[900]}
          borderRadius={1}
          p="10px"
        >
          <Typography variant="h5" fontWeight="600">
            Timeline
          </Typography>
          <Divider />
          <ApexTimeline />
        </Box>
        {/* ----------------------------------------------------------------------------- Compliance Breakdown ------------------------------------------------------------------------- */}
        <Box
          gridColumn="span 5"
          gridRow="span 3"
          backgroundColor={colors.primary[900]}
          borderRadius={1}
          p="10px"
        >
          <Typography variant="h5" fontWeight="600">
            Standard Breakdown
          </Typography>
          <Divider />
          <ApexBar />
        </Box>
        {/* ------------------------------------------------------------------------ TBF --------------------------------------------------------------------------- */}
        <Box
          gridColumn="span 5"
          gridRow="span 3"
          backgroundColor={colors.primary[900]}
          borderRadius={1}
          p="10px"
        >
          <Typography variant="h5" fontWeight="600">
            Activity Logs
          </Typography>
          <Divider />
          <ActivityLogsDataGrid />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;

