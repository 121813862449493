import React from 'react'
import Header from '../../components/Header'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import StandardTable from './StandardTable';
import ComplianceTable from './ComplianceTable.jsx';


function AccessibleTabs() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
      <Box sx={{ display:'flex', paddingTop: 2, flexDirection:'column'}}>
        <Tabs
          onChange={handleChange}
          value={value}
          aria-label="Tabs where selection follows focus"
          selectionFollowsFocus
        >
          <Tab label="Manage Compliance" />
          <Tab label="Manage Controls" />
        </Tabs>
        {value === 0 && <StandardTable />} {/* Render StandardTable component when value is 0 */}
        {value === 1 && <ComplianceTable />} {/* Render ComplianceTable component when value is 1 */}
      </Box>
    );
  }

function AdminManage() {
  return (
    <>
    <Header
        title="Admin Manage Data"
        subtitle="Manage Existing Standards" // <----------------- Always Changing
    />
     <AccessibleTabs/>
    </>
  )
}

export default AdminManage
