import { useEffect, useRef, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Topbar from "./pages/global/Topbar";
import Dashboard from "./pages/dashboard";
import LoginPage from "./pages/login";
import MngCompliance from "./pages/compliance_manage";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Navigate } from "react-router-dom";
import ComplianceInfo from "./pages/compliance_info";
import AdminManage from "./pages/AdminManageData";
import { useSelector } from "react-redux";
import { Provider } from "react-redux";
import store from "./feature/store";
import TaskAssignee from "./pages/task_assignee";


function App() {
  const [theme, colorMode] = useMode();
  const [showTopBar, setShowTopBar] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { isAssignee: isUserAssignee } = useSelector(state => state.user)

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem("isAuthenticated") === "true";
  });

  const [isAssignee, setIsAssignee] = useState(() => {
    return localStorage.getItem("isAssignee") == "true";
  });

  const handleLogin = () => {
    localStorage.setItem("isAuthenticated", true);
    if (isAssignee) {
      navigate("/task_assignee");
    } else {
      navigate("/dashboard");
    }
  }

  useEffect(() => {
    setIsAssignee(() => {
      if (!isUserAssignee) {
        return localStorage.getItem('isAssignee') == 'true'
      } else {
        return isUserAssignee
      }
    })

  }, [isUserAssignee])

  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated");
    navigate("/");
  };

  const logoutUser = () => {
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("token");
    navigate("/");
  };

  // Auto logout after 60 minutes
  useEffect(() => {
    const sixtyMinutes = 60 * 60 * 1000; // 60 minutes in milliseconds
    const timeoutId = setTimeout(logoutUser, sixtyMinutes);

    // Clear the timer when the component unmounts or when isAuthenticated changes
    return () => clearTimeout(timeoutId);
  }, [isAuthenticated]);

  return (
    <Provider store={store}>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {isAuthenticated ? (
            <div className="app">
              <main className="content">
                {showTopBar && location.pathname !== "/login" && (
                  <Topbar setIsSidebar={setShowTopBar} handleLogout={handleLogout} />
                )}
                <Routes>
                  <Route path="/" element={<Navigate to="/login" />} />
                  <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
                  {isAssignee && (
                    <Route path="/task_assignee" element={<TaskAssignee />} />
                  )}
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="*" element={<div>Page Not Found</div>} />
                  {isAuthenticated && (
                    <>
                      <Route path="/compliance_manage" element={<MngCompliance />} />
                      <Route path="/compliance_info" element={<ComplianceInfo />} />
                      <Route path="/admin_manage_data" element={<AdminManage />} />
                    </>
                  )}
                </Routes>
              </main>
            </div>
          ) : (
            <LoginPage onLogin={handleLogin} />
          )}
        </ThemeProvider>
      </ColorModeContext.Provider>
    </Provider>
  );
}

export default App;