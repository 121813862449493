import { Box, Modal, Typography, Button, Tooltip, Dialog, DialogContent, Chip } from "@mui/material";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import AddStandard from "../../components/AddStandard";
import useAxios from "../../hooks/useAxios";
import axios from "../../api/api";
import api from "../../api/api";
import React from "react";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import IconButton from "@mui/material/IconButton";
import { TextField } from "@mui/material";
// import Search from "../../components/searchBar";
// import CircularProgress from '@mui/material/CircularProgress';
import CustomAlert from "../../components/Alert/SuccessAlert";
import { Stack } from "@mui/system";
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from "@mui/icons-material/Close";

const ComplianceInfo = () => {
  const [showAlert, setShowAlert] = useState({
    message: "",
    isShow: false,
    serverity: "",
  });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedCompliance, setSelectedCompliance] = useState(null);
  const [open, setOpen] = useState(false);
  const [complianceId, setComplianceId] = useState("");
  const token = localStorage.getItem("token");
  const [standard, setStandard] = useState(null);

  const _roles = JSON.parse(localStorage.getItem('role'));
  const _isCytechAdmin = _roles.includes(`${process.env.REACT_APP_ADMIN_ROLE}`);
  const _isAdminClient = _roles.includes(`${process.env.REACT_APP_CLIENT_ROLE}`);

  api.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });


  // const [isCompliance, setIsCompliance] = useState([]); // Corrected
  const [standards, setStandards] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `scm/standards/?app=${process.env.REACT_APP_NAME}`,
          {
            headers: {
              "Content-Language": "en-US",
            },
          }
        );
        // setIsCompliance(response.data);
        setStandards(response.data)
        setError(false);
      } catch (error) {
        setError(true);
        alert("Reload. Error loading data.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleAddToCompliance = (event, params) => {
    event.stopPropagation(); // prevent row click event from being triggered
    const index = standards.findIndex((d) => d.id === params.id);
    const _compliance = standards[index];
    setSelectedCompliance(_compliance);
    setComplianceId(params.id);
    setStandard(params.row);
    setOpen(true);
  };

  const onCloseAlertWithTime = () => {
    const interval = setInterval(() => {
      setShowAlert((prevState) => ({ ...prevState, isShow: false }));
      clearInterval(interval);
    }, 5000);
  };

  const [searchStandard, setSearchStandard] = useState('');
  const [searchSCFControl, setSearchSCFControl] = useState('');

  const handleSearchSCFControl = (event) => {
    setSearchSCFControl(event.target.value);
  };

  const handleSearchStandard = (event) => {
    setSearchStandard(event.target.value);
  };
  const filteredData = standards
    .filter((row) => {
      const searchWords = searchStandard.toLowerCase().split(" ");
      return searchWords.every((word) =>
        row.name.toLowerCase().includes(word)
      );
    })
    .sort((a, b) => {
      /* 
      NOTE:
      Prioritize standards when searchStandard is provided and searchSCFControl is not
      1. Sort alphabetically if both have the same match index
      2. a has a match at the beginning while b doesn't, so a should come first
      3. b has a match at the beginning while a doesn't, so b should come first
      */
      if (searchStandard && !searchSCFControl) {
        const aMatchIndex = a.name.toLowerCase().indexOf(searchStandard.toLowerCase());
        const bMatchIndex = b.name.toLowerCase().indexOf(searchStandard.toLowerCase());
        if (aMatchIndex === bMatchIndex) {
          return a.name.localeCompare(b.name); 
        } else if (aMatchIndex === 0) {
          return -1; 
        } else if (bMatchIndex === 0) {
          return 1; 
        }
        return aMatchIndex - bMatchIndex;
      }
      /* 
      NOTE:
      Prioritize compliance when searchStandard is not provided and searchSCFControl is provided
      1. a has the searchSCFControl, so it should come first.
      2. b has the searchSCFControl, so it should come first.
      */
      else if (!searchStandard && searchSCFControl) {
        if (a.compliances.some(compliance => compliance.includes(searchSCFControl)) && !b.compliances.some(compliance => compliance.includes(searchSCFControl))) {
          return -1; 
        } else if (!a.compliances.some(compliance => compliance.includes(searchSCFControl)) && b.compliances.some(compliance => compliance.includes(searchSCFControl))) {
          return 1; 
        }
      }
      /* 
      NOTE: 
      Prioritize standards and categorize in compliance data when both searchStandard and searchSCFControl are provided.
      1. a has a match, so it should come first.
      2. b has a match, so it should come first.
      3. a has the searchSCFControl, so it should come first.
      4. b has the searchSCFControl, so it should come first.
      */
      else if (searchStandard && searchSCFControl) {
        if (a.name.toLowerCase().includes(searchStandard.toLowerCase()) && !b.name.toLowerCase().includes(searchStandard.toLowerCase())) {
          return -1; 
        } else if (!a.name.toLowerCase().includes(searchStandard.toLowerCase()) && b.name.toLowerCase().includes(searchStandard.toLowerCase())) {
          return 1;
        } else if (a.compliances.some(compliance => compliance.includes(searchSCFControl)) && !b.compliances.some(compliance => compliance.includes(searchSCFControl))) {
          return -1; 
        } else if (!a.compliances.some(compliance => compliance.includes(searchSCFControl)) && b.compliances.some(compliance => compliance.includes(searchSCFControl))) {
          return 1; 
        }
      }

      return a.name.localeCompare(b.name);    // Sort alphabetically by name if no specific prioritization applies
    });
  /* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
  /* ================================================= */
  const CompliancesCell = (params) => {
  const [openModal, setOpenModal] = useState(false);

  const compliances =
    params.value && params.value.length > 0
      ? params.value.map((_compliance) => _compliance.replace(/["[\]',]+/g, "")).join(", ")
      : "";

  const standard_name = params.row.name

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSearchText('');
    setFilteredCompliances(compliances.split(", "))
  };

  const [scfControls, setScfControls] = useState(compliances)

  const [searchText, setSearchText] = useState('');
  const [filteredCompliances, setFilteredCompliances] = useState(compliances.split(", "));

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearchText(event.target.value);
    const filtered = compliances.split(", ").filter(control =>
      control.toLowerCase().includes(searchValue.toLowerCase())
      );
      setFilteredCompliances(filtered);
    
  };


  return (
    <>
      <Typography
        noWrap={true}
        style={{
          fontSize: 'small',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: 'Highlight',
          fontWeight: 'bold'
        }}
      >
        {scfControls}
      </Typography>
        {scfControls.length > 70 && (
          <Tooltip>
          <IconButton
            onClick={()=> {
              handleOpenModal()
            }}
            color="primary"
            sx={{
              '&:hover': {
                color: 'white', // Apply your hover color here
              },
            }}
          >
            <InfoIcon />
          </IconButton>
          </Tooltip>
        )}
      <Modal open={openModal} display={'flex'}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: 800,
            // height: 600,
            minHeight: '30vh',
            maxHeight: '75vh',
            minWidth: '30vh',
            maxWidth: '80vh',
            background: colors.primary[900],
            boxShadow: 24,
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'column',
            p: 4,
          }}
        >
          <IconButton
                aria-label="close"
                variant="contained"
                color="white"
                onClick={handleCloseModal}
                sx={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                }}
              >
                <CloseIcon />
              </IconButton>
          <Box>
            <Typography fontWeight={"bold"} fontSize={"2rem"}>
              {standard_name}
            </Typography>
          </Box>
          <Stack display='flex' direction='row' justifyContent='space-between' alignItems='center'>
          <h3>Related SCF Controls:</h3>
          <TextField
          id="compliance-search"
          value={searchText}
          label="Search Compliance"
          onChange={handleSearchChange}
          type="search"
          variant="standard"
          sx={{ marginBottom: 2, marginLeft: 1, width: '12vw'}}
          />
          </Stack>
          <Stack 
          overflow={"auto"}
          sx={{

            "&::-webkit-scrollbar": {
              width: "10px",
            },

            "&::-webkit-scrollbar-track": {
              backgroundColor: "black",
              borderRadius: "10px",
            },

            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
              borderRadius: "10px",
            },

            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#555",
            },

          }}
          >
            <Box display={"flex"} flexWrap={"wrap"}>
              {filteredCompliances.map((controls, index) => (
                <Chip 
                  key={index} 
                  label={controls} 
                  size={"small"}
                  variant={"outlined"} 
                  style={{ marginRight: "5px", marginBottom: "10px", fontWeight: "bold", fontSize: ".7rem", color: "#11A0DB", backgroundColor: "black"}} />
              ))}
            </Box>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
 /* ====================================================================================================== */
  const columns = [
    {
      field: "name",
      headerName: (
        <span style={{ fontSize: "16px", fontWeight: "bold" }}>Standards</span>
      ),
      flex: 0.12,
      headerAlign: "center",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              whiteSpace: "normal",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              fontWeight={"bold"}
              noWrap={false}
              sx={{ color: "skyblue" }}
            >
              {params.value.replace(/["[\]']+/g, "")}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "description",
      headerName: (
        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
          Description
        </span>
      ),
      flex: 0.3,
      headerAlign: "center",
      align: "left",
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography
            style={{
              height: "3em",
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
            }}
          >
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "compliances",
      headerName: (
        <span style={{ fontSize: "14px", fontWeight: "bold" }}>SCF Controls</span>
      ),
      flex: 0.25,
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => <CompliancesCell{...params}/>,
    },

    {
      field: "add",
      headerName: (
        <span style={{ fontSize: "16px", fontWeight: "bold" }}>Action</span>
      ),
      flex: 0.06,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <Box display="flex">
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            size="small"
            disabled={params.row.sections.length === 0}
            onClick={(event) => handleAddToCompliance(event, params)}
          >
            {params.row.sections.length === 0 ? 'Not yet available' : 'Add Standard'}
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <>
      {_isCytechAdmin || _isAdminClient ?
        <Box m="15">
          <Box>
            <Header
              title="Compliance Information"
              subtitle="Security Management" // <----------------- Always Changing :<<<<<<<<<
            />
          </Box>

          {/*<----------------- Search Section ----------------->*/}
          <Stack direction="row" spacing={2} paddingTop={12} marginLeft={1}>
            <TextField
              id="standard-search-1"
              value={searchStandard}
              label="Search Standard"
              onChange={handleSearchStandard}
              type="search"
              variant="standard"
              sx={{ width: '14vw' }}
            />
            <TextField
              id="standard-search-2"
              value={searchSCFControl}
              label="Search SCF Control"
              onChange={handleSearchSCFControl}
              type="search"
              variant="standard"
              sx={{ width: '14vw' }}
            />
          </Stack>

          <Box
            height="79vh"
            marginTop=".5vh"
            display={"flex"}
            sx={{
              borderRadius: "5px",
              "& .MuiDataGrid-root": {
                border: "none",
                backgroundColor: colors.black[100],
              },
              "& .MuiDataGrid-cell": {
                borderBottomWidth: "1px",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: colors.primary[900],
                borderBottom: "",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.black[800],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "",
                backgroundColor: colors.primary[900],
              },
            }}
          >
            <DataGrid
              rows={filteredData}
              columns={columns}
              loading={loading}
              disableRowSelectionOnClick={true}
              disableColumnFilter={true} //column filter and export
              disableColumnSelector={true}
              disableDensitySelector={true}
              disableColumnMenu={true}
              disableSelectionOnClick={false}
              editMode="cell"
              disableExport={true}
              pagination={false}
              showColumnRightBorder={true} // show the vertical line between columns
              showCellRightBorder={true}
              isCellEditable={false} // show the vertical line between cells
            />
            <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogContent
                sx={{
                  top: "50%",
                  left: "50%",
                  boxShadow: 24,
                  p: 2,
                  borderRadius: "5px",
                  justifyContent: "center",
                  "& .MuiTextField-root": { m: 1, width: "60ch" },
                }}
              >

                <Typography variant="h4">
                  Please click the standard to confirm your choice
                </Typography>

                <Box paddingTop={"1vh"}>
                  <AddStandard
                    compliance={selectedCompliance}
                    onCloseModal={() => setOpen(false)}
                    standardObj={standard}
                    complianceId={complianceId}
                    onShowAlert={(alertState) => {
                      setShowAlert({
                        isShow: true,
                        message: alertState.message,
                        severity: alertState.severity,
                      });
                      onCloseAlertWithTime();
                    }}
                  />
                </Box>
              </DialogContent>
            </Dialog>
          </Box>
          {/* )} */}
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Box
              sx={{
                position: "absolute",
                top: 145,
                width: "30%",
                paddingRight: "3vh",
              }}
            >
              {showAlert.isShow && (
                <CustomAlert
                  severity="success"
                  onClose={() => {
                    setShowAlert({ isShow: false });
                  }}
                  open={showAlert.isShow}
                  successMessage={showAlert.message}
                />
              )}
            </Box>
          </Box>
        </Box>
        :
        <div style={{ padding: 12, paddingLeft: 8 }}>
          <h1>Oops! Something went wrong</h1>
          <p style={{ paddingLeft: 12 }}>Sorry, you need to be an admin to access this page.</p>
        </div>
      }
    </>
  );
};
export default ComplianceInfo;
