import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "../../../api/api"

export const getScfControls = createAsyncThunk(
  "scfControls/getScfControls",  async (params, thunk) => {
        try{
            const res = await axios.get(
                `${process.env.REACT_APP_API_DJANGO}compliances/?app_id=${process.env.REACT_APP_NAME}`
              );
              return res.data;
        } catch (error) {
         return thunk.rejectWithValue(error.response.data);
        }
  })

  export const getScfControlsByTaskId = createAsyncThunk(
    "scfControls/getScfControlsByTaskId",  async (params, thunk) => {
          try{
              const res = await axios.get(
                  `${process.env.REACT_APP_API_DJANGO}compliancetask/?task_id=${params['taskId']}`
                );
                return res.data;
          } catch (error) {
           return thunk.rejectWithValue(error.response.data);
          }
    })

    export const tagScfControls = createAsyncThunk(
        "scfControls/create-compliance-tasks",  async (params, thunk) => {
              try{
                  await axios.post(
                      `${process.env.REACT_APP_API_DJANGO}compliancetask/create-compliance-tasks/`,
                      params['data']
                    );
                    const res = await axios.get(
                        `${process.env.REACT_APP_API_DJANGO}compliancetask/?task_id=${params['taskId']}`
                      );
                      return res.data;
              } catch (error) {
               return thunk.rejectWithValue(error.response.data);
              }
        })


  const scfControlsSlice = createSlice({
    name: "scfControls",
    initialState: {
        scfControls: [],
        scfTasks: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers : (builder) => {
        builder.addCase(getScfControls.pending, (state) => {
            state.loading = true;
        })
        .addCase(getScfControls.fulfilled, (state, action) => {
            state.loading = false;
            state.scfControls = action.payload
        })
        .addCase(getScfControls.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }).addCase(getScfControlsByTaskId.pending, (state) => {
            state.loading = true;
        })
        .addCase(getScfControlsByTaskId.fulfilled, (state, action) => {
            state.loading = false;
            state.scfTasks = action.payload
        })
        .addCase(getScfControlsByTaskId.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        .addCase(tagScfControls.pending, (state) => {
            state.loading = true;
        })
        .addCase(tagScfControls.fulfilled, (state, action) => {
            state.loading = false;
            state.scfTasks = action.payload
        })
        .addCase(tagScfControls.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
    }
  })

  export default scfControlsSlice.reducer;