import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import { Box, CircularProgress, Skeleton, Typography } from "@mui/material";
import axios from "../../api/api";
import api from "../../api/api";

function generateSeriesData(chartData) {
  const uniqueSections = Array.from(
    new Set(
      chartData.flatMap((standard) =>
        standard.Sections_data.map((section) =>
          section.sections_name.replace("~", "")
        )
      )
    )
  );

  const series = uniqueSections.map((section) => {
    return {
      name: section,
      data: chartData.map((standard) => {
        const matchingSection = standard.Sections_data.find(
          (s) => s.sections_name === section
        );
        return matchingSection ? matchingSection.sections_progress : 0;
      }),
    };
  });

  return series;
}

function ApexBar() {
  const _clientId = localStorage.getItem("client");
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true); // Set initial loading state to true
  api.interceptors.request.use(
    function (config) {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await axios.get(
          `scm/taskprogresses/chart-data/?app=${process.env.REACT_APP_NAME}&client_id=${_clientId}`
        );
        // Simulate a 3-second delay before setting the loading state to false
        setTimeout(() => {
          setChartData(response.data);
          setLoading(false);
        }, 3000);
      } catch (err) {
        setLoading(false); // Set loading state to false in case of an error
      }
    };

    fetchChartData();
  }, [_clientId]);

  const series = generateSeriesData(chartData);

  const categories = chartData.map((standard) => standard.ClientStandard);

  const colorsArray = [
    "#FBCC16", // original color
    "#11A0DB", // original color
    "#67AD45", // original color
    "#F99D1B", // original color
    "#FF0000", // original color
    "#00A86B", // a bright green
    "#F15A22", // a vibrant orange
    "#0072C6", // a bright blue
    "#D3D3D3", // a light gray
    "#FF8C00", // a bright orange
    "#00BFFF", // a light blue
    "#FF1493", // a bright pink
    "#00CED1", // a bright turquoise
    "#8B0000", // a deep red
    "#008000", // a bright green
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    yaxis: {
      labels: {
        show: false, // This hides y-axis labels
      },
    },
    xaxis: {
      categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: "#FFFFFF", // Set the x-axis label color to white using the 'style' property
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    fill: {
      opacity: 1,
      colors: series.map((_, index) => colorsArray[index % colorsArray.length]),
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
      markers: {
        fillColors: colorsArray,
      },
      labels: {
        colors: "#FFFFFF",
      },
      showForSingleSeries: true, // Add this line to display the legend even for a single series
    },
    grid: {
      show: true,
      borderColor: "#D7D7D7",
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };

  return (
    <div
      style={{
        color: "black",
        width: "100%", // Make the chart container stretchable
        overflow: "auto",
      }}
    >
      <Box display="flex" justifyContent="center" margin={0} pt={0}>
        {loading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            marginTop={0.5}
          >
            <Box display="flex" alignItems="center" flexDirection="column">
              <Skeleton
                variant="rounded"
                width={860}
                height={60}
                animation="wave"
              />
              <Box height={8} />
              <Skeleton
                variant="rectangular"
                width={900}
                height={280}
                animation="wave"
              />
              <Box height={8} />
              <Skeleton
                variant="rounded"
                width={860}
                height={30}
                animation="wave"
              />
            </Box>
          </Box>
        ) : (
          <div style={{ minWidth: "500px", marginTop: "15px" }}>
            {/* Set a minimum width and enable horizontal scrolling */}
            <div style={{ display: "flex" }}>
              <div
                style={{
                  flex: "1 1 0",
                  minWidth: "500px",
                  marginBottom: "-50px",
                  // height: "1vh"
                }}
              >
                {/* Set a minimum width to ensure horizontal scroll works */}
                <ApexCharts
                  type="bar"
                  options={options}
                  width={chartData.length * 200} // Adjust the width based on the number of series
                  height={400}
                  series={series}
                  marginBottom={-20}
                />
              </div>
            </div>
          </div>
        )}
      </Box>
    </div>
  );
}

export default ApexBar;
