import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import useAxios from '../hooks/useAxios';
import axios from '../api/api'
import standardService from '../api/services/standardService';
import { Select } from '@mui/material';
import { useDispatch } from 'react-redux';
import { trigger } from '../feature/slices/standards/standardSlice';

export default function SectionStatus({ standard_id, newTask, defaultVal, taskProgress }) {
    const [status, setStatus] = React.useState(defaultVal)
    const isAssignee = localStorage.getItem('isAssignee');
    const [open, setOpen] = React.useState(false); const dispatch = useDispatch()
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [newStatus, setNewStatus] = React.useState('Not Yet Started');
    const [selectedStatus, setSelectedStatus] = React.useState(null);
    const _clientId = localStorage.getItem('client');

    const _status = [
        'ongoing', 'not applicable', 'not fulfilled', 'partially fulfilled', 'fulfilled', 'Not Yet Started'
    ]
    const handleClick = () => {
    };

    const handleMenuItemClick = (status, index) => {
        setSelectedIndex(index);
        setNewStatus(status)
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    // const [taskprogress, taskprogressError, taskprogressLoading] = useAxios({
    //     axiosInstance: axios,
    //     method: 'GET',
    //     url: `scm/sections/?standard=${standard_id}`,

    //     requestConfig: {
    //         headers: {
    //             'Content-Language': 'en-US'
    //         },
    //     }
    // },)

    const updateSectionStatus = (taskProgressId, section_status) => {
        standardService.updateSectionStatus(taskProgressId, { section_status: section_status })
            .then((res) => { 
                setStatus(section_status)
                dispatch(trigger())
            })
            .catch((err) => { })    
    }

    return (
        <React.Fragment>
            <Select
                id="demo-simple-select"
                value={status}
                onChange={(event) => {
                    updateSectionStatus(taskProgress.id, event.target.value)
                }}
                disabled={isAssignee === 'true'}
                style={{ textTransform: 'uppercase' }}
            >
                {_status.map((s, i) => (
                    <MenuItem value={s} key={i} style={{ textTransform: 'uppercase' }}>{s}</MenuItem>
                ))}
            </Select>
        </React.Fragment>
    );
}