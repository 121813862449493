import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL_LOGIN,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

export default api;

export const getActivityLogs = async (client) => {
  try {
    const response = await api.get(
      `scm/activitylogs/?app_id=${process.env.REACT_APP_NAME}&client_id=${client}`
    );
    return response.data.map((log) => ({
      ...log,
      id: log.id,
      timestamp: new Date(log.timestamp).toLocaleString(),
    }));
  } catch (error) {
    return [];
  }
};

export const fetchStakeholderByRole = async (stakeholderRoleId) => {
  const _role = localStorage.getItem('role');

  try {
    const response = await api.get(
      `scm/stakeholderRole/get_stakeholder_by_role/?role=${_role.replace(/[\[\]"]/g, '')}`
    );
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return data.email;
  } catch (error) {
    return [];
  }
};


export const uploadTaskEvidence = async (task_id, fileUrl, client) => {
  let result = null;
  try {
    const response = await api.post("scm/taskevidences/", {
      app_name: process.env.REACT_APP_NAME,
      client: client,
      task_progress: task_id,
      file_url: fileUrl,
    });

    result = response.data;
  } catch (error) { }
  return result;
};

export const updateTaskEvidence = async (evidenceId, fileUrl) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_API_URL_LOGIN}scm/taskevidences/update_task_evidence/${evidenceId}/`,
      {
        file_url: fileUrl,
      }
    );
    return res;
  } catch (error) { }
};

export const uploadExistingFile = async (fileUrl, client) => {
  let result = null;
  try {
    const response = await api.post("scm/taskevidences/", {
      file_url: fileUrl,
      app_name: process.env.REACT_APP_NAME,
      client: client,
    });

    result = response.data;
  } catch (error) { }
  return result;
};

// export const uploadExistingFile = async (fileUrl, client) => {
//   let result = null;
//   try {
//     const response = await api.post("scm/upload_task_evidence/", {
//       file_url: fileUrl,
//       app: 2,
//       client: client,
//     });

//     result = response.data;
//   } catch (error) { }
//   return result;
// };

export const uploadClientDocument = async (file, clientComplianceId) => {
  let result = null;
  const formData = new FormData();
  formData.append("file", file);
  formData.append("client_compliance", parseInt(clientComplianceId)); // Convert clientComplianceId to an integer

  try {
    const response = await api.post("scm/clientdocuments/upload_client_document/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    result = response.data;
  } catch (error) { }
  return result;
};

// export const getTaskProgress = async () => {
//   try {
//     const response = await api.get("scm/get_task_progress/");
//     return response.data.map(({ client_standard_id, percentage }) => ({
//       clientStandardId: client_standard_id,
//       percentage: percentage,
//     }));
//   } catch (error) {
//     return [];
//   }
// };

export const fetchExistingUploadedFile = async (task_progress) => {
  try {
    const response = await api.get(
      `scm/taskevidences/?task_progress=${task_progress}`
    );
    const fileList = response.data.map((fileData) => ({
      id: fileData.id,
      name: fileData.file_url.split("/").pop(), // Extract file name from URL
      previewUrl: fileData.file_url,
      task_id: fileData.task_progress,
    }));

    if (fileList.length > 0) {
      return fileList;
    }

    // Return an empty array if no files exist
    return [];
  } catch (error) {
    throw error;
  }
};

//const response = await api.get(`scm/taskevidences/get-files-client-and-app?client_id=${client}&app_id=${app}`);

export const fetchExistingUploadedFileList = async (client) => {
  try {
    const response = await api.get(
      `scm/taskevidences/get-files-client-and-app/?client_id=${client}&app_id=${process.env.REACT_APP_NAME}`
    );
    const fileList = response.data.map((fileData) => ({
      id: fileData.id,
      name: fileData.file_url.split("/").pop(), // Extract file name from URL
      previewUrl: fileData.file_url,
      task_id: fileData.task,
    }));

    if (fileList.length > 0) {
      return fileList;
    }

    // Return an empty array if no files exist
    return [];
  } catch (error) {
    throw error;
  }
};

export async function removeTaskEvidence(evidenceId) {
  try {
    const response = await api.delete(`/scm/taskevidences/${evidenceId}/`);
    return response.data;
  } catch (error) {
    throw new Error(`Error deleting task evidence: ${error.message}`);
  }
}
