import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography, CircularProgress, Alert } from '@mui/material';
import DataTable from './standardTable';
import { useDispatch } from 'react-redux';
import { add } from '../feature/slices/standards/standardSlice';
import { useState, } from 'react';
import useAxios from '../hooks/useAxios';
import axios from '../api/api'
import api from '../api/api';
import standardService from '../api/services/standardService';


export default function AddStandard({ compliance, onCloseModal, complianceId, onShowAlert, standardObj }) {


  const [isChecked, setIsChecked] = useState(false)
  const [selectedStandards, setSelectedStandards] = useState([])
  const [standardLoading, setStandardLoading] = useState(false);
  const [standardError, setStandardError] = useState(null);
  // const { isLoading, isError, isSuccess, data } = useSelector(state => state.standards)
  const dispatch = useDispatch()
  const token = localStorage.getItem('token');
  const _user = localStorage.getItem('user');

  api.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  // const [standard, error, loading] = useAxios({
  //   axiosInstance: axios,
  //   method: 'GET',
  //   url: `scm/standards/`,
  //   requestConfig: {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Language': 'en-US'
  //     },
  //   }
  // })

  // Declare state variables using the useState hook
  const [alertState, setAlertState] = useState({ message: '', severity: '' });
  const [successMessage, setSuccessMessage] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const _client = localStorage.getItem('client');

  const addClientStandard = async ({ client_compliance, standard, version, start_date, end_date, status}) => {
    const res = await standardService.addClientStandard({
      client_compliance: client_compliance,
      standard: standard,
      version: version,
      start_date: start_date,
      end_date: end_date,
      status: status,
      app: process.env.REACT_APP_NAME,
      client: _client,
      user: _user
    })
    return res
  };

  const onAddToList = async () => {

    // try {
    //   if (!isChecked) {
    //     return
    //   }
    //   setIsAdding(true)
    //   const response = await addClientStandard({ standard: standardObj.id });
    //   console.log(response)


    // } catch (error) {

    //   console.log(error.respo)
    //   setAlertState({
    //     message: `Failed to add standard`,
    //     severity: 'error'
    //   });
    // } finally {
    //   setIsAdding(false)
    // }

    if (!isChecked) {
      setAlertState({
        message: `Please check the checkbox to add standard`,
        severity: 'error'
      });
      return
    }
    setIsAdding(true)
    addClientStandard({ standard: standardObj.id })
      .then((response) => {
        onShowAlert({
          message: `${response.data.message}`,
          severity: 'success'
        });
        onCloseModal();
        setIsAdding(false)
      })
      .catch((error) => {
        console.log(error)
        setIsAdding(false)
        setAlertState({
          message: `${error.response.data.message}`,
          severity: 'error'
        });
      })


  };



  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <DataTable
          standard={standardObj}
          onSelectedItem={(e) => {
            setIsChecked(!isChecked)
          }}
          sx={{ height: "100%", width: "100%" }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', height: '50px', justifyContent: 'flex-end', alignItems: 'end' }}>
        <Button
            type="submit"
            color="secondary"
            variant="outlined"
            onClick={onCloseModal}
            sx={{ marginTop: 1, width: '6vw', alignSelf: 'flex-end', marginRight: '1vw' }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            sx={{ marginTop: 1, width: '6vw', alignSelf: 'flex-end', marginRight: '2vw'  }}
            onClick={onAddToList}
            disabled={isAdding}
          >
            {isAdding ? <CircularProgress size={24} /> : 'Add'}
          </Button>
        </Box>
      </Box>
      <Box sx={{ position: 'fixed', top: '20%', left: '75%'}}>
            {alertState.message && (
              <Alert severity={alertState.severity} onClose={() => setAlertState({ message: '', severity: '' })}>
                {alertState.message}
              </Alert>
            )}
          </Box>
      {/* )} */}
    </Box>
  );

}
