import React, { useEffect, useState, createContext  } from 'react'
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Assignee from './Assignee';
import TaskStatus from './TaskStatus';
import NewAssignee from './NewAssignee';
import NewTaskStatus from './NewTaskStatus';
import UploadModal from './FileUpload/EvidenceUpload';
import CustomAlert from './Alert/SuccessAlert';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Button, Stack, useTheme, Grid, Icon, Skeleton, Avatar, Divider } from "@mui/material";
import { tokens } from "../theme";
import { TextField } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import useAxios from "../hooks/useAxios";
import axios, { fetchExistingUploadedFileList } from "../api/api";
import standardService from "../api/services/standardService";
import { Alert, Tooltip } from "@mui/material";
import SectionStatus from "./SectionStatus";
import Comment from "./Comment";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TaskAssignee from "./TaskAssignee";
import { useDispatch, useSelector } from "react-redux";
import { updateTaskAssignee, updateTaskStatus } from '../feature/slices/taskprogress/taskProgressSlice';
// import {
//   getAllTaskProgress,
//   setCurrentRow,
//   updateData,
// } from "../feature/slices/taskProgressSlice";

function Row(props) {
  const row = props.row;
  const { assignees, taskprogress } = props;
  const [open, setOpen] = React.useState(false);
  const [alertState, setAlertState] = React.useState({
    message: "",
    severity: "",
  });

  //===========
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  //===========
  const dispatch = useDispatch();
  const _clientId = localStorage.getItem("client");

  

  const handleOpen = () => {
    setOpen(true);
  };

  // const { taskProgresses : progressTasks, isLoading, currentRow } = useSelector(state => state.taskProgress)
  const [newDescription, setNewDescription] = React.useState(""); // state variable for the description value
  const [isEditingDescription, setIsEditingDescription] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const [taskProgress, setTaskProgress] = React.useState(null)
  const [assignee, setAssignee] = React.useState(null)


//   const getTaskProgress = async (sectionId) => {
//     const res = await axios.get(
//       `${process.env.REACT_APP_API_DJANGO}taskprogresses/${sectionId}/?client_id=${_clientId}`
//     );
//     return res;
//   };

  // const getProgresses = async () => {
  //   const res = await axios.get(
  //     `${process.env.REACT_APP_API_DJANGO}taskprogresses/?section_id=${row.id}&client_id=${_clientId}`
  //   );
  //   return res;
  // };
//   const [progressTasks, setProgressTasks] = React.useState([]);
  // const [loading, setLoading] = React.useState(false)

  const onUpdateTaskAssignee = (updatedAssignee) => {
    try {
      dispatch(
        updateTaskAssignee({
          id: updatedAssignee.id,
          task_assignee: updatedAssignee.task_assignee,
        })
      );
    } catch (error) {}
  };

  const onUpdateTaskStatus = (updatedStatus) => {
    try {
      dispatch(
        updateTaskStatus({
          id: updatedStatus.id,
          task_status: updatedStatus.task_status,
        })
      );
    } catch (error) {}
  };

  useEffect(() => {
    // getTaskProgress(props.row.id)
    //   .then(res => {
    //     setTaskProgress(res.data)
    //     setAssignee(res.data.section_assignee.first_name)
        
    //   })
    //   .catch((err) => {});
  }, []);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset", background: colors.black[800] },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              if (!open) {
                // setLoading(true);
                // getProgressesBySectionAndClient().then((res) => {
                //   setProgressTasks(res.data);
                //   setLoading(false);
                // });
              }
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row" sx={{ fontWeight: "bold", fontSize: 14, color: 'Highlight' }}>
          {row.compliance__name && (
           <div>{row.compliance__name.replace(/["[\]']+/g, "")}</div>
          )}
        </TableCell>

        <TableCell align="left">
           {row.compliance__description && (
            <div>
              {row.compliance__description.replace(/["[\]']+/g, "")}
            </div>
           )}
        </TableCell>

        <TableCell align="left">
            {row.compliance__methods && (
                <div>
                {row.compliance__methods.replace(/["[\]']+/g, "")}
              </div>
            )}
        </TableCell>
      </TableRow>

      {/* ---------------------------------Child Rows------------------------------------ */}
    
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
          sx={{ background: colors.grey[900] }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/* =====================================THE ADD TASK BUTTON IS HERE========================================= */}
            <Box sx={{ margin: 1 }}>
              <Table size="medium" aria-label="Sections">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Stack
                        display="flex"
                        direction="row"
                        sx={{ fontWeight: "bold" }}
                      >
                        Subsection
                        <Tooltip
                          title={
                            "The following subsections are part of the section above, where you can view task requirements and their status. It is also the place where you can upload evidence/requirements and engage in conversations with others through comments."
                          }
                        >
                          <IconButton disableRipple={true} size="small">
                            <HelpOutlineIcon
                              style={{
                                fontSize: "0.8rem",
                                alignSelf: "center",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Section Title
                    </TableCell>{" "}
                    {/* This is decription sauna*/}
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Requirements
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Assignee
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Status
                      <Tooltip
                        title={
                          <span>
                            Task Status Legend:
                            <br />-<strong>?UNKNOWN</strong> = Has not even been
                            checked yet.
                            <br />-<strong>NONEEXISTENT 0%</strong> = Complete
                            lack of recognizable policy, procedure, control etc.
                            <br />-<strong>INITIAL 20%</strong> = Development
                            has barely started and will require significant work
                            to fulfill the requirements.
                            <br />-<strong>LIMITED 40%</strong> = Progressing
                            nicely but not yet complete.
                            <br />-<strong>DEFINED 60%</strong> = Development is
                            more or less complete although detail is lacking
                            and/or it is not yet implemented, enforced and
                            actively supported by the top management.
                            <br />-<strong>MANAGED 80%</strong> = Development is
                            complete, the process/control has been implemented
                            and recently started operating.
                            <br />-<strong>OPTIMIZED 100%</strong> = The
                            requirement is full satisfied, is operating fully as
                            expected, is being actively monitored and improved,
                            and there is substantial evidence to prove all that
                            to the auditors.
                            <br />-<strong>NOT APPLICABLE</strong> = This task
                            does not apply to your compliance. The requirements
                            or criteria mentioned are not relevant or applicable
                            to your specific situation.
                          </span>
                        }
                      >
                        <HelpOutlineIcon
                          style={{
                            marginLeft: "3px",
                            fontSize: "0.8rem",
                            verticalAlign: "middle",
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Files
                    </TableCell>
                    <TableCell align="center">
                      <Stack sx={{ fontWeight: "bold" }}>Reference</Stack>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {loading ? (
                    <>Loading...</>
                  ) : (
                    <TaskRow
                      assignees={assignees}
                      onUpdateTaskAssignee={onUpdateTaskAssignee}
                      onUpdateTaskStatus={onUpdateTaskStatus}
                      standardId={props.standardId}
                      complianceId={row?.compliance__id}
                      taskprogress={taskprogress}
                      onUpdateData={(data) => props.onUpdateData(data)}
                    />
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {/* <Box
        sx={{
          position: "absolute",
          top: -60,
          paddingLeft: "15vh",
          paddingRight: "15vh",
        }}
      >
        {alertState.message && (
          <Alert
            severity={alertState.severity}
            onClose={() => setAlertState({ message: "", severity: "" })}
          >
            {alertState.message}
          </Alert>
        )}
      </Box> */}
    </React.Fragment>
  );
}

const TaskRow = ({
  assignees,
//   task_id,
//   taskProgresses = [],
  onUpdateTaskAssignee,
  onUpdateTaskStatus,
  onUpdateData,
  standardId, 
  complianceId,
  taskprogress
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [subsecList, setSubsecList] = React.useState([]);
  const [error, setError] = useState(null);
  const isAssignee = localStorage.getItem("isAssignee");

  // const { taskprogress } = useSelector((state) => state.taskprogress)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`scm/compliancetask/?standard=${standardId}&compliance=${complianceId}`, {
          headers: {
            "Content-Language": "en-US",
          },
        });
        setSubsecList(response.data);
        setError(false);
      } catch (error) {
        setError(true);
        alert("Reload. Error loading data.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

 
  return (
    <>
      {isLoading && taskprogress?.length > 0 ? (
        <Grid
          container
          flexDirection="row"
          justifyContent="left"
          position="relative"
          top=".3vh"
          left="3vh"
        >
          <Box justifyContent="center">
            <CircularProgress size={24} />
          </Box>
        </Grid>
      ) : (
        subsecList.map((task) => {
          const progressTask = taskprogress?.find(
            (_taskprogress) => _taskprogress?.task?.id === task?.task.id
          );

          return progressTask === undefined ? (
            <></>
          ) : (
            <TableRow key={task.task.id}>
              <TableCell component="th" scope="row">
                {task.task.name.replace("~", "")}
              </TableCell>
              <TableCell align="center">
                  <Box textAlign="center">
                    {task.task.description}
                  </Box>
              </TableCell>
              <TableCell align="center">
                <Box textAlign="center">
                  {task.task.requirement}
                </Box>
            </TableCell>

              <TableCell align='center'>
                {taskprogress ? (
                  <Stack display='flex' direction='row' spacing={1} justifyContent="center">
                    <TaskAssignee
                      key={`scf-${progressTask.id}`}
                      assignees={assignees}
                      taskAssignee={progressTask?.task_assignee}
                      taskProgress={progressTask}
                      onUpdateTaskAssignee={onUpdateTaskAssignee}
                      onUpdateData={(data) => onUpdateData(data)}
                    />
                    {onUpdateTaskAssignee && (
                      <Tooltip title={progressTask?.task_assignee?.first_name}>
                        <Avatar
                          align="right"
                          sx={{
                            bgcolor: '#F99D1B',
                            boxShadow: 5,
                            transition: "transform 0.2s ease-in-out",
                            "&:hover": {
                              transform: "scale(1.0) translateY(-5px)",
                            },
                            cursor: "default",
                          }}
                        >{progressTask?.task_assignee?.first_name[0]}</Avatar>
                      </Tooltip>
                    )}
                  </Stack>
                ) : (
                  <CircularProgress size={30} color='success' />
                )}
                
              </TableCell>

              <TableCell align="center">
              {/* onUpdateData={(data) => onUpdateData(data) */}
                {taskprogress ? <TaskStatus key={`scf-${task.id}`} 
                taskStatus={progressTask?.task_status} 
                taskProgress={progressTask} 
                onUpdateData={(data) => onUpdateData(data)} 
                onUpdateTaskStatus={onUpdateTaskStatus}/> : <CircularProgress size={30} color='success' />}
              </TableCell>
              <TableCell align="center">
                <UploadModal key={`scf-${task.id}`} task={progressTask.id} assignee={progressTask?.task_assignee} task_requirement={task.requirement}/>
              </TableCell>
              <TableCell align="right">
                <Comment key={`scf-${task.id}`} task_progress={progressTask.id} />
              </TableCell>
            </TableRow>
          );
        })
      )}
    </>
  );
};

export default function CollapsibleControlsView({ standardId, client_standardId }) {
  const [isLoading, setIsLoading] = useState(false);
  const [complianceList, setComplianceList] = useState([]);
  const [error, setError] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [showAlert, setShowAlert] = React.useState({
    message: "",
    isShow: false,
    serverity: "",
  });

  const _client = localStorage.getItem("client");
  const _app = localStorage.getItem("app");

  const onCloseAlertWithTime = () => {
    const interval = setInterval(() => {
      setShowAlert((prevState) => ({ ...prevState, isShow: false })); // Update the state variable here
      clearInterval(interval);
    }, 5000);
  };

  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };  
  const { taskprogress } = useSelector((state) => state.taskprogress)
  const [taskProgress, setTaskProgress] = React.useState(taskprogress)
  const { assignees } = useSelector((state) => state.assignees)
  
  
  // const getTaskProgresses = async () => {
  //   const res = await axios.get(
  //     `${process.env.REACT_APP_API_DJANGO}taskprogresses/?client_id=${_client}&standard_id=${client_standardId}`
  //   );
  //   return res;
  // };
  const onUpdateData = (data) => {
  setTaskProgress((prevState) => 
    prevState.map((taskProgress) => {
      if (taskProgress.id === data.id) {

        if(data.task_assignee){
          return {
            ...taskProgress,
            task_assignee: data.task_assignee,
          };
        }else{
          return {
            ...taskProgress,
            task_status: data.task_status,
          };
        }
        
      }
      return taskProgress;
    })
  );
};
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          `scm/compliancetask/get-all/?standard=${standardId}`,
          {
            headers: {
              "Content-Language": "en-US",
            },
          }
        );
        // const assignees = await getAssignees();
        // setAssignees(assignees);
        setComplianceList(response.data);
        setError(false);
      } catch (error) {
        setError(true);
        alert("Reload. Error loading data.");
      } finally {
        setIsLoading(false);
      }
    };
    // getTaskProgresses().then(res => {
    //   setTaskProgress(res.data);
    // }).catch((err) => {});
    // dispatch(getTaskProgress({_client: _client, client_standardId: client_standardId}))
    //  setTaskProgress(taskprogress)
    fetchData();
  }, []);

  const filteredData = complianceList.filter((row) =>
  Object.values(row).some((value) =>
    String(value).toLowerCase().includes(searchText.toLowerCase())
  )
);
// const filteredData = complianceList.filter((row) =>
//   row.compliance__name.toLowerCase().includes(searchText.toLowerCase())
// );


  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "right" }}>
        <Box
          sx={{
            position: "absolute",
            top: '6%',
            width: "25%",
            paddingRight: "10vh",
          }}
        >
          {showAlert.isShow && (
            <CustomAlert
              severity="success"
              onClose={() => {
                setShowAlert({ isShow: false });
              }}
              open={showAlert.isShow}
              successMessage={showAlert.message}
            />
          )}
        </Box>
      </Box>
      {isLoading ? (
        <Grid
          container
          flexDirection="row"
          justifyContent="center"
          position="absolute"
        >
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              pt: 25,
            }}
          >
            <Typography pb={2}>Loading ...</Typography>
            <CircularProgress />
          </Box>
        </Grid>
      ) : (
        <>
        <TextField
          id="compliance-search"
          value={searchText}
          label="Search Compliance"
          onChange={handleSearchChange}
          type="search"
          variant="standard"
          sx={{ marginBottom: 1, marginLeft: 1, width: '16vw'}}
        />
       
        <TableContainer
          component={Paper}
          style={{
            maxHeight: "84.5%",
            overflow: "auto",
            backgroundColor: colors.black[600],
          }}
        >
          <Table aria-label="collapsible table" style={{maxHeight: '70%'}}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell sx={{ fontWeight: "bold", fontSize: 14 }}>SCF Control</TableCell>
                <TableCell align="left" sx={{ fontWeight: "bold", fontSize: 14 }}>
                  Description
                </TableCell>
                {/* <TableCell align="left">Requirements</TableCell> */}
                <TableCell align="left" sx={{ fontWeight: "bold", fontSize: 14 }}>
                  Methods To Comply With SCF Controls
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row) => (
                <Row
                  key={`scf-${row.id}`}
                  row={row}
                  assignees={assignees}
                  client_standardId={client_standardId}
                  standardId={standardId}
                  section_name={row.name}
                  onShowAlert={(alertState) => {
                    setShowAlert({
                      isShow: true,
                      message: alertState.message,
                      severity: alertState.severity,
                    });
                    onCloseAlertWithTime();
                  }}
                  taskprogress={taskProgress}
                  onUpdateData={(data) => onUpdateData(data)}
                />
              ))}
            </TableBody>
           
          </Table>
        </TableContainer>
        </>
      )}
    </>
  );
}
