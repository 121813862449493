import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import { Box, Button, Divider, Skeleton, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import { tokens } from "../../theme"; //color themeT
import { useTheme } from "@emotion/react";
import CollapsibleComplianceView from "../../components/CollapsibleComplianceView";
import Existing from "../../components/FileUpload/ExistingDocsUpload";
import { useLocation, useNavigate } from "react-router-dom";
import GroupBy from "../../components/GroupBy";
import CustomPagination from "../../components/CustomPagination";
import axios from "../../api/api";
import useAxios from "../../hooks/useAxios";
import { useDispatch } from "react-redux";
import { add, reset } from "../../feature/slices/standards/assigneeSlice";
import { useMemo } from "react";
import api from "../../api/api";
import standardService from "../../api/services/standardService";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import CloseIcon from "@mui/icons-material/Close";
import queryParamHelper from "../../Helper/queryParamsHelper";
import { CloseOutlined } from "@mui/icons-material";
import AnnexTableCollapse from "../../components/AnnexTableCollapse";
import { getTaskProgress } from "../../feature/slices/taskprogress/taskProgressSlice";

function MngCompliance() {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const [filterState, setFilterState] = useState("Standards");
  const [compliance, setCompliance] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [clientStandard, setClientStandard] = useState(null);
  const [standardName, setStandardName] = useState(null);

  const [percentage, setPercentage] = useState([]);
  const { refresh } = useSelector((state) => state.standard);
  const searchParamStandardId = queryParamHelper.getQueryParam(
    "clientStandard",
    location.search
  );
  const _client = localStorage.getItem("client");
  const _isAssignee = localStorage.getItem("isAssignee");

  // const [standard, Error, Loading] = useAxios({
  //   axiosInstance: api,
  //   method: 'GET',
  //   url: `scm/clientstandard/${searchParamStandardId.value}/`,
  //   requestConfig: {
  //     headers: {
  //       'Content-Language': 'en-US'
  //     },
  //   }
  // })

  const fetchStandardId = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_DJANGO}clientstandard/${searchParamStandardId.value}/`
      );
      return res;
    } catch (e) {
      return e;
    }
  };

  useEffect(() => {
    setShowModal(clientStandard?.length != 0 && clientStandard !== null);
    // console.log(searchParamStandardId.value)

    // async function fetchPercentage() {
    //   // const data = await getTaskProgress();
    //   const newPercentage = {};

    //   data.forEach(({ clientStandardId, percentage }) => {// Iterate through the data received from getTaskProgress() and extract
    //     newPercentage[clientStandardId] = percentage;
    //   });
    //   setPercentage(newPercentage);
    // }
    // fetchPercentage();
    fetchStandardId().then((res) => {
      setClientStandard(res.data.standard);
      setStandardName(res.data.standard_name);
    });
  }, [refresh, clientStandard]);

  // const [compliances, setCompliances] = useState([])
  const token = localStorage.getItem("token");

  api.interceptors.request.use((config) => {
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  const [compliancePage, setCompliancePage] = useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageChangeCompliance = (event, value) => {
    setCompliancePage(value);
  };
  const navigate = useNavigate();
  const handleAddCompliance = () => {
    navigate("/compliance_info");
  };

  const [clientStandards, taskError, taskLoading] = useAxios({
    axiosInstance: api,
    method: "GET",
    url: `scm/clientstandard/?app=${process.env.REACT_APP_NAME}&client=${_client}`,
    requestConfig: {
      headers: {
        "Content-Language": "en-US",
      },
    },
  });

  const [page, setPage] = useState(1);
  const itemsPerPage = Math.min(12, clientStandards?.length || 0); // access length only if clientStandards is defined

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const displayedData = clientStandards?.slice(startIndex, endIndex) || [];

  const totalNumPages = Math.ceil(clientStandards?.length / itemsPerPage) || 1; // default to 1 if clientStandards is undefined

  const groupedData = useMemo(() => {
    // memoize the groupedData constant to avoid unnecessary re-rendering
    if (!clientStandards) return {};

    return clientStandards.reduce((acc, obj) => {
      const { client_compliance, ...rest } = obj;
      if (!acc[client_compliance]) {
        acc[client_compliance] = [rest];
      } else {
        acc[client_compliance].push(rest);
      }
      return acc;
    }, {});
  }, [clientStandards]);

  return (
    <React.Fragment>
      {taskLoading ? (
        <Grid container justifyContent="center" sx={{ padding: "15px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pl: "2vh",
              pr: "2vh",
              gap: 2,
            }}
          >
            {Array(6)
              .fill()
              .map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width={300}
                  height={290}
                  sx={{ mx: "auto" }}
                />
              ))}
          </Box>
        </Grid>
      ) : (
        <Box sx={{ cursor: "pointer" }}>
          {clientStandards?.length === 0 && ( // access length only if clientStandards is defined
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "85vh",
                cursor: "pointer",
              }}
            >
              {clientStandards?.length === 0 && (
                 <Box>
                 <Typography
                   variant="h4"
                   sx={{
                     textAlign: "center",
                     fontWeight: "bold",
                     color: "#3c8084",
                   }}
                 >
                   No Task Available
                 </Typography>
             </Box>
          )}
            </Box>
          )}
          {clientStandards?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "3vh",
                marginRight: "3vh",
                marginTop: "1.5vh",
                paddingBottom: "3rem",
              }}
            >
              <Box style={{ position: "absolute", top: 9, right: 80 }}>
                {/* <GroupBy onChangeFilter={(state) => setFilterState(state)} /> */}
              </Box>
              <Grid
                container
                spacing={{ xs: 1, md: 2 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {displayedData.map((client_standard, i) => (
                      <Grid item xs={2} sm={6} md={2} key={i}>
                        <ComplianceCard
                          title={client_standard.standard_name}
                          // circularProgressValue={(percentage[client_standard.id] || 0).toFixed(1)}
                          // subtitle={client_standard.client_compliance}
                          created={client_standard.created_at}
                          modified={client_standard.updated_at}
                          complianceStatus={"Not Yet Started"}
                          auditStatus={"Not Yet Started"}
                          complianceValue={0}
                          auditValue={0}
                          standard_id={client_standard.standard}
                          client_standardId={client_standard.id}
                          user={String(client_standard.name)}
                        />
                      </Grid>
                    ))}
              </Grid>
              <Box style={{ position: "absolute", minHeight: "6vh" }}>
                {filterState === "Standards" ? (
                  <CustomPagination
                    count={totalNumPages}
                    page={page}
                    onChange={handlePageChange}
                    variant="outlined"
                    shape="rounded"
                    orientation="horizontal"
                  />
                ) : (
                  <CustomPagination
                    count={Math.ceil(
                      Object.entries(groupedData).length /
                        Math.min(12, Object.entries(groupedData).length || 0)
                    )}
                    page={compliancePage}
                    onChange={handlePageChangeCompliance}
                    variant="outlined"
                    shape="rounded"
                    orientation="horizontal"
                  />
                )}
              </Box>
              {searchParamStandardId != null && (
                <Modal
                  open={showModal}
                  onClose={() => {
                    setShowModal(false);
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      background: colors.primary[900],
                      boxShadow: 10,
                      p: 1,
                    }}
                    borderRadius={1}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                    >
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        width={"100%"}
                      >
                        <IconButton
                          variant="contained"
                          onClick={() => {
                            setShowModal(false);
                            navigate("/compliance_manage/");
                          }}
                          sx={{ position: "absolute", top: 20, right: 20 }}
                        >
                          <CloseOutlined />
                        </IconButton>
                      </Box>
                      <Box marginTop={"1vh"}>
                        <Typography fontWeight={"bold"} fontSize={"25px"}>
                          {standardName}
                        </Typography>
                        <Typography
                          fontWeight={"semibold"}
                          color={"GrayText"}
                          fontSize={"28px"}
                        >
                          {""}
                        </Typography>
                      </Box>
                    </Box>
                    {/* ==========================================Collapsible Table============================================= */}
                    <CollapsibleComplianceView
                      standardId={clientStandard}
                      client_standardId={searchParamStandardId.value}
                    />
                    <Box>
                      <Box
                        display={"flex"}
                        variant="contained"
                        onClick={() => {}}
                        sx={{
                          position: "absolute",
                          bottom: "4%",
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      >
                        {/* <Existing client_standardId={Number(searchParamStandardId.value)}/> */}
                      </Box>
                    </Box>
                  </Box>
                </Modal>
              )}
            </Box>
          )}
        </Box>
      )}
    </React.Fragment>
  );
}

//  This function is for groupby compliance
function ComplianceCard({
  title,
  standard,
  created,
  modified,
  circularProgressValue,
  complianceValue,
  auditValue,
  complianceStatus,
  auditStatus,
  user,
  client_standardId,
  standard_id,
}) {
  const [open, setOpen] = useState(false);
  const [secondModalOpen, setSecondModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [stateSubTitle, setStateSubTitle] = useState(null);
  const [complianceName, setComplianceName] = useState("");
  const [status, setStatus] = useState(null);
  const { refresh } = useSelector((state) => state.standard);
  const [description, setDescription] = useState("");
  const _client = localStorage.getItem("client");
  const location = useLocation();

  const searchParamStandardId = queryParamHelper.getQueryParam(
    "clientStandard",
    location.search
  );

  useEffect(() => {
    standardService
      .getStatusValuesAndTaskProgress(client_standardId)
      .then((res) => setStatus(res.data));
  }, [refresh]);

  const handleClickOpen = async (event) => {
    dispatch(getTaskProgress({_client: _client, client_standardId: client_standardId}))
    setOpen(true);
    event.stopPropagation(); // prevent row click event from being triggered
    dispatch(reset()); // assuming dispatch is a function for dispatching Redux actions
    // try {
    //   const res = await fetchStakeholderByRole(); // assuming fetchStakeholderByRole is an asynchronous function that fetches data from an API
    //   for (let data of res) {
    //     dispatch(add(data)); // assuming add is a Redux action that adds data to the Redux store
    //   }
    // } catch (error) {
    //   // handle error as needed
    // }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSecondModalOpenToggle = () => {
    setSecondModalOpen(!secondModalOpen);
  };
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      <Paper
        sx={{
          padding: "2rem",
          background: colors.primary[900],
          borderColor: "highlight", // add this line to change the border color
          boxShadow: "1px 1px 2px rgba(135, 206, 235, 0.5)",
          height: "100%",
        }}
        onClick={handleClickOpen}
      >
        <Stack
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignContent="flex-start"
            flexDirection={"column"}
          >
            <Typography noWrap={false} marginBottom={"5px"} fontWeight={"bold"}>
              {title}
            </Typography>
            <Typography color="highlight">{/* {stateSubTitle} */}</Typography>
          </Box>
          <Box>
            <Tooltip title="The circular progress is determined by all the tasks within the standard or compliance. It counts the number of optimized task statuses and divides it by the total number of tasks across all sections of the standard/compliance. ">
              <IconButton>
                <CircularProgressWithLabel
                  value={Number(status?.task_progress.percentage.toFixed(1))}
                  size={50}
                  thickness={6}
                  sx={{
                    boxShadow: "5px 5px 10px rgba(0, 0, 0, 0.2)",
                    borderRadius: "50%",
                    backgroundColor: colors.black[600],
                  }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Stack>
        <Divider />
        <Stack
          direction="row"
          display="flex"
          justifyContent="right"
          spacing={5}
        >
          <Typography align="right" fontSize="small">
            Created
          </Typography>
          <Typography align="right" fontSize="small">
            Modified
          </Typography>
        </Stack>
        <Stack
          direction="row"
          display="flex"
          justifyContent="right"
          spacing={5}
        >
          <Tooltip title={user}>
            <Avatar
              align="right"
              sx={{
                bgcolor: "#0a6083",
                boxShadow: 5,
                transition: "transform 0.2s ease-in-out",
                "&:hover": {
                  transform: "scale(1.2) translateY(-10px)",
                },
                cursor: "default",
              }}
            >
              {user[0]}
            </Avatar>
          </Tooltip>
          <Typography align="right" fontSize="small">
            {created}
          </Typography>
          <Typography align="right" fontSize="small">
            {modified}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          display="flex"
          justifyContent="space-between"
          pt={4}
        >
          <Typography>
            Analysis Status
            <Tooltip
              title={
                "The analysis status is determined by the progress status of each section, including ongoing, fulfilled, and partially fulfilled tasks. It counts the total number of these statuses and divides it by the overall number of sections within the standard or compliance."
              }
            >
              <HelpOutlineIcon
                style={{
                  marginLeft: "3px",
                  fontSize: "0.8rem",
                  verticalAlign: "middle",
                }}
              />
            </Tooltip>
          </Typography>
          <Divider sx={{ height: 18, m: 0.3 }} orientation="vertical" />
          <Typography>
            {status?.cp_values.analysis_status} / {status?.cp_values.overall}
          </Typography>
        </Stack>
        <Box>
          <LinearProgress
            variant="determinate"
            value={status?.cp_values.analysis_status_value}
            sx={{
              height: 20,
              width: "100%",
              boxShadow: 5,
              backgroundColor: "GrayText",
            }}
          />
        </Box>
        <Stack
          direction="row"
          display="flex"
          justifyContent="space-between"
          pt={1}
        >
          <Typography color>
            Audit Status
            <Tooltip
              title={
                "The audit status is determined by the progress status of each section. It calculates the number of completed (fulfilled) sections divided by the total sections within the standard or compliance."
              }
            >
              <HelpOutlineIcon
                style={{
                  marginLeft: "3px",
                  fontSize: "0.8rem",
                  verticalAlign: "middle",
                }}
              />
            </Tooltip>
          </Typography>
          <Typography>
            {status?.cp_values.audit_status} / {status?.cp_values.overall}
          </Typography>
        </Stack>
        <Box>
          <LinearProgress
            variant="determinate"
            value={status?.cp_values.audit_status_value}
            sx={{
              height: 20,
              border: 0,
              boxShadow: 5,
              backgroundColor: "GrayText",
            }}
          />
        </Box>
      </Paper>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            // top: '10%',
            // left: '10%',
            // transform: 'translate(-50%, -50%)',
            width: "100%",
            height: "100%",
            background: colors.primary[900],
            boxShadow: 10,
            p: 1,
          }}
          borderRadius={1}
        >
          <Box display={"flex"} direction="row" justifyContent="space-between">
            <Typography fontWeight={"bold"} fontSize={"25px"}>
              {title}
            </Typography>
            <Stack
              display={"flex"}
              direction="row"
              justifyContent="space-between"
              width="15%"
              paddingBottom="1vh"
            >
              <Existing
                sx={{ alignSelf: "right" }}
                client_standardId={client_standardId}
              />
              {/* <Typography fontWeight={'semibold'} color={'GrayText'} fontSize={'28px'}>{description}</Typography> */}
              <IconButton
                aria-label="delete"
                variant="contained"
                color="white"
                onClick={handleClose}
                sx={{ alignSelf: "right" }}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Box>
          {/* ==========================================Collapsible Table============================================= */}
          <CollapsibleComplianceView
            standardId={standard_id}
            client_standardId={client_standardId}
          />
          <Box>
            {/* <Box display={'flex'} variant='contained' onClick={handleSecondModalOpenToggle}
                    sx={{ position: 'absolute', bottom: '4%', left: '50%', transform: 'translateX(-50%)' }}
                  ><Existing client_standardId={client_standardId} /></Box> */}
          </Box>
        </Box>
      </Modal>
    </>
  );
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${props.value}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default MngCompliance;
