import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import standardService from "../../../api/services/standardService"

const initialState = {
    standards: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    refresh: false
}


const standardSlice = createSlice({
    name: 'standard',
    initialState,
    reducers: {
        add: (state, action) => {
            state.standards.push(action.payload)
        },
        trigger: (state) => {
            state.refresh = !state.refresh
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addStandard.pending, (state) => {
            state.isLoading = true
            state.isSuccess = false
            state.isError = false
            state.data = null
        }).addCase(addStandard.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.data = action.payload
        }).addCase(addStandard.rejected, (state, action) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = true
            state.data = action.payload
        })
    }
}
)


export const addStandard = createAsyncThunk(
    'create/standard',
    async (data, thunk) => {
        try {
            const res = await standardService.add(data)
            return res.data
        } catch (error) {
            return thunk.rejectWithValue(error.response.data)
        }
    }
)


export default standardSlice.reducer;
export const { add, trigger } = standardSlice.actions